import {
    ApolloClient,
    InMemoryCache
} from '@apollo/client'

const client = new ApolloClient({
  uri: '/v1/graphql',
  cache: new InMemoryCache({
    typePolicies: {
      tin_country: {
        keyFields: ['code']
      }
    }
  })
})

export default client