import { Button, Paper, TextField } from '@mui/material'
import { TinFragment, Tin_Tin, useRemoveTinMutation, useUpdateTinMutation } from 'queries'
import { createElement as $, FC, Fragment } from 'react'
import useDebounceValue from 'useDebounceValue'
import Documents from './Documents'
import FormatEditor from './FormatEditor'
import Links from './Links'
import TypeSelector from './TypeSelector'

type Props = TinFragment

const TinVariant: FC<Props> = (props) => {
  const {
    id,
    isAnalogue,
    type,
    format,
    abbreviation,
    description,
    assignmentProcedure,
    absenceReason,
    checkLinks,
    countryCode,
    documents
  } = props
  const [updateTin] = useUpdateTinMutation()
  const handleChange = (key: keyof Tin_Tin) => (data: any) => {
    updateTin({
      variables: { id, _set: { [key]: data }},
      optimisticResponse: {
        __typename: 'mutation_root',
        updateTin: {
          ...props,
          [key]: data,
        }
      }
    })
  }

  return $(Fragment, { key: id },
    $(Paper, { sx: { padding: '1rem', marginBottom: '1rem' } },
      $(TypeSelector, {
        type,
        isAnalogue,
        onChange: handleChange
      }),
      $(DebouncedTextField, {
        label: 'Аббревиатура',
        value: abbreviation || '',
        onChange: handleChange('abbreviation')
      }),
      $(DebouncedTextField, {
        label: 'Описание',
        value: description || '',
        onChange: handleChange('description')
      }),
      $(DebouncedTextField, {
        label: 'Процедура присвоения',
        value: assignmentProcedure || '',
        onChange: handleChange('assignmentProcedure')
      }),
      $(DebouncedTextField, {
        label: 'Причины отсутствия',
        value: absenceReason || '',
        onChange: handleChange('absenceReason')
      })),
    $(Paper, { sx: { padding: '1rem', marginBottom: '1rem' }}, 
      $(FormatEditor, {
        format: format,
        onChange: handleChange('format')
      })),
    $(Paper, { sx: { padding: '1rem', marginBottom: '1rem' }}, 
      $(Links, { id, checkLinks })),
    $(Paper, { sx: { padding: '1rem', marginBottom: '1rem' }}, 
      $(Documents, { id, documents })),
    $(DeleteTin, { id, countryCode }))
}

const DebouncedTextField = ({
  value,
  onChange,
  label
}: {
  value: string,
  label: string,
  onChange: (nextValue: string) => void
}) => {
  const [debouncedValue, setDebouncedValue] = useDebounceValue(value, 500, onChange)
  return $(TextField, {
    label,
    variant: 'filled',
    value: debouncedValue,
    margin: 'normal',
    multiline: true,
    onChange: (e) => setDebouncedValue(e.target.value),
    fullWidth: true
  })
}

const DeleteTin = ({
  id,
  countryCode
}: Pick<Props, 'id' | 'countryCode'>) => {
  const [removeTin] = useRemoveTinMutation({
    variables: { id },
    update: (cache) => {
      const countryId = cache.identify({
        __typename: 'tin_country',
        code: countryCode
      })
      cache.modify({
        id: countryId,
        fields: {
          tins: (tins, { readField }) => tins.filter((tinId: any) => id !== readField('id', tinId))
        }
      })
    }
  })
  return $(Button, {
    fullWidth: true,
    variant: 'outlined',
    color: 'error',
    // size: 'small',
    onClick: () => removeTin()
    },
    'Удалить вариант TIN')
}

export default TinVariant