import { Accordion, AccordionDetails, AccordionSummary, Box, styled, Typography } from '@mui/material'
import { createElement as $, FC } from 'react'

const Info: FC = () => {

  return $(Box, { maxWidth: 480, margin: 'auto', marginTop: '10vh', marginBottom: '10vh' },
    $(Accordion, null,
      $(AccordionSummary, null, $(Typography, { variant: 'h5' }, 'Техническое описание')),
      $(AccordionDetails, null, 
        $(Text, { variant: 'body2' }, tech))),
    $(Accordion, null,
      $(AccordionSummary, null, $(Typography, { variant: 'h5' }, 'Системные требования')),
      $(AccordionDetails, null, 
        $(Text, { variant: 'body2' }, spec))),
    $(Accordion, null,
      $(AccordionSummary, null, $(Typography, { variant: 'h5' }, 'Аутетнификация')),
      $(AccordionDetails, null, 
        $(Text, { variant: 'body2' }, authentication.preamble),
        $(Box, {
          sx: { background: 'black' },
          marginTop: '1rem',
          marginBottom: '1rem',
          fontFamily: 'Monospace',
          padding: '1rem',
          whiteSpace: 'pre-wrap'
          }, authentication.code),
        $(Text, { variant: 'body2' }, authentication.cookie))))
}

const Text = styled(Typography)({
  whiteSpace: 'pre-wrap'
})

const tech = `
Система представляет из себя микросервисное решение состоящее из следующих компонентов:
- Postgres 13
- Hasura GraphQL Engine (Haskell)
- Authentication service (TypeScript)
- Frontend (TypeScript)
- Traefik Proxy
Микросервисы поставляются в виде архива с определениями Docker Compose, архивами с docker-образами и миграциями для базы, в т.ч. данные.
Для разворачивания системы необходимо выполнить скрипт deploy.sh который распакует образы, накатит миграции и создаст пользователя.
Система предоставляет тривиальное GraphQL API позволяющее получить все справочные данные, включая правила проверок форматов за один запрос.
Данные возвращаются в соответствии со схемой в формате JSON
`

const spec = 
`Для разворачивания системы на собственных мощностях необходима следующая конфигурация:
Программная часть
- Ubuntu Linux 18.* и выше
- Docker 20.10.5
- Docker Compose 3.6

Аппаратная часть
Виртуальный сервер аналогичный AWS t2.medium
- ≥ 4.0 ГБ ОЗУ
- ≥ 2 ядра
- ≥ 2 ГБ свободного пространства хранилища

При разворачивании используется доступ в приватный Docker Registry. Альтернативой, в случае отсутствия доступа в интернет, является образ виртуальной машины, что несколько усложняет процесс обновления.
`

const authentication = {
  preamble: `Аутентификация осуществляется с использованием пары логин/пароль. Выполняется GraphQL запрос вида:`,
  code: `mutation login(
  $login: String!,
  $password: String!
) {
  login(login: $login, password: $password) {
    ...on InvalidCredentials {
      message
    }
    ...on Token {
      token
      userId
    }
  }
}`,
cookie: `
В ответ в случае ошибки возвращается InvalidCredentials с сообщением, в случае успеха Token.
Так же в случае успеха возвращается хедер Set-Cookie: auth=<TOKEN> выставляющий токен в качестве cookie с ключом auth.
Этот хедер следует обрабатывать на каждый запрос, так как время от времени токен обновляется`
}

export default Info