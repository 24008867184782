import { Box, Button, styled } from '@mui/material'
import { Tin_Document, useAddImageMutation } from 'queries'
import { createElement as $, FC, PropsWithChildren, useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'

type UploadButtonProps = PropsWithChildren & { documentId: Tin_Document['id'] }

const UploadButton: FC<UploadButtonProps> = ({
  documentId,
  children
}) => {
  const loading = false
  const [mutate] = useAddImageMutation()
  const handleChange = useCallback((e: any) => {
    if (e.target.files.length === 0)
      return null
    const reader = new FileReader()
    reader.onload = () => {
      const image = reader.result as string
      const id = uuidv4()
      mutate({
        variables: { documentId, image, id },
        update: (cache) =>
        cache.modify({
          id: cache.identify({ __typename: 'tin_document', id: documentId }),
          fields: {
            image: () => ({
              id,
              image: reader.result
            })
          }
        })
      })
    }
    reader.readAsDataURL(e.target.files[0])
  }, [mutate, documentId])

  return $(Box, { padding: '.5rem 0' },
    $(Button, {
      sx: { width: '100%', padding: 0 },
      fullWidth: true,
      variant: 'outlined',
      disabled: loading
      },
      children,
      $(HiddenInput, {
        type: 'file',
        accept: 'image/*',
        onChange: handleChange
      })))
}

const HiddenInput = styled('input')({
  width: '100%',
  height: '100%',
  opacity: 0,
  position: 'absolute',
  cursor: 'pointer',
})

export default UploadButton