import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  bigint: { input: any; output: any; }
  inet: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  oid: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
  uuid: { input: string; output: string; }
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type InvalidCredentials = {
  __typename?: 'InvalidCredentials';
  message?: Maybe<Scalars['String']['output']>;
};

export type LoginResult = InvalidCredentials | Token;

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

export type Token = {
  __typename?: 'Token';
  token: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

/** columns and relationships of "audit.log" */
export type Audit_Log = {
  __typename?: 'audit_log';
  changedFields?: Maybe<Scalars['jsonb']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  tin?: Maybe<Tin_Tin>;
  tinId?: Maybe<Scalars['uuid']['output']>;
};


/** columns and relationships of "audit.log" */
export type Audit_LogChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "audit.log" */
export type Audit_Log_Aggregate = {
  __typename?: 'audit_log_aggregate';
  aggregate?: Maybe<Audit_Log_Aggregate_Fields>;
  nodes: Array<Audit_Log>;
};

/** aggregate fields of "audit.log" */
export type Audit_Log_Aggregate_Fields = {
  __typename?: 'audit_log_aggregate_fields';
  avg?: Maybe<Audit_Log_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Audit_Log_Max_Fields>;
  min?: Maybe<Audit_Log_Min_Fields>;
  stddev?: Maybe<Audit_Log_Stddev_Fields>;
  stddev_pop?: Maybe<Audit_Log_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Audit_Log_Stddev_Samp_Fields>;
  sum?: Maybe<Audit_Log_Sum_Fields>;
  var_pop?: Maybe<Audit_Log_Var_Pop_Fields>;
  var_samp?: Maybe<Audit_Log_Var_Samp_Fields>;
  variance?: Maybe<Audit_Log_Variance_Fields>;
};


/** aggregate fields of "audit.log" */
export type Audit_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Audit_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "audit.log" */
export type Audit_Log_Aggregate_Order_By = {
  avg?: InputMaybe<Audit_Log_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Audit_Log_Max_Order_By>;
  min?: InputMaybe<Audit_Log_Min_Order_By>;
  stddev?: InputMaybe<Audit_Log_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Audit_Log_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Audit_Log_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Audit_Log_Sum_Order_By>;
  var_pop?: InputMaybe<Audit_Log_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Audit_Log_Var_Samp_Order_By>;
  variance?: InputMaybe<Audit_Log_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "audit.log" */
export type Audit_Log_Arr_Rel_Insert_Input = {
  data: Array<Audit_Log_Insert_Input>;
};

/** aggregate avg on columns */
export type Audit_Log_Avg_Fields = {
  __typename?: 'audit_log_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "audit.log" */
export type Audit_Log_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "audit.log". All fields are combined with a logical 'AND'. */
export type Audit_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Audit_Log_Bool_Exp>>;
  _not?: InputMaybe<Audit_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Audit_Log_Bool_Exp>>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  countryCode?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  tin?: InputMaybe<Tin_Tin_Bool_Exp>;
  tinId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "audit.log" */
export type Audit_Log_Insert_Input = {
  changedFields?: InputMaybe<Scalars['jsonb']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  tin?: InputMaybe<Tin_Tin_Obj_Rel_Insert_Input>;
  tinId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Audit_Log_Max_Fields = {
  __typename?: 'audit_log_max_fields';
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  tinId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "audit.log" */
export type Audit_Log_Max_Order_By = {
  countryCode?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tinId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Audit_Log_Min_Fields = {
  __typename?: 'audit_log_min_fields';
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  tinId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "audit.log" */
export type Audit_Log_Min_Order_By = {
  countryCode?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tinId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "audit.log". */
export type Audit_Log_Order_By = {
  changedFields?: InputMaybe<Order_By>;
  countryCode?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tin?: InputMaybe<Tin_Tin_Order_By>;
  tinId?: InputMaybe<Order_By>;
};

/** select columns of table "audit.log" */
export enum Audit_Log_Select_Column {
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  TinId = 'tinId'
}

/** aggregate stddev on columns */
export type Audit_Log_Stddev_Fields = {
  __typename?: 'audit_log_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "audit.log" */
export type Audit_Log_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Audit_Log_Stddev_Pop_Fields = {
  __typename?: 'audit_log_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "audit.log" */
export type Audit_Log_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Audit_Log_Stddev_Samp_Fields = {
  __typename?: 'audit_log_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "audit.log" */
export type Audit_Log_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Audit_Log_Sum_Fields = {
  __typename?: 'audit_log_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "audit.log" */
export type Audit_Log_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Audit_Log_Var_Pop_Fields = {
  __typename?: 'audit_log_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "audit.log" */
export type Audit_Log_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Audit_Log_Var_Samp_Fields = {
  __typename?: 'audit_log_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "audit.log" */
export type Audit_Log_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Audit_Log_Variance_Fields = {
  __typename?: 'audit_log_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "audit.log" */
export type Audit_Log_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** History of auditable actions on audited tables, from audit.if_modified_func() */
export type Audit_Logged_Actions = {
  __typename?: 'audit_logged_actions';
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action: Scalars['String']['output'];
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk: Scalars['timestamptz']['output'];
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm: Scalars['timestamptz']['output'];
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx: Scalars['timestamptz']['output'];
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: Maybe<Scalars['String']['output']>;
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: Maybe<Scalars['jsonb']['output']>;
  /** IP address of client that issued query. Null for unix domain socket. */
  client_addr?: Maybe<Scalars['inet']['output']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Int']['output']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: Maybe<Scalars['String']['output']>;
  /** Unique identifier for each auditable event */
  event_id: Scalars['bigint']['output'];
  hasura_user?: Maybe<Scalars['jsonb']['output']>;
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid: Scalars['oid']['output'];
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: Maybe<Scalars['jsonb']['output']>;
  /** Database schema audited table for this event is in */
  schema_name: Scalars['String']['output'];
  /** Login / session user whose statement caused the audited event */
  session_user_name?: Maybe<Scalars['String']['output']>;
  /** 't' if audit event is from an FOR EACH STATEMENT trigger, 'f' for FOR EACH ROW */
  statement_only: Scalars['Boolean']['output'];
  /** Non-schema-qualified table name of table event occured in */
  table_name: Scalars['String']['output'];
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['bigint']['output']>;
};


/** History of auditable actions on audited tables, from audit.if_modified_func() */
export type Audit_Logged_ActionsChanged_FieldsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** History of auditable actions on audited tables, from audit.if_modified_func() */
export type Audit_Logged_ActionsHasura_UserArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** History of auditable actions on audited tables, from audit.if_modified_func() */
export type Audit_Logged_ActionsRow_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "audit.logged_actions" */
export type Audit_Logged_Actions_Aggregate = {
  __typename?: 'audit_logged_actions_aggregate';
  aggregate?: Maybe<Audit_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Audit_Logged_Actions>;
};

/** aggregate fields of "audit.logged_actions" */
export type Audit_Logged_Actions_Aggregate_Fields = {
  __typename?: 'audit_logged_actions_aggregate_fields';
  avg?: Maybe<Audit_Logged_Actions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Audit_Logged_Actions_Max_Fields>;
  min?: Maybe<Audit_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Audit_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Audit_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Audit_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Audit_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Audit_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Audit_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Audit_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "audit.logged_actions" */
export type Audit_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Audit_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Audit_Logged_Actions_Append_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Audit_Logged_Actions_Avg_Fields = {
  __typename?: 'audit_logged_actions_avg_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']['output']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']['output']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "audit.logged_actions". All fields are combined with a logical 'AND'. */
export type Audit_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Audit_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Audit_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Audit_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  action_tstamp_clk?: InputMaybe<Timestamptz_Comparison_Exp>;
  action_tstamp_stm?: InputMaybe<Timestamptz_Comparison_Exp>;
  action_tstamp_tx?: InputMaybe<Timestamptz_Comparison_Exp>;
  application_name?: InputMaybe<String_Comparison_Exp>;
  changed_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  client_addr?: InputMaybe<Inet_Comparison_Exp>;
  client_port?: InputMaybe<Int_Comparison_Exp>;
  client_query?: InputMaybe<String_Comparison_Exp>;
  event_id?: InputMaybe<Bigint_Comparison_Exp>;
  hasura_user?: InputMaybe<Jsonb_Comparison_Exp>;
  relid?: InputMaybe<Oid_Comparison_Exp>;
  row_data?: InputMaybe<Jsonb_Comparison_Exp>;
  schema_name?: InputMaybe<String_Comparison_Exp>;
  session_user_name?: InputMaybe<String_Comparison_Exp>;
  statement_only?: InputMaybe<Boolean_Comparison_Exp>;
  table_name?: InputMaybe<String_Comparison_Exp>;
  transaction_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "audit.logged_actions" */
export enum Audit_Logged_Actions_Constraint {
  /** unique or primary key constraint on columns "event_id" */
  LoggedActionsPkey = 'logged_actions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Audit_Logged_Actions_Delete_At_Path_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Array<Scalars['String']['input']>>;
  hasura_user?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Audit_Logged_Actions_Delete_Elem_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['Int']['input']>;
  hasura_user?: InputMaybe<Scalars['Int']['input']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Audit_Logged_Actions_Delete_Key_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['String']['input']>;
  hasura_user?: InputMaybe<Scalars['String']['input']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "audit.logged_actions" */
export type Audit_Logged_Actions_Inc_Input = {
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: InputMaybe<Scalars['Int']['input']>;
  /** Unique identifier for each auditable event */
  event_id?: InputMaybe<Scalars['bigint']['input']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "audit.logged_actions" */
export type Audit_Logged_Actions_Insert_Input = {
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: InputMaybe<Scalars['String']['input']>;
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?: InputMaybe<Scalars['timestamptz']['input']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: InputMaybe<Scalars['timestamptz']['input']>;
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?: InputMaybe<Scalars['timestamptz']['input']>;
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: InputMaybe<Scalars['String']['input']>;
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  /** IP address of client that issued query. Null for unix domain socket. */
  client_addr?: InputMaybe<Scalars['inet']['input']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: InputMaybe<Scalars['Int']['input']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: InputMaybe<Scalars['String']['input']>;
  /** Unique identifier for each auditable event */
  event_id?: InputMaybe<Scalars['bigint']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid?: InputMaybe<Scalars['oid']['input']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
  /** Database schema audited table for this event is in */
  schema_name?: InputMaybe<Scalars['String']['input']>;
  /** Login / session user whose statement caused the audited event */
  session_user_name?: InputMaybe<Scalars['String']['input']>;
  /** 't' if audit event is from an FOR EACH STATEMENT trigger, 'f' for FOR EACH ROW */
  statement_only?: InputMaybe<Scalars['Boolean']['input']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?: InputMaybe<Scalars['String']['input']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Audit_Logged_Actions_Max_Fields = {
  __typename?: 'audit_logged_actions_max_fields';
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: Maybe<Scalars['String']['output']>;
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?: Maybe<Scalars['timestamptz']['output']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: Maybe<Scalars['timestamptz']['output']>;
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?: Maybe<Scalars['timestamptz']['output']>;
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: Maybe<Scalars['String']['output']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Int']['output']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: Maybe<Scalars['String']['output']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['bigint']['output']>;
  /** Database schema audited table for this event is in */
  schema_name?: Maybe<Scalars['String']['output']>;
  /** Login / session user whose statement caused the audited event */
  session_user_name?: Maybe<Scalars['String']['output']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?: Maybe<Scalars['String']['output']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Audit_Logged_Actions_Min_Fields = {
  __typename?: 'audit_logged_actions_min_fields';
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: Maybe<Scalars['String']['output']>;
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?: Maybe<Scalars['timestamptz']['output']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: Maybe<Scalars['timestamptz']['output']>;
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?: Maybe<Scalars['timestamptz']['output']>;
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: Maybe<Scalars['String']['output']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Int']['output']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: Maybe<Scalars['String']['output']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['bigint']['output']>;
  /** Database schema audited table for this event is in */
  schema_name?: Maybe<Scalars['String']['output']>;
  /** Login / session user whose statement caused the audited event */
  session_user_name?: Maybe<Scalars['String']['output']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?: Maybe<Scalars['String']['output']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "audit.logged_actions" */
export type Audit_Logged_Actions_Mutation_Response = {
  __typename?: 'audit_logged_actions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Audit_Logged_Actions>;
};

/** on_conflict condition type for table "audit.logged_actions" */
export type Audit_Logged_Actions_On_Conflict = {
  constraint: Audit_Logged_Actions_Constraint;
  update_columns?: Array<Audit_Logged_Actions_Update_Column>;
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};

/** Ordering options when selecting data from "audit.logged_actions". */
export type Audit_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  action_tstamp_clk?: InputMaybe<Order_By>;
  action_tstamp_stm?: InputMaybe<Order_By>;
  action_tstamp_tx?: InputMaybe<Order_By>;
  application_name?: InputMaybe<Order_By>;
  changed_fields?: InputMaybe<Order_By>;
  client_addr?: InputMaybe<Order_By>;
  client_port?: InputMaybe<Order_By>;
  client_query?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  hasura_user?: InputMaybe<Order_By>;
  relid?: InputMaybe<Order_By>;
  row_data?: InputMaybe<Order_By>;
  schema_name?: InputMaybe<Order_By>;
  session_user_name?: InputMaybe<Order_By>;
  statement_only?: InputMaybe<Order_By>;
  table_name?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: audit_logged_actions */
export type Audit_Logged_Actions_Pk_Columns_Input = {
  /** Unique identifier for each auditable event */
  event_id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Audit_Logged_Actions_Prepend_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "audit.logged_actions" */
export enum Audit_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTstampClk = 'action_tstamp_clk',
  /** column name */
  ActionTstampStm = 'action_tstamp_stm',
  /** column name */
  ActionTstampTx = 'action_tstamp_tx',
  /** column name */
  ApplicationName = 'application_name',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ClientAddr = 'client_addr',
  /** column name */
  ClientPort = 'client_port',
  /** column name */
  ClientQuery = 'client_query',
  /** column name */
  EventId = 'event_id',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  Relid = 'relid',
  /** column name */
  RowData = 'row_data',
  /** column name */
  SchemaName = 'schema_name',
  /** column name */
  SessionUserName = 'session_user_name',
  /** column name */
  StatementOnly = 'statement_only',
  /** column name */
  TableName = 'table_name',
  /** column name */
  TransactionId = 'transaction_id'
}

/** input type for updating data in table "audit.logged_actions" */
export type Audit_Logged_Actions_Set_Input = {
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: InputMaybe<Scalars['String']['input']>;
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?: InputMaybe<Scalars['timestamptz']['input']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: InputMaybe<Scalars['timestamptz']['input']>;
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?: InputMaybe<Scalars['timestamptz']['input']>;
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: InputMaybe<Scalars['String']['input']>;
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  /** IP address of client that issued query. Null for unix domain socket. */
  client_addr?: InputMaybe<Scalars['inet']['input']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: InputMaybe<Scalars['Int']['input']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: InputMaybe<Scalars['String']['input']>;
  /** Unique identifier for each auditable event */
  event_id?: InputMaybe<Scalars['bigint']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid?: InputMaybe<Scalars['oid']['input']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
  /** Database schema audited table for this event is in */
  schema_name?: InputMaybe<Scalars['String']['input']>;
  /** Login / session user whose statement caused the audited event */
  session_user_name?: InputMaybe<Scalars['String']['input']>;
  /** 't' if audit event is from an FOR EACH STATEMENT trigger, 'f' for FOR EACH ROW */
  statement_only?: InputMaybe<Scalars['Boolean']['input']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?: InputMaybe<Scalars['String']['input']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Audit_Logged_Actions_Stddev_Fields = {
  __typename?: 'audit_logged_actions_stddev_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']['output']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']['output']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Audit_Logged_Actions_Stddev_Pop_Fields = {
  __typename?: 'audit_logged_actions_stddev_pop_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']['output']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']['output']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Audit_Logged_Actions_Stddev_Samp_Fields = {
  __typename?: 'audit_logged_actions_stddev_samp_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']['output']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']['output']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate sum on columns */
export type Audit_Logged_Actions_Sum_Fields = {
  __typename?: 'audit_logged_actions_sum_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Int']['output']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['bigint']['output']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "audit.logged_actions" */
export enum Audit_Logged_Actions_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTstampClk = 'action_tstamp_clk',
  /** column name */
  ActionTstampStm = 'action_tstamp_stm',
  /** column name */
  ActionTstampTx = 'action_tstamp_tx',
  /** column name */
  ApplicationName = 'application_name',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ClientAddr = 'client_addr',
  /** column name */
  ClientPort = 'client_port',
  /** column name */
  ClientQuery = 'client_query',
  /** column name */
  EventId = 'event_id',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  Relid = 'relid',
  /** column name */
  RowData = 'row_data',
  /** column name */
  SchemaName = 'schema_name',
  /** column name */
  SessionUserName = 'session_user_name',
  /** column name */
  StatementOnly = 'statement_only',
  /** column name */
  TableName = 'table_name',
  /** column name */
  TransactionId = 'transaction_id'
}

export type Audit_Logged_Actions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Audit_Logged_Actions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Audit_Logged_Actions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Audit_Logged_Actions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Audit_Logged_Actions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Audit_Logged_Actions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Audit_Logged_Actions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Audit_Logged_Actions_Set_Input>;
  where: Audit_Logged_Actions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Audit_Logged_Actions_Var_Pop_Fields = {
  __typename?: 'audit_logged_actions_var_pop_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']['output']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']['output']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Audit_Logged_Actions_Var_Samp_Fields = {
  __typename?: 'audit_logged_actions_var_samp_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']['output']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']['output']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Audit_Logged_Actions_Variance_Fields = {
  __typename?: 'audit_logged_actions_variance_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']['output']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']['output']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "auth.logout" */
export type Auth_Logout = {
  __typename?: 'auth_logout';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
};

/** aggregated selection of "auth.logout" */
export type Auth_Logout_Aggregate = {
  __typename?: 'auth_logout_aggregate';
  aggregate?: Maybe<Auth_Logout_Aggregate_Fields>;
  nodes: Array<Auth_Logout>;
};

/** aggregate fields of "auth.logout" */
export type Auth_Logout_Aggregate_Fields = {
  __typename?: 'auth_logout_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Auth_Logout_Max_Fields>;
  min?: Maybe<Auth_Logout_Min_Fields>;
};


/** aggregate fields of "auth.logout" */
export type Auth_Logout_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Logout_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.logout". All fields are combined with a logical 'AND'. */
export type Auth_Logout_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Logout_Bool_Exp>>;
  _not?: InputMaybe<Auth_Logout_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Logout_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.logout" */
export enum Auth_Logout_Constraint {
  /** unique or primary key constraint on columns "id" */
  LogoutPkey = 'logout_pkey'
}

/** input type for inserting data into table "auth.logout" */
export type Auth_Logout_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Auth_Logout_Max_Fields = {
  __typename?: 'auth_logout_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Auth_Logout_Min_Fields = {
  __typename?: 'auth_logout_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "auth.logout" */
export type Auth_Logout_Mutation_Response = {
  __typename?: 'auth_logout_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Logout>;
};

/** on_conflict condition type for table "auth.logout" */
export type Auth_Logout_On_Conflict = {
  constraint: Auth_Logout_Constraint;
  update_columns?: Array<Auth_Logout_Update_Column>;
  where?: InputMaybe<Auth_Logout_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.logout". */
export type Auth_Logout_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth_logout */
export type Auth_Logout_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.logout" */
export enum Auth_Logout_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "auth.logout" */
export type Auth_Logout_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "auth.logout" */
export enum Auth_Logout_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id'
}

export type Auth_Logout_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Logout_Set_Input>;
  where: Auth_Logout_Bool_Exp;
};

/** columns and relationships of "auth.role" */
export type Auth_Role = {
  __typename?: 'auth_role';
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** aggregated selection of "auth.role" */
export type Auth_Role_Aggregate = {
  __typename?: 'auth_role_aggregate';
  aggregate?: Maybe<Auth_Role_Aggregate_Fields>;
  nodes: Array<Auth_Role>;
};

/** aggregate fields of "auth.role" */
export type Auth_Role_Aggregate_Fields = {
  __typename?: 'auth_role_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Auth_Role_Max_Fields>;
  min?: Maybe<Auth_Role_Min_Fields>;
};


/** aggregate fields of "auth.role" */
export type Auth_Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.role". All fields are combined with a logical 'AND'. */
export type Auth_Role_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Role_Bool_Exp>>;
  _not?: InputMaybe<Auth_Role_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Role_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.role" */
export enum Auth_Role_Constraint {
  /** unique or primary key constraint on columns "name" */
  RolePkey = 'role_pkey'
}

export enum Auth_Role_Enum {
  Admin = 'ADMIN',
  User = 'USER'
}

/** Boolean expression to compare columns of type "auth_role_enum". All fields are combined with logical 'AND'. */
export type Auth_Role_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Auth_Role_Enum>;
  _in?: InputMaybe<Array<Auth_Role_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Auth_Role_Enum>;
  _nin?: InputMaybe<Array<Auth_Role_Enum>>;
};

/** input type for inserting data into table "auth.role" */
export type Auth_Role_Insert_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Auth_Role_Max_Fields = {
  __typename?: 'auth_role_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Auth_Role_Min_Fields = {
  __typename?: 'auth_role_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.role" */
export type Auth_Role_Mutation_Response = {
  __typename?: 'auth_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Role>;
};

/** on_conflict condition type for table "auth.role" */
export type Auth_Role_On_Conflict = {
  constraint: Auth_Role_Constraint;
  update_columns?: Array<Auth_Role_Update_Column>;
  where?: InputMaybe<Auth_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.role". */
export type Auth_Role_Order_By = {
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth_role */
export type Auth_Role_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "auth.role" */
export enum Auth_Role_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "auth.role" */
export type Auth_Role_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.role" */
export enum Auth_Role_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

export type Auth_Role_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Role_Set_Input>;
  where: Auth_Role_Bool_Exp;
};

/** columns and relationships of "auth.token" */
export type Auth_Token = {
  __typename?: 'auth_token';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "auth.token" */
export type Auth_Token_Aggregate = {
  __typename?: 'auth_token_aggregate';
  aggregate?: Maybe<Auth_Token_Aggregate_Fields>;
  nodes: Array<Auth_Token>;
};

/** aggregate fields of "auth.token" */
export type Auth_Token_Aggregate_Fields = {
  __typename?: 'auth_token_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Auth_Token_Max_Fields>;
  min?: Maybe<Auth_Token_Min_Fields>;
};


/** aggregate fields of "auth.token" */
export type Auth_Token_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Token_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.token". All fields are combined with a logical 'AND'. */
export type Auth_Token_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Token_Bool_Exp>>;
  _not?: InputMaybe<Auth_Token_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Token_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.token" */
export enum Auth_Token_Constraint {
  /** unique or primary key constraint on columns "id" */
  TokenPkey = 'token_pkey'
}

/** input type for inserting data into table "auth.token" */
export type Auth_Token_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Auth_Token_Max_Fields = {
  __typename?: 'auth_token_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Auth_Token_Min_Fields = {
  __typename?: 'auth_token_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "auth.token" */
export type Auth_Token_Mutation_Response = {
  __typename?: 'auth_token_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Token>;
};

/** on_conflict condition type for table "auth.token" */
export type Auth_Token_On_Conflict = {
  constraint: Auth_Token_Constraint;
  update_columns?: Array<Auth_Token_Update_Column>;
  where?: InputMaybe<Auth_Token_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.token". */
export type Auth_Token_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth_token */
export type Auth_Token_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.token" */
export enum Auth_Token_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "auth.token" */
export type Auth_Token_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "auth.token" */
export enum Auth_Token_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export type Auth_Token_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Token_Set_Input>;
  where: Auth_Token_Bool_Exp;
};

/** columns and relationships of "auth.user" */
export type Auth_User = {
  __typename?: 'auth_user';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  login?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  role: Auth_Role_Enum;
};

/** aggregated selection of "auth.user" */
export type Auth_User_Aggregate = {
  __typename?: 'auth_user_aggregate';
  aggregate?: Maybe<Auth_User_Aggregate_Fields>;
  nodes: Array<Auth_User>;
};

/** aggregate fields of "auth.user" */
export type Auth_User_Aggregate_Fields = {
  __typename?: 'auth_user_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Auth_User_Max_Fields>;
  min?: Maybe<Auth_User_Min_Fields>;
};


/** aggregate fields of "auth.user" */
export type Auth_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.user". All fields are combined with a logical 'AND'. */
export type Auth_User_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_User_Bool_Exp>>;
  _not?: InputMaybe<Auth_User_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_User_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  login?: InputMaybe<String_Comparison_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<Auth_Role_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.user" */
export enum Auth_User_Constraint {
  /** unique or primary key constraint on columns "login" */
  UserLoginKey = 'user_login_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey'
}

/** input type for inserting data into table "auth.user" */
export type Auth_User_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  login?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Auth_Role_Enum>;
};

/** aggregate max on columns */
export type Auth_User_Max_Fields = {
  __typename?: 'auth_user_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  login?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Auth_User_Min_Fields = {
  __typename?: 'auth_user_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  login?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.user" */
export type Auth_User_Mutation_Response = {
  __typename?: 'auth_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_User>;
};

/** on_conflict condition type for table "auth.user" */
export type Auth_User_On_Conflict = {
  constraint: Auth_User_Constraint;
  update_columns?: Array<Auth_User_Update_Column>;
  where?: InputMaybe<Auth_User_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.user". */
export type Auth_User_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  login?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth_user */
export type Auth_User_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.user" */
export enum Auth_User_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Login = 'login',
  /** column name */
  Password = 'password',
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "auth.user" */
export type Auth_User_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  login?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Auth_Role_Enum>;
};

/** update columns of table "auth.user" */
export enum Auth_User_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Login = 'login',
  /** column name */
  Password = 'password',
  /** column name */
  Role = 'role'
}

export type Auth_User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_User_Set_Input>;
  where: Auth_User_Bool_Exp;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** Boolean expression to compare columns of type "inet". All fields are combined with logical 'AND'. */
export type Inet_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['inet']['input']>;
  _gt?: InputMaybe<Scalars['inet']['input']>;
  _gte?: InputMaybe<Scalars['inet']['input']>;
  _in?: InputMaybe<Array<Scalars['inet']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['inet']['input']>;
  _lte?: InputMaybe<Scalars['inet']['input']>;
  _neq?: InputMaybe<Scalars['inet']['input']>;
  _nin?: InputMaybe<Array<Scalars['inet']['input']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** insert a single row into the table: "tin.check_link" */
  addCheckLink?: Maybe<Tin_Check_Link>;
  /** insert data into the table: "tin.check_link" */
  addCheckLinks?: Maybe<Tin_Check_Link_Mutation_Response>;
  /** insert a single row into the table: "tin.document" */
  addDocument?: Maybe<Tin_Document>;
  /** insert data into the table: "tin.document" */
  addDocuments?: Maybe<Tin_Document_Mutation_Response>;
  /** insert a single row into the table: "tin.image" */
  addImage?: Maybe<Tin_Image>;
  /** insert data into the table: "tin.image" */
  addImages?: Maybe<Tin_Image_Mutation_Response>;
  /** insert a single row into the table: "tin.tin" */
  addTin?: Maybe<Tin_Tin>;
  /** insert data into the table: "tin.tin" */
  addTins?: Maybe<Tin_Tin_Mutation_Response>;
  /** delete data from the table: "audit.logged_actions" */
  delete_audit_logged_actions?: Maybe<Audit_Logged_Actions_Mutation_Response>;
  /** delete single row from the table: "audit.logged_actions" */
  delete_audit_logged_actions_by_pk?: Maybe<Audit_Logged_Actions>;
  /** delete data from the table: "auth.logout" */
  delete_auth_logout?: Maybe<Auth_Logout_Mutation_Response>;
  /** delete single row from the table: "auth.logout" */
  delete_auth_logout_by_pk?: Maybe<Auth_Logout>;
  /** delete data from the table: "auth.role" */
  delete_auth_role?: Maybe<Auth_Role_Mutation_Response>;
  /** delete single row from the table: "auth.role" */
  delete_auth_role_by_pk?: Maybe<Auth_Role>;
  /** delete data from the table: "auth.token" */
  delete_auth_token?: Maybe<Auth_Token_Mutation_Response>;
  /** delete single row from the table: "auth.token" */
  delete_auth_token_by_pk?: Maybe<Auth_Token>;
  /** delete data from the table: "auth.user" */
  delete_auth_user?: Maybe<Auth_User_Mutation_Response>;
  /** delete single row from the table: "auth.user" */
  delete_auth_user_by_pk?: Maybe<Auth_User>;
  /** delete data from the table: "tariff.tariff" */
  delete_tariff_tariff?: Maybe<Tariff_Tariff_Mutation_Response>;
  /** delete single row from the table: "tariff.tariff" */
  delete_tariff_tariff_by_pk?: Maybe<Tariff_Tariff>;
  /** delete data from the table: "tariff.tariff_country" */
  delete_tariff_tariff_country?: Maybe<Tariff_Tariff_Country_Mutation_Response>;
  /** delete single row from the table: "tariff.tariff_country" */
  delete_tariff_tariff_country_by_pk?: Maybe<Tariff_Tariff_Country>;
  /** delete data from the table: "tariff.tariff_user" */
  delete_tariff_tariff_user?: Maybe<Tariff_Tariff_User_Mutation_Response>;
  /** delete single row from the table: "tariff.tariff_user" */
  delete_tariff_tariff_user_by_pk?: Maybe<Tariff_Tariff_User>;
  /** delete data from the table: "tariff.user_country" */
  delete_tariff_user_country?: Maybe<Tariff_User_Country_Mutation_Response>;
  /** delete single row from the table: "tariff.user_country" */
  delete_tariff_user_country_by_pk?: Maybe<Tariff_User_Country>;
  /** delete data from the table: "tin.country" */
  delete_tin_country?: Maybe<Tin_Country_Mutation_Response>;
  /** delete single row from the table: "tin.country" */
  delete_tin_country_by_pk?: Maybe<Tin_Country>;
  /** delete data from the table: "tin.tin_type" */
  delete_tin_tin_type?: Maybe<Tin_Tin_Type_Mutation_Response>;
  /** delete single row from the table: "tin.tin_type" */
  delete_tin_tin_type_by_pk?: Maybe<Tin_Tin_Type>;
  /** insert data into the table: "audit.logged_actions" */
  insert_audit_logged_actions?: Maybe<Audit_Logged_Actions_Mutation_Response>;
  /** insert a single row into the table: "audit.logged_actions" */
  insert_audit_logged_actions_one?: Maybe<Audit_Logged_Actions>;
  /** insert data into the table: "auth.logout" */
  insert_auth_logout?: Maybe<Auth_Logout_Mutation_Response>;
  /** insert a single row into the table: "auth.logout" */
  insert_auth_logout_one?: Maybe<Auth_Logout>;
  /** insert data into the table: "auth.role" */
  insert_auth_role?: Maybe<Auth_Role_Mutation_Response>;
  /** insert a single row into the table: "auth.role" */
  insert_auth_role_one?: Maybe<Auth_Role>;
  /** insert data into the table: "auth.token" */
  insert_auth_token?: Maybe<Auth_Token_Mutation_Response>;
  /** insert a single row into the table: "auth.token" */
  insert_auth_token_one?: Maybe<Auth_Token>;
  /** insert data into the table: "auth.user" */
  insert_auth_user?: Maybe<Auth_User_Mutation_Response>;
  /** insert a single row into the table: "auth.user" */
  insert_auth_user_one?: Maybe<Auth_User>;
  /** insert data into the table: "tariff.tariff" */
  insert_tariff_tariff?: Maybe<Tariff_Tariff_Mutation_Response>;
  /** insert data into the table: "tariff.tariff_country" */
  insert_tariff_tariff_country?: Maybe<Tariff_Tariff_Country_Mutation_Response>;
  /** insert a single row into the table: "tariff.tariff_country" */
  insert_tariff_tariff_country_one?: Maybe<Tariff_Tariff_Country>;
  /** insert a single row into the table: "tariff.tariff" */
  insert_tariff_tariff_one?: Maybe<Tariff_Tariff>;
  /** insert data into the table: "tariff.tariff_user" */
  insert_tariff_tariff_user?: Maybe<Tariff_Tariff_User_Mutation_Response>;
  /** insert a single row into the table: "tariff.tariff_user" */
  insert_tariff_tariff_user_one?: Maybe<Tariff_Tariff_User>;
  /** insert data into the table: "tariff.user_country" */
  insert_tariff_user_country?: Maybe<Tariff_User_Country_Mutation_Response>;
  /** insert a single row into the table: "tariff.user_country" */
  insert_tariff_user_country_one?: Maybe<Tariff_User_Country>;
  /** insert data into the table: "tin.country" */
  insert_tin_country?: Maybe<Tin_Country_Mutation_Response>;
  /** insert a single row into the table: "tin.country" */
  insert_tin_country_one?: Maybe<Tin_Country>;
  /** insert data into the table: "tin.tin_type" */
  insert_tin_tin_type?: Maybe<Tin_Tin_Type_Mutation_Response>;
  /** insert a single row into the table: "tin.tin_type" */
  insert_tin_tin_type_one?: Maybe<Tin_Tin_Type>;
  login: LoginResult;
  logout?: Maybe<Scalars['Boolean']['output']>;
  /** delete single row from the table: "tin.check_link" */
  removeCheckLink?: Maybe<Tin_Check_Link>;
  /** delete data from the table: "tin.check_link" */
  removeCheckLinks?: Maybe<Tin_Check_Link_Mutation_Response>;
  /** delete single row from the table: "tin.document" */
  removeDocument?: Maybe<Tin_Document>;
  /** delete data from the table: "tin.document" */
  removeDocuments?: Maybe<Tin_Document_Mutation_Response>;
  /** delete single row from the table: "tin.image" */
  removeImage?: Maybe<Tin_Image>;
  /** delete data from the table: "tin.image" */
  removeImages?: Maybe<Tin_Image_Mutation_Response>;
  /** delete single row from the table: "tin.tin" */
  removeTin?: Maybe<Tin_Tin>;
  /** delete data from the table: "tin.tin" */
  removeTins?: Maybe<Tin_Tin_Mutation_Response>;
  /** update single row of the table: "tin.check_link" */
  updateChecklink?: Maybe<Tin_Check_Link>;
  /** update data of the table: "tin.check_link" */
  updateChecklinks?: Maybe<Tin_Check_Link_Mutation_Response>;
  /** update single row of the table: "tin.document" */
  updateDocument?: Maybe<Tin_Document>;
  /** update data of the table: "tin.document" */
  updateDocuments?: Maybe<Tin_Document_Mutation_Response>;
  /** update single row of the table: "tin.image" */
  updateImage?: Maybe<Tin_Image>;
  /** update data of the table: "tin.image" */
  updateImages?: Maybe<Tin_Image_Mutation_Response>;
  /** update single row of the table: "tin.tin" */
  updateTin?: Maybe<Tin_Tin>;
  /** update data of the table: "tin.tin" */
  updateTins?: Maybe<Tin_Tin_Mutation_Response>;
  /** update data of the table: "audit.logged_actions" */
  update_audit_logged_actions?: Maybe<Audit_Logged_Actions_Mutation_Response>;
  /** update single row of the table: "audit.logged_actions" */
  update_audit_logged_actions_by_pk?: Maybe<Audit_Logged_Actions>;
  /** update multiples rows of table: "audit.logged_actions" */
  update_audit_logged_actions_many?: Maybe<Array<Maybe<Audit_Logged_Actions_Mutation_Response>>>;
  /** update data of the table: "auth.logout" */
  update_auth_logout?: Maybe<Auth_Logout_Mutation_Response>;
  /** update single row of the table: "auth.logout" */
  update_auth_logout_by_pk?: Maybe<Auth_Logout>;
  /** update multiples rows of table: "auth.logout" */
  update_auth_logout_many?: Maybe<Array<Maybe<Auth_Logout_Mutation_Response>>>;
  /** update data of the table: "auth.role" */
  update_auth_role?: Maybe<Auth_Role_Mutation_Response>;
  /** update single row of the table: "auth.role" */
  update_auth_role_by_pk?: Maybe<Auth_Role>;
  /** update multiples rows of table: "auth.role" */
  update_auth_role_many?: Maybe<Array<Maybe<Auth_Role_Mutation_Response>>>;
  /** update data of the table: "auth.token" */
  update_auth_token?: Maybe<Auth_Token_Mutation_Response>;
  /** update single row of the table: "auth.token" */
  update_auth_token_by_pk?: Maybe<Auth_Token>;
  /** update multiples rows of table: "auth.token" */
  update_auth_token_many?: Maybe<Array<Maybe<Auth_Token_Mutation_Response>>>;
  /** update data of the table: "auth.user" */
  update_auth_user?: Maybe<Auth_User_Mutation_Response>;
  /** update single row of the table: "auth.user" */
  update_auth_user_by_pk?: Maybe<Auth_User>;
  /** update multiples rows of table: "auth.user" */
  update_auth_user_many?: Maybe<Array<Maybe<Auth_User_Mutation_Response>>>;
  /** update data of the table: "tariff.tariff" */
  update_tariff_tariff?: Maybe<Tariff_Tariff_Mutation_Response>;
  /** update single row of the table: "tariff.tariff" */
  update_tariff_tariff_by_pk?: Maybe<Tariff_Tariff>;
  /** update data of the table: "tariff.tariff_country" */
  update_tariff_tariff_country?: Maybe<Tariff_Tariff_Country_Mutation_Response>;
  /** update single row of the table: "tariff.tariff_country" */
  update_tariff_tariff_country_by_pk?: Maybe<Tariff_Tariff_Country>;
  /** update multiples rows of table: "tariff.tariff_country" */
  update_tariff_tariff_country_many?: Maybe<Array<Maybe<Tariff_Tariff_Country_Mutation_Response>>>;
  /** update multiples rows of table: "tariff.tariff" */
  update_tariff_tariff_many?: Maybe<Array<Maybe<Tariff_Tariff_Mutation_Response>>>;
  /** update data of the table: "tariff.tariff_user" */
  update_tariff_tariff_user?: Maybe<Tariff_Tariff_User_Mutation_Response>;
  /** update single row of the table: "tariff.tariff_user" */
  update_tariff_tariff_user_by_pk?: Maybe<Tariff_Tariff_User>;
  /** update multiples rows of table: "tariff.tariff_user" */
  update_tariff_tariff_user_many?: Maybe<Array<Maybe<Tariff_Tariff_User_Mutation_Response>>>;
  /** update data of the table: "tariff.user_country" */
  update_tariff_user_country?: Maybe<Tariff_User_Country_Mutation_Response>;
  /** update single row of the table: "tariff.user_country" */
  update_tariff_user_country_by_pk?: Maybe<Tariff_User_Country>;
  /** update multiples rows of table: "tariff.user_country" */
  update_tariff_user_country_many?: Maybe<Array<Maybe<Tariff_User_Country_Mutation_Response>>>;
  /** update multiples rows of table: "tin.check_link" */
  update_tin_check_link_many?: Maybe<Array<Maybe<Tin_Check_Link_Mutation_Response>>>;
  /** update data of the table: "tin.country" */
  update_tin_country?: Maybe<Tin_Country_Mutation_Response>;
  /** update single row of the table: "tin.country" */
  update_tin_country_by_pk?: Maybe<Tin_Country>;
  /** update multiples rows of table: "tin.country" */
  update_tin_country_many?: Maybe<Array<Maybe<Tin_Country_Mutation_Response>>>;
  /** update multiples rows of table: "tin.document" */
  update_tin_document_many?: Maybe<Array<Maybe<Tin_Document_Mutation_Response>>>;
  /** update multiples rows of table: "tin.image" */
  update_tin_image_many?: Maybe<Array<Maybe<Tin_Image_Mutation_Response>>>;
  /** update multiples rows of table: "tin.tin" */
  update_tin_tin_many?: Maybe<Array<Maybe<Tin_Tin_Mutation_Response>>>;
  /** update data of the table: "tin.tin_type" */
  update_tin_tin_type?: Maybe<Tin_Tin_Type_Mutation_Response>;
  /** update single row of the table: "tin.tin_type" */
  update_tin_tin_type_by_pk?: Maybe<Tin_Tin_Type>;
  /** update multiples rows of table: "tin.tin_type" */
  update_tin_tin_type_many?: Maybe<Array<Maybe<Tin_Tin_Type_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootAddCheckLinkArgs = {
  object: Tin_Check_Link_Insert_Input;
  on_conflict?: InputMaybe<Tin_Check_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootAddCheckLinksArgs = {
  objects: Array<Tin_Check_Link_Insert_Input>;
  on_conflict?: InputMaybe<Tin_Check_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootAddDocumentArgs = {
  object: Tin_Document_Insert_Input;
  on_conflict?: InputMaybe<Tin_Document_On_Conflict>;
};


/** mutation root */
export type Mutation_RootAddDocumentsArgs = {
  objects: Array<Tin_Document_Insert_Input>;
  on_conflict?: InputMaybe<Tin_Document_On_Conflict>;
};


/** mutation root */
export type Mutation_RootAddImageArgs = {
  object: Tin_Image_Insert_Input;
  on_conflict?: InputMaybe<Tin_Image_On_Conflict>;
};


/** mutation root */
export type Mutation_RootAddImagesArgs = {
  objects: Array<Tin_Image_Insert_Input>;
  on_conflict?: InputMaybe<Tin_Image_On_Conflict>;
};


/** mutation root */
export type Mutation_RootAddTinArgs = {
  object: Tin_Tin_Insert_Input;
  on_conflict?: InputMaybe<Tin_Tin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootAddTinsArgs = {
  objects: Array<Tin_Tin_Insert_Input>;
  on_conflict?: InputMaybe<Tin_Tin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootDelete_Audit_Logged_ActionsArgs = {
  where: Audit_Logged_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Audit_Logged_Actions_By_PkArgs = {
  event_id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_LogoutArgs = {
  where: Auth_Logout_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Logout_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_RoleArgs = {
  where: Auth_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Role_By_PkArgs = {
  name: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_TokenArgs = {
  where: Auth_Token_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Token_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_UserArgs = {
  where: Auth_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_User_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tariff_TariffArgs = {
  where: Tariff_Tariff_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tariff_Tariff_By_PkArgs = {
  name: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tariff_Tariff_CountryArgs = {
  where: Tariff_Tariff_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tariff_Tariff_Country_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tariff_Tariff_UserArgs = {
  where: Tariff_Tariff_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tariff_Tariff_User_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tariff_User_CountryArgs = {
  where: Tariff_User_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tariff_User_Country_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tin_CountryArgs = {
  where: Tin_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tin_Country_By_PkArgs = {
  iso31661alpha3: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tin_Tin_TypeArgs = {
  where: Tin_Tin_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tin_Tin_Type_By_PkArgs = {
  name: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootInsert_Audit_Logged_ActionsArgs = {
  objects: Array<Audit_Logged_Actions_Insert_Input>;
  on_conflict?: InputMaybe<Audit_Logged_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audit_Logged_Actions_OneArgs = {
  object: Audit_Logged_Actions_Insert_Input;
  on_conflict?: InputMaybe<Audit_Logged_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_LogoutArgs = {
  objects: Array<Auth_Logout_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Logout_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Logout_OneArgs = {
  object: Auth_Logout_Insert_Input;
  on_conflict?: InputMaybe<Auth_Logout_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_RoleArgs = {
  objects: Array<Auth_Role_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Role_OneArgs = {
  object: Auth_Role_Insert_Input;
  on_conflict?: InputMaybe<Auth_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_TokenArgs = {
  objects: Array<Auth_Token_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Token_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Token_OneArgs = {
  object: Auth_Token_Insert_Input;
  on_conflict?: InputMaybe<Auth_Token_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_UserArgs = {
  objects: Array<Auth_User_Insert_Input>;
  on_conflict?: InputMaybe<Auth_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_User_OneArgs = {
  object: Auth_User_Insert_Input;
  on_conflict?: InputMaybe<Auth_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tariff_TariffArgs = {
  objects: Array<Tariff_Tariff_Insert_Input>;
  on_conflict?: InputMaybe<Tariff_Tariff_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tariff_Tariff_CountryArgs = {
  objects: Array<Tariff_Tariff_Country_Insert_Input>;
  on_conflict?: InputMaybe<Tariff_Tariff_Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tariff_Tariff_Country_OneArgs = {
  object: Tariff_Tariff_Country_Insert_Input;
  on_conflict?: InputMaybe<Tariff_Tariff_Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tariff_Tariff_OneArgs = {
  object: Tariff_Tariff_Insert_Input;
  on_conflict?: InputMaybe<Tariff_Tariff_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tariff_Tariff_UserArgs = {
  objects: Array<Tariff_Tariff_User_Insert_Input>;
  on_conflict?: InputMaybe<Tariff_Tariff_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tariff_Tariff_User_OneArgs = {
  object: Tariff_Tariff_User_Insert_Input;
  on_conflict?: InputMaybe<Tariff_Tariff_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tariff_User_CountryArgs = {
  objects: Array<Tariff_User_Country_Insert_Input>;
  on_conflict?: InputMaybe<Tariff_User_Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tariff_User_Country_OneArgs = {
  object: Tariff_User_Country_Insert_Input;
  on_conflict?: InputMaybe<Tariff_User_Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tin_CountryArgs = {
  objects: Array<Tin_Country_Insert_Input>;
  on_conflict?: InputMaybe<Tin_Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tin_Country_OneArgs = {
  object: Tin_Country_Insert_Input;
  on_conflict?: InputMaybe<Tin_Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tin_Tin_TypeArgs = {
  objects: Array<Tin_Tin_Type_Insert_Input>;
  on_conflict?: InputMaybe<Tin_Tin_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tin_Tin_Type_OneArgs = {
  object: Tin_Tin_Type_Insert_Input;
  on_conflict?: InputMaybe<Tin_Tin_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootLoginArgs = {
  login: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootRemoveCheckLinkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootRemoveCheckLinksArgs = {
  where: Tin_Check_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootRemoveDocumentArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootRemoveDocumentsArgs = {
  where: Tin_Document_Bool_Exp;
};


/** mutation root */
export type Mutation_RootRemoveImageArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootRemoveImagesArgs = {
  where: Tin_Image_Bool_Exp;
};


/** mutation root */
export type Mutation_RootRemoveTinArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootRemoveTinsArgs = {
  where: Tin_Tin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateChecklinkArgs = {
  _set?: InputMaybe<Tin_Check_Link_Set_Input>;
  pk_columns: Tin_Check_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateChecklinksArgs = {
  _set?: InputMaybe<Tin_Check_Link_Set_Input>;
  where: Tin_Check_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateDocumentArgs = {
  _set?: InputMaybe<Tin_Document_Set_Input>;
  pk_columns: Tin_Document_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateDocumentsArgs = {
  _set?: InputMaybe<Tin_Document_Set_Input>;
  where: Tin_Document_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateImageArgs = {
  _set?: InputMaybe<Tin_Image_Set_Input>;
  pk_columns: Tin_Image_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateImagesArgs = {
  _set?: InputMaybe<Tin_Image_Set_Input>;
  where: Tin_Image_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateTinArgs = {
  _append?: InputMaybe<Tin_Tin_Append_Input>;
  _delete_at_path?: InputMaybe<Tin_Tin_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tin_Tin_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tin_Tin_Delete_Key_Input>;
  _prepend?: InputMaybe<Tin_Tin_Prepend_Input>;
  _set?: InputMaybe<Tin_Tin_Set_Input>;
  pk_columns: Tin_Tin_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateTinsArgs = {
  _append?: InputMaybe<Tin_Tin_Append_Input>;
  _delete_at_path?: InputMaybe<Tin_Tin_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tin_Tin_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tin_Tin_Delete_Key_Input>;
  _prepend?: InputMaybe<Tin_Tin_Prepend_Input>;
  _set?: InputMaybe<Tin_Tin_Set_Input>;
  where: Tin_Tin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Audit_Logged_ActionsArgs = {
  _append?: InputMaybe<Audit_Logged_Actions_Append_Input>;
  _delete_at_path?: InputMaybe<Audit_Logged_Actions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Audit_Logged_Actions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Audit_Logged_Actions_Delete_Key_Input>;
  _inc?: InputMaybe<Audit_Logged_Actions_Inc_Input>;
  _prepend?: InputMaybe<Audit_Logged_Actions_Prepend_Input>;
  _set?: InputMaybe<Audit_Logged_Actions_Set_Input>;
  where: Audit_Logged_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Audit_Logged_Actions_By_PkArgs = {
  _append?: InputMaybe<Audit_Logged_Actions_Append_Input>;
  _delete_at_path?: InputMaybe<Audit_Logged_Actions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Audit_Logged_Actions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Audit_Logged_Actions_Delete_Key_Input>;
  _inc?: InputMaybe<Audit_Logged_Actions_Inc_Input>;
  _prepend?: InputMaybe<Audit_Logged_Actions_Prepend_Input>;
  _set?: InputMaybe<Audit_Logged_Actions_Set_Input>;
  pk_columns: Audit_Logged_Actions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Audit_Logged_Actions_ManyArgs = {
  updates: Array<Audit_Logged_Actions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_LogoutArgs = {
  _set?: InputMaybe<Auth_Logout_Set_Input>;
  where: Auth_Logout_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Logout_By_PkArgs = {
  _set?: InputMaybe<Auth_Logout_Set_Input>;
  pk_columns: Auth_Logout_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Logout_ManyArgs = {
  updates: Array<Auth_Logout_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_RoleArgs = {
  _set?: InputMaybe<Auth_Role_Set_Input>;
  where: Auth_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Role_By_PkArgs = {
  _set?: InputMaybe<Auth_Role_Set_Input>;
  pk_columns: Auth_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Role_ManyArgs = {
  updates: Array<Auth_Role_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_TokenArgs = {
  _set?: InputMaybe<Auth_Token_Set_Input>;
  where: Auth_Token_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Token_By_PkArgs = {
  _set?: InputMaybe<Auth_Token_Set_Input>;
  pk_columns: Auth_Token_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Token_ManyArgs = {
  updates: Array<Auth_Token_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_UserArgs = {
  _set?: InputMaybe<Auth_User_Set_Input>;
  where: Auth_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_User_By_PkArgs = {
  _set?: InputMaybe<Auth_User_Set_Input>;
  pk_columns: Auth_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_User_ManyArgs = {
  updates: Array<Auth_User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tariff_TariffArgs = {
  _set?: InputMaybe<Tariff_Tariff_Set_Input>;
  where: Tariff_Tariff_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tariff_Tariff_By_PkArgs = {
  _set?: InputMaybe<Tariff_Tariff_Set_Input>;
  pk_columns: Tariff_Tariff_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tariff_Tariff_CountryArgs = {
  _set?: InputMaybe<Tariff_Tariff_Country_Set_Input>;
  where: Tariff_Tariff_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tariff_Tariff_Country_By_PkArgs = {
  _set?: InputMaybe<Tariff_Tariff_Country_Set_Input>;
  pk_columns: Tariff_Tariff_Country_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tariff_Tariff_Country_ManyArgs = {
  updates: Array<Tariff_Tariff_Country_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tariff_Tariff_ManyArgs = {
  updates: Array<Tariff_Tariff_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tariff_Tariff_UserArgs = {
  _set?: InputMaybe<Tariff_Tariff_User_Set_Input>;
  where: Tariff_Tariff_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tariff_Tariff_User_By_PkArgs = {
  _set?: InputMaybe<Tariff_Tariff_User_Set_Input>;
  pk_columns: Tariff_Tariff_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tariff_Tariff_User_ManyArgs = {
  updates: Array<Tariff_Tariff_User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tariff_User_CountryArgs = {
  _set?: InputMaybe<Tariff_User_Country_Set_Input>;
  where: Tariff_User_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tariff_User_Country_By_PkArgs = {
  _set?: InputMaybe<Tariff_User_Country_Set_Input>;
  pk_columns: Tariff_User_Country_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tariff_User_Country_ManyArgs = {
  updates: Array<Tariff_User_Country_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tin_Check_Link_ManyArgs = {
  updates: Array<Tin_Check_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tin_CountryArgs = {
  _set?: InputMaybe<Tin_Country_Set_Input>;
  where: Tin_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tin_Country_By_PkArgs = {
  _set?: InputMaybe<Tin_Country_Set_Input>;
  pk_columns: Tin_Country_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tin_Country_ManyArgs = {
  updates: Array<Tin_Country_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tin_Document_ManyArgs = {
  updates: Array<Tin_Document_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tin_Image_ManyArgs = {
  updates: Array<Tin_Image_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tin_Tin_ManyArgs = {
  updates: Array<Tin_Tin_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tin_Tin_TypeArgs = {
  _set?: InputMaybe<Tin_Tin_Type_Set_Input>;
  where: Tin_Tin_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tin_Tin_Type_By_PkArgs = {
  _set?: InputMaybe<Tin_Tin_Type_Set_Input>;
  pk_columns: Tin_Tin_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tin_Tin_Type_ManyArgs = {
  updates: Array<Tin_Tin_Type_Updates>;
};

/** Boolean expression to compare columns of type "oid". All fields are combined with logical 'AND'. */
export type Oid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['oid']['input']>;
  _gt?: InputMaybe<Scalars['oid']['input']>;
  _gte?: InputMaybe<Scalars['oid']['input']>;
  _in?: InputMaybe<Array<Scalars['oid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['oid']['input']>;
  _lte?: InputMaybe<Scalars['oid']['input']>;
  _neq?: InputMaybe<Scalars['oid']['input']>;
  _nin?: InputMaybe<Array<Scalars['oid']['input']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch aggregated fields from the table: "tin.check_link" */
  aggregateCheckLinks: Tin_Check_Link_Aggregate;
  /** fetch aggregated fields from the table: "tin.country" */
  aggregateCountries: Tin_Country_Aggregate;
  /** fetch aggregated fields from the table: "tin.document" */
  aggregateDocuments: Tin_Document_Aggregate;
  /** fetch aggregated fields from the table: "tin.image" */
  aggregateImages: Tin_Image_Aggregate;
  /** fetch aggregated fields from the table: "tin.tin" */
  aggregateTins: Tin_Tin_Aggregate;
  /** fetch data from the table: "audit.log" */
  audit_log: Array<Audit_Log>;
  /** fetch aggregated fields from the table: "audit.log" */
  audit_log_aggregate: Audit_Log_Aggregate;
  /** fetch data from the table: "audit.logged_actions" */
  audit_logged_actions: Array<Audit_Logged_Actions>;
  /** fetch aggregated fields from the table: "audit.logged_actions" */
  audit_logged_actions_aggregate: Audit_Logged_Actions_Aggregate;
  /** fetch data from the table: "audit.logged_actions" using primary key columns */
  audit_logged_actions_by_pk?: Maybe<Audit_Logged_Actions>;
  /** fetch data from the table: "auth.logout" */
  auth_logout: Array<Auth_Logout>;
  /** fetch aggregated fields from the table: "auth.logout" */
  auth_logout_aggregate: Auth_Logout_Aggregate;
  /** fetch data from the table: "auth.logout" using primary key columns */
  auth_logout_by_pk?: Maybe<Auth_Logout>;
  /** fetch data from the table: "auth.role" */
  auth_role: Array<Auth_Role>;
  /** fetch aggregated fields from the table: "auth.role" */
  auth_role_aggregate: Auth_Role_Aggregate;
  /** fetch data from the table: "auth.role" using primary key columns */
  auth_role_by_pk?: Maybe<Auth_Role>;
  /** fetch data from the table: "auth.token" */
  auth_token: Array<Auth_Token>;
  /** fetch aggregated fields from the table: "auth.token" */
  auth_token_aggregate: Auth_Token_Aggregate;
  /** fetch data from the table: "auth.token" using primary key columns */
  auth_token_by_pk?: Maybe<Auth_Token>;
  /** fetch data from the table: "auth.user" */
  auth_user: Array<Auth_User>;
  /** fetch aggregated fields from the table: "auth.user" */
  auth_user_aggregate: Auth_User_Aggregate;
  /** fetch data from the table: "auth.user" using primary key columns */
  auth_user_by_pk?: Maybe<Auth_User>;
  check?: Maybe<Scalars['String']['output']>;
  /** fetch data from the table: "tin.check_link" using primary key columns */
  checkLink?: Maybe<Tin_Check_Link>;
  /** An array relationship */
  checkLinks: Array<Tin_Check_Link>;
  /** fetch data from the table: "tin.country" */
  countries: Array<Tin_Country>;
  /** fetch data from the table: "tin.country" using primary key columns */
  country?: Maybe<Tin_Country>;
  /** fetch data from the table: "tin.document" using primary key columns */
  document?: Maybe<Tin_Document>;
  /** An array relationship */
  documents: Array<Tin_Document>;
  /** fetch data from the table: "tin.image" using primary key columns */
  image?: Maybe<Tin_Image>;
  /** An array relationship */
  images: Array<Tin_Image>;
  /** execute function "auth.me" which returns "auth.user" */
  me?: Maybe<Auth_User>;
  /** execute function "auth.me" and query aggregates on result of table type "auth.user" */
  me_aggregate: Auth_User_Aggregate;
  /** fetch data from the table: "tariff.country_access" */
  tariff_country_access: Array<Tariff_Country_Access>;
  /** fetch aggregated fields from the table: "tariff.country_access" */
  tariff_country_access_aggregate: Tariff_Country_Access_Aggregate;
  /** fetch data from the table: "tariff.tariff" */
  tariff_tariff: Array<Tariff_Tariff>;
  /** fetch aggregated fields from the table: "tariff.tariff" */
  tariff_tariff_aggregate: Tariff_Tariff_Aggregate;
  /** fetch data from the table: "tariff.tariff" using primary key columns */
  tariff_tariff_by_pk?: Maybe<Tariff_Tariff>;
  /** fetch data from the table: "tariff.tariff_country" */
  tariff_tariff_country: Array<Tariff_Tariff_Country>;
  /** fetch aggregated fields from the table: "tariff.tariff_country" */
  tariff_tariff_country_aggregate: Tariff_Tariff_Country_Aggregate;
  /** fetch data from the table: "tariff.tariff_country" using primary key columns */
  tariff_tariff_country_by_pk?: Maybe<Tariff_Tariff_Country>;
  /** fetch data from the table: "tariff.tariff_user" */
  tariff_tariff_user: Array<Tariff_Tariff_User>;
  /** fetch aggregated fields from the table: "tariff.tariff_user" */
  tariff_tariff_user_aggregate: Tariff_Tariff_User_Aggregate;
  /** fetch data from the table: "tariff.tariff_user" using primary key columns */
  tariff_tariff_user_by_pk?: Maybe<Tariff_Tariff_User>;
  /** fetch data from the table: "tariff.user_country" */
  tariff_user_country: Array<Tariff_User_Country>;
  /** fetch aggregated fields from the table: "tariff.user_country" */
  tariff_user_country_aggregate: Tariff_User_Country_Aggregate;
  /** fetch data from the table: "tariff.user_country" using primary key columns */
  tariff_user_country_by_pk?: Maybe<Tariff_User_Country>;
  /** fetch data from the table: "tin.tin" using primary key columns */
  tin?: Maybe<Tin_Tin>;
  /** fetch data from the table: "tin.tin_type" */
  tin_tin_type: Array<Tin_Tin_Type>;
  /** fetch aggregated fields from the table: "tin.tin_type" */
  tin_tin_type_aggregate: Tin_Tin_Type_Aggregate;
  /** fetch data from the table: "tin.tin_type" using primary key columns */
  tin_tin_type_by_pk?: Maybe<Tin_Tin_Type>;
  /** An array relationship */
  tins: Array<Tin_Tin>;
};


export type Query_RootAggregateCheckLinksArgs = {
  distinct_on?: InputMaybe<Array<Tin_Check_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Check_Link_Order_By>>;
  where?: InputMaybe<Tin_Check_Link_Bool_Exp>;
};


export type Query_RootAggregateCountriesArgs = {
  distinct_on?: InputMaybe<Array<Tin_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Country_Order_By>>;
  where?: InputMaybe<Tin_Country_Bool_Exp>;
};


export type Query_RootAggregateDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Tin_Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Document_Order_By>>;
  where?: InputMaybe<Tin_Document_Bool_Exp>;
};


export type Query_RootAggregateImagesArgs = {
  distinct_on?: InputMaybe<Array<Tin_Image_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Image_Order_By>>;
  where?: InputMaybe<Tin_Image_Bool_Exp>;
};


export type Query_RootAggregateTinsArgs = {
  distinct_on?: InputMaybe<Array<Tin_Tin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Tin_Order_By>>;
  where?: InputMaybe<Tin_Tin_Bool_Exp>;
};


export type Query_RootAudit_LogArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Log_Order_By>>;
  where?: InputMaybe<Audit_Log_Bool_Exp>;
};


export type Query_RootAudit_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Log_Order_By>>;
  where?: InputMaybe<Audit_Log_Bool_Exp>;
};


export type Query_RootAudit_Logged_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Logged_Actions_Order_By>>;
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};


export type Query_RootAudit_Logged_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Logged_Actions_Order_By>>;
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};


export type Query_RootAudit_Logged_Actions_By_PkArgs = {
  event_id: Scalars['bigint']['input'];
};


export type Query_RootAuth_LogoutArgs = {
  distinct_on?: InputMaybe<Array<Auth_Logout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Logout_Order_By>>;
  where?: InputMaybe<Auth_Logout_Bool_Exp>;
};


export type Query_RootAuth_Logout_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Logout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Logout_Order_By>>;
  where?: InputMaybe<Auth_Logout_Bool_Exp>;
};


export type Query_RootAuth_Logout_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAuth_RoleArgs = {
  distinct_on?: InputMaybe<Array<Auth_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Role_Order_By>>;
  where?: InputMaybe<Auth_Role_Bool_Exp>;
};


export type Query_RootAuth_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Role_Order_By>>;
  where?: InputMaybe<Auth_Role_Bool_Exp>;
};


export type Query_RootAuth_Role_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Query_RootAuth_TokenArgs = {
  distinct_on?: InputMaybe<Array<Auth_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Token_Order_By>>;
  where?: InputMaybe<Auth_Token_Bool_Exp>;
};


export type Query_RootAuth_Token_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Token_Order_By>>;
  where?: InputMaybe<Auth_Token_Bool_Exp>;
};


export type Query_RootAuth_Token_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAuth_UserArgs = {
  distinct_on?: InputMaybe<Array<Auth_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_User_Order_By>>;
  where?: InputMaybe<Auth_User_Bool_Exp>;
};


export type Query_RootAuth_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_User_Order_By>>;
  where?: InputMaybe<Auth_User_Bool_Exp>;
};


export type Query_RootAuth_User_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCheckLinkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCheckLinksArgs = {
  distinct_on?: InputMaybe<Array<Tin_Check_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Check_Link_Order_By>>;
  where?: InputMaybe<Tin_Check_Link_Bool_Exp>;
};


export type Query_RootCountriesArgs = {
  distinct_on?: InputMaybe<Array<Tin_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Country_Order_By>>;
  where?: InputMaybe<Tin_Country_Bool_Exp>;
};


export type Query_RootCountryArgs = {
  iso31661alpha3: Scalars['String']['input'];
};


export type Query_RootDocumentArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Tin_Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Document_Order_By>>;
  where?: InputMaybe<Tin_Document_Bool_Exp>;
};


export type Query_RootImageArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootImagesArgs = {
  distinct_on?: InputMaybe<Array<Tin_Image_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Image_Order_By>>;
  where?: InputMaybe<Tin_Image_Bool_Exp>;
};


export type Query_RootMeArgs = {
  distinct_on?: InputMaybe<Array<Auth_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_User_Order_By>>;
  where?: InputMaybe<Auth_User_Bool_Exp>;
};


export type Query_RootMe_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_User_Order_By>>;
  where?: InputMaybe<Auth_User_Bool_Exp>;
};


export type Query_RootTariff_Country_AccessArgs = {
  distinct_on?: InputMaybe<Array<Tariff_Country_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tariff_Country_Access_Order_By>>;
  where?: InputMaybe<Tariff_Country_Access_Bool_Exp>;
};


export type Query_RootTariff_Country_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tariff_Country_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tariff_Country_Access_Order_By>>;
  where?: InputMaybe<Tariff_Country_Access_Bool_Exp>;
};


export type Query_RootTariff_TariffArgs = {
  distinct_on?: InputMaybe<Array<Tariff_Tariff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tariff_Tariff_Order_By>>;
  where?: InputMaybe<Tariff_Tariff_Bool_Exp>;
};


export type Query_RootTariff_Tariff_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tariff_Tariff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tariff_Tariff_Order_By>>;
  where?: InputMaybe<Tariff_Tariff_Bool_Exp>;
};


export type Query_RootTariff_Tariff_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Query_RootTariff_Tariff_CountryArgs = {
  distinct_on?: InputMaybe<Array<Tariff_Tariff_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tariff_Tariff_Country_Order_By>>;
  where?: InputMaybe<Tariff_Tariff_Country_Bool_Exp>;
};


export type Query_RootTariff_Tariff_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tariff_Tariff_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tariff_Tariff_Country_Order_By>>;
  where?: InputMaybe<Tariff_Tariff_Country_Bool_Exp>;
};


export type Query_RootTariff_Tariff_Country_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootTariff_Tariff_UserArgs = {
  distinct_on?: InputMaybe<Array<Tariff_Tariff_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tariff_Tariff_User_Order_By>>;
  where?: InputMaybe<Tariff_Tariff_User_Bool_Exp>;
};


export type Query_RootTariff_Tariff_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tariff_Tariff_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tariff_Tariff_User_Order_By>>;
  where?: InputMaybe<Tariff_Tariff_User_Bool_Exp>;
};


export type Query_RootTariff_Tariff_User_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootTariff_User_CountryArgs = {
  distinct_on?: InputMaybe<Array<Tariff_User_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tariff_User_Country_Order_By>>;
  where?: InputMaybe<Tariff_User_Country_Bool_Exp>;
};


export type Query_RootTariff_User_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tariff_User_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tariff_User_Country_Order_By>>;
  where?: InputMaybe<Tariff_User_Country_Bool_Exp>;
};


export type Query_RootTariff_User_Country_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootTinArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootTin_Tin_TypeArgs = {
  distinct_on?: InputMaybe<Array<Tin_Tin_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Tin_Type_Order_By>>;
  where?: InputMaybe<Tin_Tin_Type_Bool_Exp>;
};


export type Query_RootTin_Tin_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tin_Tin_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Tin_Type_Order_By>>;
  where?: InputMaybe<Tin_Tin_Type_Bool_Exp>;
};


export type Query_RootTin_Tin_Type_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Query_RootTinsArgs = {
  distinct_on?: InputMaybe<Array<Tin_Tin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Tin_Order_By>>;
  where?: InputMaybe<Tin_Tin_Bool_Exp>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch aggregated fields from the table: "tin.check_link" */
  aggregateCheckLinks: Tin_Check_Link_Aggregate;
  /** fetch aggregated fields from the table: "tin.country" */
  aggregateCountries: Tin_Country_Aggregate;
  /** fetch aggregated fields from the table: "tin.document" */
  aggregateDocuments: Tin_Document_Aggregate;
  /** fetch aggregated fields from the table: "tin.image" */
  aggregateImages: Tin_Image_Aggregate;
  /** fetch aggregated fields from the table: "tin.tin" */
  aggregateTins: Tin_Tin_Aggregate;
  /** fetch data from the table: "audit.log" */
  audit_log: Array<Audit_Log>;
  /** fetch aggregated fields from the table: "audit.log" */
  audit_log_aggregate: Audit_Log_Aggregate;
  /** fetch data from the table: "audit.logged_actions" */
  audit_logged_actions: Array<Audit_Logged_Actions>;
  /** fetch aggregated fields from the table: "audit.logged_actions" */
  audit_logged_actions_aggregate: Audit_Logged_Actions_Aggregate;
  /** fetch data from the table: "audit.logged_actions" using primary key columns */
  audit_logged_actions_by_pk?: Maybe<Audit_Logged_Actions>;
  /** fetch data from the table: "auth.logout" */
  auth_logout: Array<Auth_Logout>;
  /** fetch aggregated fields from the table: "auth.logout" */
  auth_logout_aggregate: Auth_Logout_Aggregate;
  /** fetch data from the table: "auth.logout" using primary key columns */
  auth_logout_by_pk?: Maybe<Auth_Logout>;
  /** fetch data from the table: "auth.role" */
  auth_role: Array<Auth_Role>;
  /** fetch aggregated fields from the table: "auth.role" */
  auth_role_aggregate: Auth_Role_Aggregate;
  /** fetch data from the table: "auth.role" using primary key columns */
  auth_role_by_pk?: Maybe<Auth_Role>;
  /** fetch data from the table: "auth.token" */
  auth_token: Array<Auth_Token>;
  /** fetch aggregated fields from the table: "auth.token" */
  auth_token_aggregate: Auth_Token_Aggregate;
  /** fetch data from the table: "auth.token" using primary key columns */
  auth_token_by_pk?: Maybe<Auth_Token>;
  /** fetch data from the table: "auth.user" */
  auth_user: Array<Auth_User>;
  /** fetch aggregated fields from the table: "auth.user" */
  auth_user_aggregate: Auth_User_Aggregate;
  /** fetch data from the table: "auth.user" using primary key columns */
  auth_user_by_pk?: Maybe<Auth_User>;
  /** fetch data from the table: "tin.check_link" using primary key columns */
  checkLink?: Maybe<Tin_Check_Link>;
  /** An array relationship */
  checkLinks: Array<Tin_Check_Link>;
  /** fetch data from the table: "tin.country" */
  countries: Array<Tin_Country>;
  /** fetch data from the table: "tin.country" using primary key columns */
  country?: Maybe<Tin_Country>;
  /** fetch data from the table: "tin.document" using primary key columns */
  document?: Maybe<Tin_Document>;
  /** An array relationship */
  documents: Array<Tin_Document>;
  /** fetch data from the table: "tin.image" using primary key columns */
  image?: Maybe<Tin_Image>;
  /** An array relationship */
  images: Array<Tin_Image>;
  /** execute function "auth.me" which returns "auth.user" */
  me?: Maybe<Auth_User>;
  /** execute function "auth.me" and query aggregates on result of table type "auth.user" */
  me_aggregate: Auth_User_Aggregate;
  /** fetch data from the table: "tariff.country_access" */
  tariff_country_access: Array<Tariff_Country_Access>;
  /** fetch aggregated fields from the table: "tariff.country_access" */
  tariff_country_access_aggregate: Tariff_Country_Access_Aggregate;
  /** fetch data from the table: "tariff.tariff" */
  tariff_tariff: Array<Tariff_Tariff>;
  /** fetch aggregated fields from the table: "tariff.tariff" */
  tariff_tariff_aggregate: Tariff_Tariff_Aggregate;
  /** fetch data from the table: "tariff.tariff" using primary key columns */
  tariff_tariff_by_pk?: Maybe<Tariff_Tariff>;
  /** fetch data from the table: "tariff.tariff_country" */
  tariff_tariff_country: Array<Tariff_Tariff_Country>;
  /** fetch aggregated fields from the table: "tariff.tariff_country" */
  tariff_tariff_country_aggregate: Tariff_Tariff_Country_Aggregate;
  /** fetch data from the table: "tariff.tariff_country" using primary key columns */
  tariff_tariff_country_by_pk?: Maybe<Tariff_Tariff_Country>;
  /** fetch data from the table: "tariff.tariff_user" */
  tariff_tariff_user: Array<Tariff_Tariff_User>;
  /** fetch aggregated fields from the table: "tariff.tariff_user" */
  tariff_tariff_user_aggregate: Tariff_Tariff_User_Aggregate;
  /** fetch data from the table: "tariff.tariff_user" using primary key columns */
  tariff_tariff_user_by_pk?: Maybe<Tariff_Tariff_User>;
  /** fetch data from the table: "tariff.user_country" */
  tariff_user_country: Array<Tariff_User_Country>;
  /** fetch aggregated fields from the table: "tariff.user_country" */
  tariff_user_country_aggregate: Tariff_User_Country_Aggregate;
  /** fetch data from the table: "tariff.user_country" using primary key columns */
  tariff_user_country_by_pk?: Maybe<Tariff_User_Country>;
  /** fetch data from the table: "tin.tin" using primary key columns */
  tin?: Maybe<Tin_Tin>;
  /** fetch data from the table: "tin.tin_type" */
  tin_tin_type: Array<Tin_Tin_Type>;
  /** fetch aggregated fields from the table: "tin.tin_type" */
  tin_tin_type_aggregate: Tin_Tin_Type_Aggregate;
  /** fetch data from the table: "tin.tin_type" using primary key columns */
  tin_tin_type_by_pk?: Maybe<Tin_Tin_Type>;
  /** An array relationship */
  tins: Array<Tin_Tin>;
};


export type Subscription_RootAggregateCheckLinksArgs = {
  distinct_on?: InputMaybe<Array<Tin_Check_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Check_Link_Order_By>>;
  where?: InputMaybe<Tin_Check_Link_Bool_Exp>;
};


export type Subscription_RootAggregateCountriesArgs = {
  distinct_on?: InputMaybe<Array<Tin_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Country_Order_By>>;
  where?: InputMaybe<Tin_Country_Bool_Exp>;
};


export type Subscription_RootAggregateDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Tin_Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Document_Order_By>>;
  where?: InputMaybe<Tin_Document_Bool_Exp>;
};


export type Subscription_RootAggregateImagesArgs = {
  distinct_on?: InputMaybe<Array<Tin_Image_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Image_Order_By>>;
  where?: InputMaybe<Tin_Image_Bool_Exp>;
};


export type Subscription_RootAggregateTinsArgs = {
  distinct_on?: InputMaybe<Array<Tin_Tin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Tin_Order_By>>;
  where?: InputMaybe<Tin_Tin_Bool_Exp>;
};


export type Subscription_RootAudit_LogArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Log_Order_By>>;
  where?: InputMaybe<Audit_Log_Bool_Exp>;
};


export type Subscription_RootAudit_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Log_Order_By>>;
  where?: InputMaybe<Audit_Log_Bool_Exp>;
};


export type Subscription_RootAudit_Logged_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Logged_Actions_Order_By>>;
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootAudit_Logged_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Logged_Actions_Order_By>>;
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootAudit_Logged_Actions_By_PkArgs = {
  event_id: Scalars['bigint']['input'];
};


export type Subscription_RootAuth_LogoutArgs = {
  distinct_on?: InputMaybe<Array<Auth_Logout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Logout_Order_By>>;
  where?: InputMaybe<Auth_Logout_Bool_Exp>;
};


export type Subscription_RootAuth_Logout_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Logout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Logout_Order_By>>;
  where?: InputMaybe<Auth_Logout_Bool_Exp>;
};


export type Subscription_RootAuth_Logout_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAuth_RoleArgs = {
  distinct_on?: InputMaybe<Array<Auth_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Role_Order_By>>;
  where?: InputMaybe<Auth_Role_Bool_Exp>;
};


export type Subscription_RootAuth_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Role_Order_By>>;
  where?: InputMaybe<Auth_Role_Bool_Exp>;
};


export type Subscription_RootAuth_Role_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Subscription_RootAuth_TokenArgs = {
  distinct_on?: InputMaybe<Array<Auth_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Token_Order_By>>;
  where?: InputMaybe<Auth_Token_Bool_Exp>;
};


export type Subscription_RootAuth_Token_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Token_Order_By>>;
  where?: InputMaybe<Auth_Token_Bool_Exp>;
};


export type Subscription_RootAuth_Token_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAuth_UserArgs = {
  distinct_on?: InputMaybe<Array<Auth_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_User_Order_By>>;
  where?: InputMaybe<Auth_User_Bool_Exp>;
};


export type Subscription_RootAuth_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_User_Order_By>>;
  where?: InputMaybe<Auth_User_Bool_Exp>;
};


export type Subscription_RootAuth_User_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCheckLinkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCheckLinksArgs = {
  distinct_on?: InputMaybe<Array<Tin_Check_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Check_Link_Order_By>>;
  where?: InputMaybe<Tin_Check_Link_Bool_Exp>;
};


export type Subscription_RootCountriesArgs = {
  distinct_on?: InputMaybe<Array<Tin_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Country_Order_By>>;
  where?: InputMaybe<Tin_Country_Bool_Exp>;
};


export type Subscription_RootCountryArgs = {
  iso31661alpha3: Scalars['String']['input'];
};


export type Subscription_RootDocumentArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Tin_Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Document_Order_By>>;
  where?: InputMaybe<Tin_Document_Bool_Exp>;
};


export type Subscription_RootImageArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootImagesArgs = {
  distinct_on?: InputMaybe<Array<Tin_Image_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Image_Order_By>>;
  where?: InputMaybe<Tin_Image_Bool_Exp>;
};


export type Subscription_RootMeArgs = {
  distinct_on?: InputMaybe<Array<Auth_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_User_Order_By>>;
  where?: InputMaybe<Auth_User_Bool_Exp>;
};


export type Subscription_RootMe_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_User_Order_By>>;
  where?: InputMaybe<Auth_User_Bool_Exp>;
};


export type Subscription_RootTariff_Country_AccessArgs = {
  distinct_on?: InputMaybe<Array<Tariff_Country_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tariff_Country_Access_Order_By>>;
  where?: InputMaybe<Tariff_Country_Access_Bool_Exp>;
};


export type Subscription_RootTariff_Country_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tariff_Country_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tariff_Country_Access_Order_By>>;
  where?: InputMaybe<Tariff_Country_Access_Bool_Exp>;
};


export type Subscription_RootTariff_TariffArgs = {
  distinct_on?: InputMaybe<Array<Tariff_Tariff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tariff_Tariff_Order_By>>;
  where?: InputMaybe<Tariff_Tariff_Bool_Exp>;
};


export type Subscription_RootTariff_Tariff_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tariff_Tariff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tariff_Tariff_Order_By>>;
  where?: InputMaybe<Tariff_Tariff_Bool_Exp>;
};


export type Subscription_RootTariff_Tariff_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Subscription_RootTariff_Tariff_CountryArgs = {
  distinct_on?: InputMaybe<Array<Tariff_Tariff_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tariff_Tariff_Country_Order_By>>;
  where?: InputMaybe<Tariff_Tariff_Country_Bool_Exp>;
};


export type Subscription_RootTariff_Tariff_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tariff_Tariff_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tariff_Tariff_Country_Order_By>>;
  where?: InputMaybe<Tariff_Tariff_Country_Bool_Exp>;
};


export type Subscription_RootTariff_Tariff_Country_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootTariff_Tariff_UserArgs = {
  distinct_on?: InputMaybe<Array<Tariff_Tariff_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tariff_Tariff_User_Order_By>>;
  where?: InputMaybe<Tariff_Tariff_User_Bool_Exp>;
};


export type Subscription_RootTariff_Tariff_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tariff_Tariff_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tariff_Tariff_User_Order_By>>;
  where?: InputMaybe<Tariff_Tariff_User_Bool_Exp>;
};


export type Subscription_RootTariff_Tariff_User_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootTariff_User_CountryArgs = {
  distinct_on?: InputMaybe<Array<Tariff_User_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tariff_User_Country_Order_By>>;
  where?: InputMaybe<Tariff_User_Country_Bool_Exp>;
};


export type Subscription_RootTariff_User_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tariff_User_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tariff_User_Country_Order_By>>;
  where?: InputMaybe<Tariff_User_Country_Bool_Exp>;
};


export type Subscription_RootTariff_User_Country_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootTinArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootTin_Tin_TypeArgs = {
  distinct_on?: InputMaybe<Array<Tin_Tin_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Tin_Type_Order_By>>;
  where?: InputMaybe<Tin_Tin_Type_Bool_Exp>;
};


export type Subscription_RootTin_Tin_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tin_Tin_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Tin_Type_Order_By>>;
  where?: InputMaybe<Tin_Tin_Type_Bool_Exp>;
};


export type Subscription_RootTin_Tin_Type_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Subscription_RootTinsArgs = {
  distinct_on?: InputMaybe<Array<Tin_Tin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Tin_Order_By>>;
  where?: InputMaybe<Tin_Tin_Bool_Exp>;
};

/** columns and relationships of "tariff.country_access" */
export type Tariff_Country_Access = {
  __typename?: 'tariff_country_access';
  id?: Maybe<Scalars['uuid']['output']>;
  iso3166_1_alpha_3?: Maybe<Scalars['String']['output']>;
  tariff_name?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "tariff.country_access" */
export type Tariff_Country_Access_Aggregate = {
  __typename?: 'tariff_country_access_aggregate';
  aggregate?: Maybe<Tariff_Country_Access_Aggregate_Fields>;
  nodes: Array<Tariff_Country_Access>;
};

/** aggregate fields of "tariff.country_access" */
export type Tariff_Country_Access_Aggregate_Fields = {
  __typename?: 'tariff_country_access_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tariff_Country_Access_Max_Fields>;
  min?: Maybe<Tariff_Country_Access_Min_Fields>;
};


/** aggregate fields of "tariff.country_access" */
export type Tariff_Country_Access_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tariff_Country_Access_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "tariff.country_access". All fields are combined with a logical 'AND'. */
export type Tariff_Country_Access_Bool_Exp = {
  _and?: InputMaybe<Array<Tariff_Country_Access_Bool_Exp>>;
  _not?: InputMaybe<Tariff_Country_Access_Bool_Exp>;
  _or?: InputMaybe<Array<Tariff_Country_Access_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  iso3166_1_alpha_3?: InputMaybe<String_Comparison_Exp>;
  tariff_name?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Tariff_Country_Access_Max_Fields = {
  __typename?: 'tariff_country_access_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  iso3166_1_alpha_3?: Maybe<Scalars['String']['output']>;
  tariff_name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Tariff_Country_Access_Min_Fields = {
  __typename?: 'tariff_country_access_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  iso3166_1_alpha_3?: Maybe<Scalars['String']['output']>;
  tariff_name?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "tariff.country_access". */
export type Tariff_Country_Access_Order_By = {
  id?: InputMaybe<Order_By>;
  iso3166_1_alpha_3?: InputMaybe<Order_By>;
  tariff_name?: InputMaybe<Order_By>;
};

/** select columns of table "tariff.country_access" */
export enum Tariff_Country_Access_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Iso3166_1Alpha_3 = 'iso3166_1_alpha_3',
  /** column name */
  TariffName = 'tariff_name'
}

/** columns and relationships of "tariff.tariff" */
export type Tariff_Tariff = {
  __typename?: 'tariff_tariff';
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** aggregated selection of "tariff.tariff" */
export type Tariff_Tariff_Aggregate = {
  __typename?: 'tariff_tariff_aggregate';
  aggregate?: Maybe<Tariff_Tariff_Aggregate_Fields>;
  nodes: Array<Tariff_Tariff>;
};

/** aggregate fields of "tariff.tariff" */
export type Tariff_Tariff_Aggregate_Fields = {
  __typename?: 'tariff_tariff_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tariff_Tariff_Max_Fields>;
  min?: Maybe<Tariff_Tariff_Min_Fields>;
};


/** aggregate fields of "tariff.tariff" */
export type Tariff_Tariff_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tariff_Tariff_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "tariff.tariff". All fields are combined with a logical 'AND'. */
export type Tariff_Tariff_Bool_Exp = {
  _and?: InputMaybe<Array<Tariff_Tariff_Bool_Exp>>;
  _not?: InputMaybe<Tariff_Tariff_Bool_Exp>;
  _or?: InputMaybe<Array<Tariff_Tariff_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tariff.tariff" */
export enum Tariff_Tariff_Constraint {
  /** unique or primary key constraint on columns "name" */
  TariffPkey = 'tariff_pkey'
}

/** columns and relationships of "tariff.tariff_country" */
export type Tariff_Tariff_Country = {
  __typename?: 'tariff_tariff_country';
  country_code: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  tariff_name: Scalars['String']['output'];
};

/** aggregated selection of "tariff.tariff_country" */
export type Tariff_Tariff_Country_Aggregate = {
  __typename?: 'tariff_tariff_country_aggregate';
  aggregate?: Maybe<Tariff_Tariff_Country_Aggregate_Fields>;
  nodes: Array<Tariff_Tariff_Country>;
};

/** aggregate fields of "tariff.tariff_country" */
export type Tariff_Tariff_Country_Aggregate_Fields = {
  __typename?: 'tariff_tariff_country_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tariff_Tariff_Country_Max_Fields>;
  min?: Maybe<Tariff_Tariff_Country_Min_Fields>;
};


/** aggregate fields of "tariff.tariff_country" */
export type Tariff_Tariff_Country_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tariff_Tariff_Country_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "tariff.tariff_country". All fields are combined with a logical 'AND'. */
export type Tariff_Tariff_Country_Bool_Exp = {
  _and?: InputMaybe<Array<Tariff_Tariff_Country_Bool_Exp>>;
  _not?: InputMaybe<Tariff_Tariff_Country_Bool_Exp>;
  _or?: InputMaybe<Array<Tariff_Tariff_Country_Bool_Exp>>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  tariff_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tariff.tariff_country" */
export enum Tariff_Tariff_Country_Constraint {
  /** unique or primary key constraint on columns "id" */
  TariffCountryPkey = 'tariff_country_pkey',
  /** unique or primary key constraint on columns "country_code", "tariff_name" */
  TariffCountryTariffNameCountryCodeKey = 'tariff_country_tariff_name_country_code_key'
}

/** input type for inserting data into table "tariff.tariff_country" */
export type Tariff_Tariff_Country_Insert_Input = {
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  tariff_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tariff_Tariff_Country_Max_Fields = {
  __typename?: 'tariff_tariff_country_max_fields';
  country_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  tariff_name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Tariff_Tariff_Country_Min_Fields = {
  __typename?: 'tariff_tariff_country_min_fields';
  country_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  tariff_name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "tariff.tariff_country" */
export type Tariff_Tariff_Country_Mutation_Response = {
  __typename?: 'tariff_tariff_country_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tariff_Tariff_Country>;
};

/** on_conflict condition type for table "tariff.tariff_country" */
export type Tariff_Tariff_Country_On_Conflict = {
  constraint: Tariff_Tariff_Country_Constraint;
  update_columns?: Array<Tariff_Tariff_Country_Update_Column>;
  where?: InputMaybe<Tariff_Tariff_Country_Bool_Exp>;
};

/** Ordering options when selecting data from "tariff.tariff_country". */
export type Tariff_Tariff_Country_Order_By = {
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tariff_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tariff_tariff_country */
export type Tariff_Tariff_Country_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "tariff.tariff_country" */
export enum Tariff_Tariff_Country_Select_Column {
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TariffName = 'tariff_name'
}

/** input type for updating data in table "tariff.tariff_country" */
export type Tariff_Tariff_Country_Set_Input = {
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  tariff_name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tariff.tariff_country" */
export enum Tariff_Tariff_Country_Update_Column {
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TariffName = 'tariff_name'
}

export type Tariff_Tariff_Country_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tariff_Tariff_Country_Set_Input>;
  where: Tariff_Tariff_Country_Bool_Exp;
};

/** input type for inserting data into table "tariff.tariff" */
export type Tariff_Tariff_Insert_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tariff_Tariff_Max_Fields = {
  __typename?: 'tariff_tariff_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Tariff_Tariff_Min_Fields = {
  __typename?: 'tariff_tariff_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "tariff.tariff" */
export type Tariff_Tariff_Mutation_Response = {
  __typename?: 'tariff_tariff_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tariff_Tariff>;
};

/** on_conflict condition type for table "tariff.tariff" */
export type Tariff_Tariff_On_Conflict = {
  constraint: Tariff_Tariff_Constraint;
  update_columns?: Array<Tariff_Tariff_Update_Column>;
  where?: InputMaybe<Tariff_Tariff_Bool_Exp>;
};

/** Ordering options when selecting data from "tariff.tariff". */
export type Tariff_Tariff_Order_By = {
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tariff_tariff */
export type Tariff_Tariff_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "tariff.tariff" */
export enum Tariff_Tariff_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "tariff.tariff" */
export type Tariff_Tariff_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tariff.tariff" */
export enum Tariff_Tariff_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

export type Tariff_Tariff_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tariff_Tariff_Set_Input>;
  where: Tariff_Tariff_Bool_Exp;
};

/** columns and relationships of "tariff.tariff_user" */
export type Tariff_Tariff_User = {
  __typename?: 'tariff_tariff_user';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  tariff_name: Scalars['String']['output'];
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "tariff.tariff_user" */
export type Tariff_Tariff_User_Aggregate = {
  __typename?: 'tariff_tariff_user_aggregate';
  aggregate?: Maybe<Tariff_Tariff_User_Aggregate_Fields>;
  nodes: Array<Tariff_Tariff_User>;
};

/** aggregate fields of "tariff.tariff_user" */
export type Tariff_Tariff_User_Aggregate_Fields = {
  __typename?: 'tariff_tariff_user_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tariff_Tariff_User_Max_Fields>;
  min?: Maybe<Tariff_Tariff_User_Min_Fields>;
};


/** aggregate fields of "tariff.tariff_user" */
export type Tariff_Tariff_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tariff_Tariff_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "tariff.tariff_user". All fields are combined with a logical 'AND'. */
export type Tariff_Tariff_User_Bool_Exp = {
  _and?: InputMaybe<Array<Tariff_Tariff_User_Bool_Exp>>;
  _not?: InputMaybe<Tariff_Tariff_User_Bool_Exp>;
  _or?: InputMaybe<Array<Tariff_Tariff_User_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  tariff_name?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "tariff.tariff_user" */
export enum Tariff_Tariff_User_Constraint {
  /** unique or primary key constraint on columns "id" */
  TariffUserPkey = 'tariff_user_pkey'
}

/** input type for inserting data into table "tariff.tariff_user" */
export type Tariff_Tariff_User_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  tariff_name?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Tariff_Tariff_User_Max_Fields = {
  __typename?: 'tariff_tariff_user_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  tariff_name?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Tariff_Tariff_User_Min_Fields = {
  __typename?: 'tariff_tariff_user_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  tariff_name?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "tariff.tariff_user" */
export type Tariff_Tariff_User_Mutation_Response = {
  __typename?: 'tariff_tariff_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tariff_Tariff_User>;
};

/** on_conflict condition type for table "tariff.tariff_user" */
export type Tariff_Tariff_User_On_Conflict = {
  constraint: Tariff_Tariff_User_Constraint;
  update_columns?: Array<Tariff_Tariff_User_Update_Column>;
  where?: InputMaybe<Tariff_Tariff_User_Bool_Exp>;
};

/** Ordering options when selecting data from "tariff.tariff_user". */
export type Tariff_Tariff_User_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tariff_name?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tariff_tariff_user */
export type Tariff_Tariff_User_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "tariff.tariff_user" */
export enum Tariff_Tariff_User_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TariffName = 'tariff_name',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "tariff.tariff_user" */
export type Tariff_Tariff_User_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  tariff_name?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "tariff.tariff_user" */
export enum Tariff_Tariff_User_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TariffName = 'tariff_name',
  /** column name */
  UserId = 'user_id'
}

export type Tariff_Tariff_User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tariff_Tariff_User_Set_Input>;
  where: Tariff_Tariff_User_Bool_Exp;
};

/** columns and relationships of "tariff.user_country" */
export type Tariff_User_Country = {
  __typename?: 'tariff_user_country';
  country_code: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "tariff.user_country" */
export type Tariff_User_Country_Aggregate = {
  __typename?: 'tariff_user_country_aggregate';
  aggregate?: Maybe<Tariff_User_Country_Aggregate_Fields>;
  nodes: Array<Tariff_User_Country>;
};

/** aggregate fields of "tariff.user_country" */
export type Tariff_User_Country_Aggregate_Fields = {
  __typename?: 'tariff_user_country_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tariff_User_Country_Max_Fields>;
  min?: Maybe<Tariff_User_Country_Min_Fields>;
};


/** aggregate fields of "tariff.user_country" */
export type Tariff_User_Country_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tariff_User_Country_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "tariff.user_country". All fields are combined with a logical 'AND'. */
export type Tariff_User_Country_Bool_Exp = {
  _and?: InputMaybe<Array<Tariff_User_Country_Bool_Exp>>;
  _not?: InputMaybe<Tariff_User_Country_Bool_Exp>;
  _or?: InputMaybe<Array<Tariff_User_Country_Bool_Exp>>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "tariff.user_country" */
export enum Tariff_User_Country_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserCountryPkey = 'user_country_pkey'
}

/** input type for inserting data into table "tariff.user_country" */
export type Tariff_User_Country_Insert_Input = {
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Tariff_User_Country_Max_Fields = {
  __typename?: 'tariff_user_country_max_fields';
  country_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Tariff_User_Country_Min_Fields = {
  __typename?: 'tariff_user_country_min_fields';
  country_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "tariff.user_country" */
export type Tariff_User_Country_Mutation_Response = {
  __typename?: 'tariff_user_country_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tariff_User_Country>;
};

/** on_conflict condition type for table "tariff.user_country" */
export type Tariff_User_Country_On_Conflict = {
  constraint: Tariff_User_Country_Constraint;
  update_columns?: Array<Tariff_User_Country_Update_Column>;
  where?: InputMaybe<Tariff_User_Country_Bool_Exp>;
};

/** Ordering options when selecting data from "tariff.user_country". */
export type Tariff_User_Country_Order_By = {
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tariff_user_country */
export type Tariff_User_Country_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "tariff.user_country" */
export enum Tariff_User_Country_Select_Column {
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "tariff.user_country" */
export type Tariff_User_Country_Set_Input = {
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "tariff.user_country" */
export enum Tariff_User_Country_Update_Column {
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export type Tariff_User_Country_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tariff_User_Country_Set_Input>;
  where: Tariff_User_Country_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "tin.check_link" */
export type Tin_Check_Link = {
  __typename?: 'tin_check_link';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  tin: Tin_Tin;
  tinId: Scalars['uuid']['output'];
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "tin.check_link" */
export type Tin_Check_Link_Aggregate = {
  __typename?: 'tin_check_link_aggregate';
  aggregate?: Maybe<Tin_Check_Link_Aggregate_Fields>;
  nodes: Array<Tin_Check_Link>;
};

/** aggregate fields of "tin.check_link" */
export type Tin_Check_Link_Aggregate_Fields = {
  __typename?: 'tin_check_link_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tin_Check_Link_Max_Fields>;
  min?: Maybe<Tin_Check_Link_Min_Fields>;
};


/** aggregate fields of "tin.check_link" */
export type Tin_Check_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tin_Check_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tin.check_link" */
export type Tin_Check_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tin_Check_Link_Max_Order_By>;
  min?: InputMaybe<Tin_Check_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tin.check_link" */
export type Tin_Check_Link_Arr_Rel_Insert_Input = {
  data: Array<Tin_Check_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tin_Check_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tin.check_link". All fields are combined with a logical 'AND'. */
export type Tin_Check_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Tin_Check_Link_Bool_Exp>>;
  _not?: InputMaybe<Tin_Check_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Tin_Check_Link_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  tin?: InputMaybe<Tin_Tin_Bool_Exp>;
  tinId?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tin.check_link" */
export enum Tin_Check_Link_Constraint {
  /** unique or primary key constraint on columns "id" */
  CheckLinkPkey = 'check_link_pkey'
}

/** input type for inserting data into table "tin.check_link" */
export type Tin_Check_Link_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  tin?: InputMaybe<Tin_Tin_Obj_Rel_Insert_Input>;
  tinId?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tin_Check_Link_Max_Fields = {
  __typename?: 'tin_check_link_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  tinId?: Maybe<Scalars['uuid']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tin.check_link" */
export type Tin_Check_Link_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tinId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tin_Check_Link_Min_Fields = {
  __typename?: 'tin_check_link_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  tinId?: Maybe<Scalars['uuid']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tin.check_link" */
export type Tin_Check_Link_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tinId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tin.check_link" */
export type Tin_Check_Link_Mutation_Response = {
  __typename?: 'tin_check_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tin_Check_Link>;
};

/** on_conflict condition type for table "tin.check_link" */
export type Tin_Check_Link_On_Conflict = {
  constraint: Tin_Check_Link_Constraint;
  update_columns?: Array<Tin_Check_Link_Update_Column>;
  where?: InputMaybe<Tin_Check_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "tin.check_link". */
export type Tin_Check_Link_Order_By = {
  comment?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tin?: InputMaybe<Tin_Tin_Order_By>;
  tinId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tin_check_link */
export type Tin_Check_Link_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "tin.check_link" */
export enum Tin_Check_Link_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  TinId = 'tinId',
  /** column name */
  Title = 'title',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "tin.check_link" */
export type Tin_Check_Link_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  tinId?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tin.check_link" */
export enum Tin_Check_Link_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  TinId = 'tinId',
  /** column name */
  Title = 'title',
  /** column name */
  Url = 'url'
}

export type Tin_Check_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tin_Check_Link_Set_Input>;
  where: Tin_Check_Link_Bool_Exp;
};

/** columns and relationships of "tin.country" */
export type Tin_Country = {
  __typename?: 'tin_country';
  /** Capital city from Geonames */
  capital?: Maybe<Scalars['String']['output']>;
  /** Codes from the U.S. standard FIPS PUB 10-4 */
  fips?: Maybe<Scalars['String']['output']>;
  /** ID at Geonames https://www.geonames.org/ */
  geonameId?: Maybe<Scalars['String']['output']>;
  isAccessible?: Maybe<Scalars['Boolean']['output']>;
  /** ISO 4217 currency alphabetic code */
  iso4217CurrencyAlphabeticCode?: Maybe<Scalars['String']['output']>;
  /** ISO 4217 currency name */
  iso4217CurrencyName?: Maybe<Scalars['String']['output']>;
  /** ISO 4217 currency numeric code */
  iso4217CurrencyNumericCode?: Maybe<Scalars['String']['output']>;
  /** Alpha-2 codes from ISO 3166-1 */
  iso31661Alpha2?: Maybe<Scalars['String']['output']>;
  /** Numeric codes from ISO 3166-1 */
  iso31661Numeric?: Maybe<Scalars['String']['output']>;
  /** Alpha-3 codes from ISO 3166-1 (synonymous with World Bank Codes) */
  iso31661alpha3: Scalars['String']['output'];
  /** Codes assigned by the International Telecommunications Union */
  itu?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  log: Array<Audit_Log>;
  /** An aggregate relationship */
  log_aggregate: Audit_Log_Aggregate;
  /** Country or Area official English short name from UN Statistics Divsion */
  nameEn?: Maybe<Scalars['String']['output']>;
  /** Country or Area official Russian short name from UN Statistics Divsion */
  nameRu?: Maybe<Scalars['String']['output']>;
  /** Country code from ITU-T recommendation E.164; sometimes followed by area code */
  phoneCode?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  tins: Array<Tin_Tin>;
  /** An aggregate relationship */
  tins_aggregate: Tin_Tin_Aggregate;
  /** Top level domain from Geonames */
  tld?: Maybe<Scalars['String']['output']>;
  /** Country's short English name from UN Protocol and Liaison Service */
  unitermEnglishShort?: Maybe<Scalars['String']['output']>;
  /** Country's formal Russian name from UN Protocol and Liaison Service */
  unitermRussianFormal?: Maybe<Scalars['String']['output']>;
  /** Country's formal English name from UN Protocol and Liaison Service */
  unitermRussianShort?: Maybe<Scalars['String']['output']>;
  /** Country's formal English name from UN Protocol and Liaison Service */
  uniterm_english_formal?: Maybe<Scalars['String']['output']>;
  /** Country abbreviations by the World Meteorological Organization */
  wmo?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "tin.country" */
export type Tin_CountryLogArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Log_Order_By>>;
  where?: InputMaybe<Audit_Log_Bool_Exp>;
};


/** columns and relationships of "tin.country" */
export type Tin_CountryLog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Log_Order_By>>;
  where?: InputMaybe<Audit_Log_Bool_Exp>;
};


/** columns and relationships of "tin.country" */
export type Tin_CountryTinsArgs = {
  distinct_on?: InputMaybe<Array<Tin_Tin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Tin_Order_By>>;
  where?: InputMaybe<Tin_Tin_Bool_Exp>;
};


/** columns and relationships of "tin.country" */
export type Tin_CountryTins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tin_Tin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Tin_Order_By>>;
  where?: InputMaybe<Tin_Tin_Bool_Exp>;
};

/** aggregated selection of "tin.country" */
export type Tin_Country_Aggregate = {
  __typename?: 'tin_country_aggregate';
  aggregate?: Maybe<Tin_Country_Aggregate_Fields>;
  nodes: Array<Tin_Country>;
};

/** aggregate fields of "tin.country" */
export type Tin_Country_Aggregate_Fields = {
  __typename?: 'tin_country_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tin_Country_Max_Fields>;
  min?: Maybe<Tin_Country_Min_Fields>;
};


/** aggregate fields of "tin.country" */
export type Tin_Country_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tin_Country_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "tin.country". All fields are combined with a logical 'AND'. */
export type Tin_Country_Bool_Exp = {
  _and?: InputMaybe<Array<Tin_Country_Bool_Exp>>;
  _not?: InputMaybe<Tin_Country_Bool_Exp>;
  _or?: InputMaybe<Array<Tin_Country_Bool_Exp>>;
  capital?: InputMaybe<String_Comparison_Exp>;
  fips?: InputMaybe<String_Comparison_Exp>;
  geonameId?: InputMaybe<String_Comparison_Exp>;
  isAccessible?: InputMaybe<Boolean_Comparison_Exp>;
  iso4217CurrencyAlphabeticCode?: InputMaybe<String_Comparison_Exp>;
  iso4217CurrencyName?: InputMaybe<String_Comparison_Exp>;
  iso4217CurrencyNumericCode?: InputMaybe<String_Comparison_Exp>;
  iso31661Alpha2?: InputMaybe<String_Comparison_Exp>;
  iso31661Numeric?: InputMaybe<String_Comparison_Exp>;
  iso31661alpha3?: InputMaybe<String_Comparison_Exp>;
  itu?: InputMaybe<String_Comparison_Exp>;
  log?: InputMaybe<Audit_Log_Bool_Exp>;
  nameEn?: InputMaybe<String_Comparison_Exp>;
  nameRu?: InputMaybe<String_Comparison_Exp>;
  phoneCode?: InputMaybe<String_Comparison_Exp>;
  tins?: InputMaybe<Tin_Tin_Bool_Exp>;
  tld?: InputMaybe<String_Comparison_Exp>;
  unitermEnglishShort?: InputMaybe<String_Comparison_Exp>;
  unitermRussianFormal?: InputMaybe<String_Comparison_Exp>;
  unitermRussianShort?: InputMaybe<String_Comparison_Exp>;
  uniterm_english_formal?: InputMaybe<String_Comparison_Exp>;
  wmo?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tin.country" */
export enum Tin_Country_Constraint {
  /** unique or primary key constraint on columns "iso3166_1_alpha_3" */
  CountryPkey = 'country_pkey'
}

/** input type for inserting data into table "tin.country" */
export type Tin_Country_Insert_Input = {
  /** Capital city from Geonames */
  capital?: InputMaybe<Scalars['String']['input']>;
  /** Codes from the U.S. standard FIPS PUB 10-4 */
  fips?: InputMaybe<Scalars['String']['input']>;
  /** ID at Geonames https://www.geonames.org/ */
  geonameId?: InputMaybe<Scalars['String']['input']>;
  /** ISO 4217 currency alphabetic code */
  iso4217CurrencyAlphabeticCode?: InputMaybe<Scalars['String']['input']>;
  /** ISO 4217 currency name */
  iso4217CurrencyName?: InputMaybe<Scalars['String']['input']>;
  /** ISO 4217 currency numeric code */
  iso4217CurrencyNumericCode?: InputMaybe<Scalars['String']['input']>;
  /** Alpha-2 codes from ISO 3166-1 */
  iso31661Alpha2?: InputMaybe<Scalars['String']['input']>;
  /** Numeric codes from ISO 3166-1 */
  iso31661Numeric?: InputMaybe<Scalars['String']['input']>;
  /** Alpha-3 codes from ISO 3166-1 (synonymous with World Bank Codes) */
  iso31661alpha3?: InputMaybe<Scalars['String']['input']>;
  /** Codes assigned by the International Telecommunications Union */
  itu?: InputMaybe<Scalars['String']['input']>;
  log?: InputMaybe<Audit_Log_Arr_Rel_Insert_Input>;
  /** Country or Area official English short name from UN Statistics Divsion */
  nameEn?: InputMaybe<Scalars['String']['input']>;
  /** Country or Area official Russian short name from UN Statistics Divsion */
  nameRu?: InputMaybe<Scalars['String']['input']>;
  /** Country code from ITU-T recommendation E.164; sometimes followed by area code */
  phoneCode?: InputMaybe<Scalars['String']['input']>;
  tins?: InputMaybe<Tin_Tin_Arr_Rel_Insert_Input>;
  /** Top level domain from Geonames */
  tld?: InputMaybe<Scalars['String']['input']>;
  /** Country's short English name from UN Protocol and Liaison Service */
  unitermEnglishShort?: InputMaybe<Scalars['String']['input']>;
  /** Country's formal Russian name from UN Protocol and Liaison Service */
  unitermRussianFormal?: InputMaybe<Scalars['String']['input']>;
  /** Country's formal English name from UN Protocol and Liaison Service */
  unitermRussianShort?: InputMaybe<Scalars['String']['input']>;
  /** Country's formal English name from UN Protocol and Liaison Service */
  uniterm_english_formal?: InputMaybe<Scalars['String']['input']>;
  /** Country abbreviations by the World Meteorological Organization */
  wmo?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tin_Country_Max_Fields = {
  __typename?: 'tin_country_max_fields';
  /** Capital city from Geonames */
  capital?: Maybe<Scalars['String']['output']>;
  /** Codes from the U.S. standard FIPS PUB 10-4 */
  fips?: Maybe<Scalars['String']['output']>;
  /** ID at Geonames https://www.geonames.org/ */
  geonameId?: Maybe<Scalars['String']['output']>;
  /** ISO 4217 currency alphabetic code */
  iso4217CurrencyAlphabeticCode?: Maybe<Scalars['String']['output']>;
  /** ISO 4217 currency name */
  iso4217CurrencyName?: Maybe<Scalars['String']['output']>;
  /** ISO 4217 currency numeric code */
  iso4217CurrencyNumericCode?: Maybe<Scalars['String']['output']>;
  /** Alpha-2 codes from ISO 3166-1 */
  iso31661Alpha2?: Maybe<Scalars['String']['output']>;
  /** Numeric codes from ISO 3166-1 */
  iso31661Numeric?: Maybe<Scalars['String']['output']>;
  /** Alpha-3 codes from ISO 3166-1 (synonymous with World Bank Codes) */
  iso31661alpha3?: Maybe<Scalars['String']['output']>;
  /** Codes assigned by the International Telecommunications Union */
  itu?: Maybe<Scalars['String']['output']>;
  /** Country or Area official English short name from UN Statistics Divsion */
  nameEn?: Maybe<Scalars['String']['output']>;
  /** Country or Area official Russian short name from UN Statistics Divsion */
  nameRu?: Maybe<Scalars['String']['output']>;
  /** Country code from ITU-T recommendation E.164; sometimes followed by area code */
  phoneCode?: Maybe<Scalars['String']['output']>;
  /** Top level domain from Geonames */
  tld?: Maybe<Scalars['String']['output']>;
  /** Country's short English name from UN Protocol and Liaison Service */
  unitermEnglishShort?: Maybe<Scalars['String']['output']>;
  /** Country's formal Russian name from UN Protocol and Liaison Service */
  unitermRussianFormal?: Maybe<Scalars['String']['output']>;
  /** Country's formal English name from UN Protocol and Liaison Service */
  unitermRussianShort?: Maybe<Scalars['String']['output']>;
  /** Country's formal English name from UN Protocol and Liaison Service */
  uniterm_english_formal?: Maybe<Scalars['String']['output']>;
  /** Country abbreviations by the World Meteorological Organization */
  wmo?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Tin_Country_Min_Fields = {
  __typename?: 'tin_country_min_fields';
  /** Capital city from Geonames */
  capital?: Maybe<Scalars['String']['output']>;
  /** Codes from the U.S. standard FIPS PUB 10-4 */
  fips?: Maybe<Scalars['String']['output']>;
  /** ID at Geonames https://www.geonames.org/ */
  geonameId?: Maybe<Scalars['String']['output']>;
  /** ISO 4217 currency alphabetic code */
  iso4217CurrencyAlphabeticCode?: Maybe<Scalars['String']['output']>;
  /** ISO 4217 currency name */
  iso4217CurrencyName?: Maybe<Scalars['String']['output']>;
  /** ISO 4217 currency numeric code */
  iso4217CurrencyNumericCode?: Maybe<Scalars['String']['output']>;
  /** Alpha-2 codes from ISO 3166-1 */
  iso31661Alpha2?: Maybe<Scalars['String']['output']>;
  /** Numeric codes from ISO 3166-1 */
  iso31661Numeric?: Maybe<Scalars['String']['output']>;
  /** Alpha-3 codes from ISO 3166-1 (synonymous with World Bank Codes) */
  iso31661alpha3?: Maybe<Scalars['String']['output']>;
  /** Codes assigned by the International Telecommunications Union */
  itu?: Maybe<Scalars['String']['output']>;
  /** Country or Area official English short name from UN Statistics Divsion */
  nameEn?: Maybe<Scalars['String']['output']>;
  /** Country or Area official Russian short name from UN Statistics Divsion */
  nameRu?: Maybe<Scalars['String']['output']>;
  /** Country code from ITU-T recommendation E.164; sometimes followed by area code */
  phoneCode?: Maybe<Scalars['String']['output']>;
  /** Top level domain from Geonames */
  tld?: Maybe<Scalars['String']['output']>;
  /** Country's short English name from UN Protocol and Liaison Service */
  unitermEnglishShort?: Maybe<Scalars['String']['output']>;
  /** Country's formal Russian name from UN Protocol and Liaison Service */
  unitermRussianFormal?: Maybe<Scalars['String']['output']>;
  /** Country's formal English name from UN Protocol and Liaison Service */
  unitermRussianShort?: Maybe<Scalars['String']['output']>;
  /** Country's formal English name from UN Protocol and Liaison Service */
  uniterm_english_formal?: Maybe<Scalars['String']['output']>;
  /** Country abbreviations by the World Meteorological Organization */
  wmo?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "tin.country" */
export type Tin_Country_Mutation_Response = {
  __typename?: 'tin_country_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tin_Country>;
};

/** input type for inserting object relation for remote table "tin.country" */
export type Tin_Country_Obj_Rel_Insert_Input = {
  data: Tin_Country_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tin_Country_On_Conflict>;
};

/** on_conflict condition type for table "tin.country" */
export type Tin_Country_On_Conflict = {
  constraint: Tin_Country_Constraint;
  update_columns?: Array<Tin_Country_Update_Column>;
  where?: InputMaybe<Tin_Country_Bool_Exp>;
};

/** Ordering options when selecting data from "tin.country". */
export type Tin_Country_Order_By = {
  capital?: InputMaybe<Order_By>;
  fips?: InputMaybe<Order_By>;
  geonameId?: InputMaybe<Order_By>;
  isAccessible?: InputMaybe<Order_By>;
  iso4217CurrencyAlphabeticCode?: InputMaybe<Order_By>;
  iso4217CurrencyName?: InputMaybe<Order_By>;
  iso4217CurrencyNumericCode?: InputMaybe<Order_By>;
  iso31661Alpha2?: InputMaybe<Order_By>;
  iso31661Numeric?: InputMaybe<Order_By>;
  iso31661alpha3?: InputMaybe<Order_By>;
  itu?: InputMaybe<Order_By>;
  log_aggregate?: InputMaybe<Audit_Log_Aggregate_Order_By>;
  nameEn?: InputMaybe<Order_By>;
  nameRu?: InputMaybe<Order_By>;
  phoneCode?: InputMaybe<Order_By>;
  tins_aggregate?: InputMaybe<Tin_Tin_Aggregate_Order_By>;
  tld?: InputMaybe<Order_By>;
  unitermEnglishShort?: InputMaybe<Order_By>;
  unitermRussianFormal?: InputMaybe<Order_By>;
  unitermRussianShort?: InputMaybe<Order_By>;
  uniterm_english_formal?: InputMaybe<Order_By>;
  wmo?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tin_country */
export type Tin_Country_Pk_Columns_Input = {
  /** Alpha-3 codes from ISO 3166-1 (synonymous with World Bank Codes) */
  iso31661alpha3: Scalars['String']['input'];
};

/** select columns of table "tin.country" */
export enum Tin_Country_Select_Column {
  /** column name */
  Capital = 'capital',
  /** column name */
  Fips = 'fips',
  /** column name */
  GeonameId = 'geonameId',
  /** column name */
  Iso4217CurrencyAlphabeticCode = 'iso4217CurrencyAlphabeticCode',
  /** column name */
  Iso4217CurrencyName = 'iso4217CurrencyName',
  /** column name */
  Iso4217CurrencyNumericCode = 'iso4217CurrencyNumericCode',
  /** column name */
  Iso31661Alpha2 = 'iso31661Alpha2',
  /** column name */
  Iso31661Numeric = 'iso31661Numeric',
  /** column name */
  Iso31661alpha3 = 'iso31661alpha3',
  /** column name */
  Itu = 'itu',
  /** column name */
  NameEn = 'nameEn',
  /** column name */
  NameRu = 'nameRu',
  /** column name */
  PhoneCode = 'phoneCode',
  /** column name */
  Tld = 'tld',
  /** column name */
  UnitermEnglishShort = 'unitermEnglishShort',
  /** column name */
  UnitermRussianFormal = 'unitermRussianFormal',
  /** column name */
  UnitermRussianShort = 'unitermRussianShort',
  /** column name */
  UnitermEnglishFormal = 'uniterm_english_formal',
  /** column name */
  Wmo = 'wmo'
}

/** input type for updating data in table "tin.country" */
export type Tin_Country_Set_Input = {
  /** Capital city from Geonames */
  capital?: InputMaybe<Scalars['String']['input']>;
  /** Codes from the U.S. standard FIPS PUB 10-4 */
  fips?: InputMaybe<Scalars['String']['input']>;
  /** ID at Geonames https://www.geonames.org/ */
  geonameId?: InputMaybe<Scalars['String']['input']>;
  /** ISO 4217 currency alphabetic code */
  iso4217CurrencyAlphabeticCode?: InputMaybe<Scalars['String']['input']>;
  /** ISO 4217 currency name */
  iso4217CurrencyName?: InputMaybe<Scalars['String']['input']>;
  /** ISO 4217 currency numeric code */
  iso4217CurrencyNumericCode?: InputMaybe<Scalars['String']['input']>;
  /** Alpha-2 codes from ISO 3166-1 */
  iso31661Alpha2?: InputMaybe<Scalars['String']['input']>;
  /** Numeric codes from ISO 3166-1 */
  iso31661Numeric?: InputMaybe<Scalars['String']['input']>;
  /** Alpha-3 codes from ISO 3166-1 (synonymous with World Bank Codes) */
  iso31661alpha3?: InputMaybe<Scalars['String']['input']>;
  /** Codes assigned by the International Telecommunications Union */
  itu?: InputMaybe<Scalars['String']['input']>;
  /** Country or Area official English short name from UN Statistics Divsion */
  nameEn?: InputMaybe<Scalars['String']['input']>;
  /** Country or Area official Russian short name from UN Statistics Divsion */
  nameRu?: InputMaybe<Scalars['String']['input']>;
  /** Country code from ITU-T recommendation E.164; sometimes followed by area code */
  phoneCode?: InputMaybe<Scalars['String']['input']>;
  /** Top level domain from Geonames */
  tld?: InputMaybe<Scalars['String']['input']>;
  /** Country's short English name from UN Protocol and Liaison Service */
  unitermEnglishShort?: InputMaybe<Scalars['String']['input']>;
  /** Country's formal Russian name from UN Protocol and Liaison Service */
  unitermRussianFormal?: InputMaybe<Scalars['String']['input']>;
  /** Country's formal English name from UN Protocol and Liaison Service */
  unitermRussianShort?: InputMaybe<Scalars['String']['input']>;
  /** Country's formal English name from UN Protocol and Liaison Service */
  uniterm_english_formal?: InputMaybe<Scalars['String']['input']>;
  /** Country abbreviations by the World Meteorological Organization */
  wmo?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tin.country" */
export enum Tin_Country_Update_Column {
  /** column name */
  Capital = 'capital',
  /** column name */
  Fips = 'fips',
  /** column name */
  GeonameId = 'geonameId',
  /** column name */
  Iso4217CurrencyAlphabeticCode = 'iso4217CurrencyAlphabeticCode',
  /** column name */
  Iso4217CurrencyName = 'iso4217CurrencyName',
  /** column name */
  Iso4217CurrencyNumericCode = 'iso4217CurrencyNumericCode',
  /** column name */
  Iso31661Alpha2 = 'iso31661Alpha2',
  /** column name */
  Iso31661Numeric = 'iso31661Numeric',
  /** column name */
  Iso31661alpha3 = 'iso31661alpha3',
  /** column name */
  Itu = 'itu',
  /** column name */
  NameEn = 'nameEn',
  /** column name */
  NameRu = 'nameRu',
  /** column name */
  PhoneCode = 'phoneCode',
  /** column name */
  Tld = 'tld',
  /** column name */
  UnitermEnglishShort = 'unitermEnglishShort',
  /** column name */
  UnitermRussianFormal = 'unitermRussianFormal',
  /** column name */
  UnitermRussianShort = 'unitermRussianShort',
  /** column name */
  UnitermEnglishFormal = 'uniterm_english_formal',
  /** column name */
  Wmo = 'wmo'
}

export type Tin_Country_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tin_Country_Set_Input>;
  where: Tin_Country_Bool_Exp;
};

/** columns and relationships of "tin.document" */
export type Tin_Document = {
  __typename?: 'tin_document';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  image?: Maybe<Tin_Image>;
  /** An array relationship */
  images: Array<Tin_Image>;
  /** An aggregate relationship */
  images_aggregate: Tin_Image_Aggregate;
  nameNative?: Maybe<Scalars['String']['output']>;
  nameRu?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  tin: Tin_Tin;
  tinId: Scalars['uuid']['output'];
};


/** columns and relationships of "tin.document" */
export type Tin_DocumentImagesArgs = {
  distinct_on?: InputMaybe<Array<Tin_Image_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Image_Order_By>>;
  where?: InputMaybe<Tin_Image_Bool_Exp>;
};


/** columns and relationships of "tin.document" */
export type Tin_DocumentImages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tin_Image_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Image_Order_By>>;
  where?: InputMaybe<Tin_Image_Bool_Exp>;
};

/** aggregated selection of "tin.document" */
export type Tin_Document_Aggregate = {
  __typename?: 'tin_document_aggregate';
  aggregate?: Maybe<Tin_Document_Aggregate_Fields>;
  nodes: Array<Tin_Document>;
};

/** aggregate fields of "tin.document" */
export type Tin_Document_Aggregate_Fields = {
  __typename?: 'tin_document_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tin_Document_Max_Fields>;
  min?: Maybe<Tin_Document_Min_Fields>;
};


/** aggregate fields of "tin.document" */
export type Tin_Document_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tin_Document_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tin.document" */
export type Tin_Document_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tin_Document_Max_Order_By>;
  min?: InputMaybe<Tin_Document_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tin.document" */
export type Tin_Document_Arr_Rel_Insert_Input = {
  data: Array<Tin_Document_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tin_Document_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tin.document". All fields are combined with a logical 'AND'. */
export type Tin_Document_Bool_Exp = {
  _and?: InputMaybe<Array<Tin_Document_Bool_Exp>>;
  _not?: InputMaybe<Tin_Document_Bool_Exp>;
  _or?: InputMaybe<Array<Tin_Document_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image?: InputMaybe<Tin_Image_Bool_Exp>;
  images?: InputMaybe<Tin_Image_Bool_Exp>;
  nameNative?: InputMaybe<String_Comparison_Exp>;
  nameRu?: InputMaybe<String_Comparison_Exp>;
  tin?: InputMaybe<Tin_Tin_Bool_Exp>;
  tinId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "tin.document" */
export enum Tin_Document_Constraint {
  /** unique or primary key constraint on columns "id" */
  DocumentPkey = 'document_pkey'
}

/** input type for inserting data into table "tin.document" */
export type Tin_Document_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image?: InputMaybe<Tin_Image_Obj_Rel_Insert_Input>;
  images?: InputMaybe<Tin_Image_Arr_Rel_Insert_Input>;
  nameNative?: InputMaybe<Scalars['String']['input']>;
  nameRu?: InputMaybe<Scalars['String']['input']>;
  tin?: InputMaybe<Tin_Tin_Obj_Rel_Insert_Input>;
  tinId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Tin_Document_Max_Fields = {
  __typename?: 'tin_document_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  nameNative?: Maybe<Scalars['String']['output']>;
  nameRu?: Maybe<Scalars['String']['output']>;
  tinId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "tin.document" */
export type Tin_Document_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nameNative?: InputMaybe<Order_By>;
  nameRu?: InputMaybe<Order_By>;
  tinId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tin_Document_Min_Fields = {
  __typename?: 'tin_document_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  nameNative?: Maybe<Scalars['String']['output']>;
  nameRu?: Maybe<Scalars['String']['output']>;
  tinId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "tin.document" */
export type Tin_Document_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nameNative?: InputMaybe<Order_By>;
  nameRu?: InputMaybe<Order_By>;
  tinId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tin.document" */
export type Tin_Document_Mutation_Response = {
  __typename?: 'tin_document_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tin_Document>;
};

/** input type for inserting object relation for remote table "tin.document" */
export type Tin_Document_Obj_Rel_Insert_Input = {
  data: Tin_Document_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tin_Document_On_Conflict>;
};

/** on_conflict condition type for table "tin.document" */
export type Tin_Document_On_Conflict = {
  constraint: Tin_Document_Constraint;
  update_columns?: Array<Tin_Document_Update_Column>;
  where?: InputMaybe<Tin_Document_Bool_Exp>;
};

/** Ordering options when selecting data from "tin.document". */
export type Tin_Document_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Tin_Image_Order_By>;
  images_aggregate?: InputMaybe<Tin_Image_Aggregate_Order_By>;
  nameNative?: InputMaybe<Order_By>;
  nameRu?: InputMaybe<Order_By>;
  tin?: InputMaybe<Tin_Tin_Order_By>;
  tinId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tin_document */
export type Tin_Document_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "tin.document" */
export enum Tin_Document_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NameNative = 'nameNative',
  /** column name */
  NameRu = 'nameRu',
  /** column name */
  TinId = 'tinId'
}

/** input type for updating data in table "tin.document" */
export type Tin_Document_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  nameNative?: InputMaybe<Scalars['String']['input']>;
  nameRu?: InputMaybe<Scalars['String']['input']>;
  tinId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "tin.document" */
export enum Tin_Document_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NameNative = 'nameNative',
  /** column name */
  NameRu = 'nameRu',
  /** column name */
  TinId = 'tinId'
}

export type Tin_Document_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tin_Document_Set_Input>;
  where: Tin_Document_Bool_Exp;
};

/** columns and relationships of "tin.image" */
export type Tin_Image = {
  __typename?: 'tin_image';
  /** An object relationship */
  document: Tin_Document;
  documentId: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  image: Scalars['String']['output'];
};

/** aggregated selection of "tin.image" */
export type Tin_Image_Aggregate = {
  __typename?: 'tin_image_aggregate';
  aggregate?: Maybe<Tin_Image_Aggregate_Fields>;
  nodes: Array<Tin_Image>;
};

/** aggregate fields of "tin.image" */
export type Tin_Image_Aggregate_Fields = {
  __typename?: 'tin_image_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tin_Image_Max_Fields>;
  min?: Maybe<Tin_Image_Min_Fields>;
};


/** aggregate fields of "tin.image" */
export type Tin_Image_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tin_Image_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tin.image" */
export type Tin_Image_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tin_Image_Max_Order_By>;
  min?: InputMaybe<Tin_Image_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tin.image" */
export type Tin_Image_Arr_Rel_Insert_Input = {
  data: Array<Tin_Image_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tin_Image_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tin.image". All fields are combined with a logical 'AND'. */
export type Tin_Image_Bool_Exp = {
  _and?: InputMaybe<Array<Tin_Image_Bool_Exp>>;
  _not?: InputMaybe<Tin_Image_Bool_Exp>;
  _or?: InputMaybe<Array<Tin_Image_Bool_Exp>>;
  document?: InputMaybe<Tin_Document_Bool_Exp>;
  documentId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tin.image" */
export enum Tin_Image_Constraint {
  /** unique or primary key constraint on columns "document_id" */
  ImageDocumentIdKey = 'image_document_id_key',
  /** unique or primary key constraint on columns "id" */
  ImagePkey = 'image_pkey'
}

/** input type for inserting data into table "tin.image" */
export type Tin_Image_Insert_Input = {
  document?: InputMaybe<Tin_Document_Obj_Rel_Insert_Input>;
  documentId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tin_Image_Max_Fields = {
  __typename?: 'tin_image_max_fields';
  documentId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  image?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tin.image" */
export type Tin_Image_Max_Order_By = {
  documentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tin_Image_Min_Fields = {
  __typename?: 'tin_image_min_fields';
  documentId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  image?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tin.image" */
export type Tin_Image_Min_Order_By = {
  documentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tin.image" */
export type Tin_Image_Mutation_Response = {
  __typename?: 'tin_image_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tin_Image>;
};

/** input type for inserting object relation for remote table "tin.image" */
export type Tin_Image_Obj_Rel_Insert_Input = {
  data: Tin_Image_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tin_Image_On_Conflict>;
};

/** on_conflict condition type for table "tin.image" */
export type Tin_Image_On_Conflict = {
  constraint: Tin_Image_Constraint;
  update_columns?: Array<Tin_Image_Update_Column>;
  where?: InputMaybe<Tin_Image_Bool_Exp>;
};

/** Ordering options when selecting data from "tin.image". */
export type Tin_Image_Order_By = {
  document?: InputMaybe<Tin_Document_Order_By>;
  documentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tin_image */
export type Tin_Image_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "tin.image" */
export enum Tin_Image_Select_Column {
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image'
}

/** input type for updating data in table "tin.image" */
export type Tin_Image_Set_Input = {
  documentId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tin.image" */
export enum Tin_Image_Update_Column {
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image'
}

export type Tin_Image_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tin_Image_Set_Input>;
  where: Tin_Image_Bool_Exp;
};

/** columns and relationships of "tin.tin" */
export type Tin_Tin = {
  __typename?: 'tin_tin';
  abbreviation?: Maybe<Scalars['String']['output']>;
  absenceReason?: Maybe<Scalars['String']['output']>;
  assignmentProcedure?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  checkLinks: Array<Tin_Check_Link>;
  /** An aggregate relationship */
  checkLinks_aggregate: Tin_Check_Link_Aggregate;
  /** An object relationship */
  country: Tin_Country;
  countryCode: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  documents: Array<Tin_Document>;
  /** An aggregate relationship */
  documents_aggregate: Tin_Document_Aggregate;
  format?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['uuid']['output'];
  isAnalogue: Scalars['Boolean']['output'];
  /** An array relationship */
  log: Array<Audit_Log>;
  /** An aggregate relationship */
  log_aggregate: Audit_Log_Aggregate;
  type: Tin_Tin_Type_Enum;
};


/** columns and relationships of "tin.tin" */
export type Tin_TinCheckLinksArgs = {
  distinct_on?: InputMaybe<Array<Tin_Check_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Check_Link_Order_By>>;
  where?: InputMaybe<Tin_Check_Link_Bool_Exp>;
};


/** columns and relationships of "tin.tin" */
export type Tin_TinCheckLinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tin_Check_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Check_Link_Order_By>>;
  where?: InputMaybe<Tin_Check_Link_Bool_Exp>;
};


/** columns and relationships of "tin.tin" */
export type Tin_TinDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Tin_Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Document_Order_By>>;
  where?: InputMaybe<Tin_Document_Bool_Exp>;
};


/** columns and relationships of "tin.tin" */
export type Tin_TinDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tin_Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tin_Document_Order_By>>;
  where?: InputMaybe<Tin_Document_Bool_Exp>;
};


/** columns and relationships of "tin.tin" */
export type Tin_TinFormatArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "tin.tin" */
export type Tin_TinLogArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Log_Order_By>>;
  where?: InputMaybe<Audit_Log_Bool_Exp>;
};


/** columns and relationships of "tin.tin" */
export type Tin_TinLog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Log_Order_By>>;
  where?: InputMaybe<Audit_Log_Bool_Exp>;
};

/** aggregated selection of "tin.tin" */
export type Tin_Tin_Aggregate = {
  __typename?: 'tin_tin_aggregate';
  aggregate?: Maybe<Tin_Tin_Aggregate_Fields>;
  nodes: Array<Tin_Tin>;
};

/** aggregate fields of "tin.tin" */
export type Tin_Tin_Aggregate_Fields = {
  __typename?: 'tin_tin_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tin_Tin_Max_Fields>;
  min?: Maybe<Tin_Tin_Min_Fields>;
};


/** aggregate fields of "tin.tin" */
export type Tin_Tin_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tin_Tin_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tin.tin" */
export type Tin_Tin_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tin_Tin_Max_Order_By>;
  min?: InputMaybe<Tin_Tin_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tin_Tin_Append_Input = {
  format?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "tin.tin" */
export type Tin_Tin_Arr_Rel_Insert_Input = {
  data: Array<Tin_Tin_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tin_Tin_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tin.tin". All fields are combined with a logical 'AND'. */
export type Tin_Tin_Bool_Exp = {
  _and?: InputMaybe<Array<Tin_Tin_Bool_Exp>>;
  _not?: InputMaybe<Tin_Tin_Bool_Exp>;
  _or?: InputMaybe<Array<Tin_Tin_Bool_Exp>>;
  abbreviation?: InputMaybe<String_Comparison_Exp>;
  absenceReason?: InputMaybe<String_Comparison_Exp>;
  assignmentProcedure?: InputMaybe<String_Comparison_Exp>;
  checkLinks?: InputMaybe<Tin_Check_Link_Bool_Exp>;
  country?: InputMaybe<Tin_Country_Bool_Exp>;
  countryCode?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  documents?: InputMaybe<Tin_Document_Bool_Exp>;
  format?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isAnalogue?: InputMaybe<Boolean_Comparison_Exp>;
  log?: InputMaybe<Audit_Log_Bool_Exp>;
  type?: InputMaybe<Tin_Tin_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "tin.tin" */
export enum Tin_Tin_Constraint {
  /** unique or primary key constraint on columns "id" */
  TinPkey = 'tin_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tin_Tin_Delete_At_Path_Input = {
  format?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tin_Tin_Delete_Elem_Input = {
  format?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tin_Tin_Delete_Key_Input = {
  format?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "tin.tin" */
export type Tin_Tin_Insert_Input = {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  absenceReason?: InputMaybe<Scalars['String']['input']>;
  assignmentProcedure?: InputMaybe<Scalars['String']['input']>;
  checkLinks?: InputMaybe<Tin_Check_Link_Arr_Rel_Insert_Input>;
  country?: InputMaybe<Tin_Country_Obj_Rel_Insert_Input>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<Tin_Document_Arr_Rel_Insert_Input>;
  format?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isAnalogue?: InputMaybe<Scalars['Boolean']['input']>;
  log?: InputMaybe<Audit_Log_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Tin_Tin_Type_Enum>;
};

/** aggregate max on columns */
export type Tin_Tin_Max_Fields = {
  __typename?: 'tin_tin_max_fields';
  abbreviation?: Maybe<Scalars['String']['output']>;
  absenceReason?: Maybe<Scalars['String']['output']>;
  assignmentProcedure?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "tin.tin" */
export type Tin_Tin_Max_Order_By = {
  abbreviation?: InputMaybe<Order_By>;
  absenceReason?: InputMaybe<Order_By>;
  assignmentProcedure?: InputMaybe<Order_By>;
  countryCode?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tin_Tin_Min_Fields = {
  __typename?: 'tin_tin_min_fields';
  abbreviation?: Maybe<Scalars['String']['output']>;
  absenceReason?: Maybe<Scalars['String']['output']>;
  assignmentProcedure?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "tin.tin" */
export type Tin_Tin_Min_Order_By = {
  abbreviation?: InputMaybe<Order_By>;
  absenceReason?: InputMaybe<Order_By>;
  assignmentProcedure?: InputMaybe<Order_By>;
  countryCode?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tin.tin" */
export type Tin_Tin_Mutation_Response = {
  __typename?: 'tin_tin_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tin_Tin>;
};

/** input type for inserting object relation for remote table "tin.tin" */
export type Tin_Tin_Obj_Rel_Insert_Input = {
  data: Tin_Tin_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tin_Tin_On_Conflict>;
};

/** on_conflict condition type for table "tin.tin" */
export type Tin_Tin_On_Conflict = {
  constraint: Tin_Tin_Constraint;
  update_columns?: Array<Tin_Tin_Update_Column>;
  where?: InputMaybe<Tin_Tin_Bool_Exp>;
};

/** Ordering options when selecting data from "tin.tin". */
export type Tin_Tin_Order_By = {
  abbreviation?: InputMaybe<Order_By>;
  absenceReason?: InputMaybe<Order_By>;
  assignmentProcedure?: InputMaybe<Order_By>;
  checkLinks_aggregate?: InputMaybe<Tin_Check_Link_Aggregate_Order_By>;
  country?: InputMaybe<Tin_Country_Order_By>;
  countryCode?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  documents_aggregate?: InputMaybe<Tin_Document_Aggregate_Order_By>;
  format?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isAnalogue?: InputMaybe<Order_By>;
  log_aggregate?: InputMaybe<Audit_Log_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tin_tin */
export type Tin_Tin_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tin_Tin_Prepend_Input = {
  format?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tin.tin" */
export enum Tin_Tin_Select_Column {
  /** column name */
  Abbreviation = 'abbreviation',
  /** column name */
  AbsenceReason = 'absenceReason',
  /** column name */
  AssignmentProcedure = 'assignmentProcedure',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Format = 'format',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnalogue = 'isAnalogue',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "tin.tin" */
export type Tin_Tin_Set_Input = {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  absenceReason?: InputMaybe<Scalars['String']['input']>;
  assignmentProcedure?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  format?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isAnalogue?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Tin_Tin_Type_Enum>;
};

/** columns and relationships of "tin.tin_type" */
export type Tin_Tin_Type = {
  __typename?: 'tin_tin_type';
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** aggregated selection of "tin.tin_type" */
export type Tin_Tin_Type_Aggregate = {
  __typename?: 'tin_tin_type_aggregate';
  aggregate?: Maybe<Tin_Tin_Type_Aggregate_Fields>;
  nodes: Array<Tin_Tin_Type>;
};

/** aggregate fields of "tin.tin_type" */
export type Tin_Tin_Type_Aggregate_Fields = {
  __typename?: 'tin_tin_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tin_Tin_Type_Max_Fields>;
  min?: Maybe<Tin_Tin_Type_Min_Fields>;
};


/** aggregate fields of "tin.tin_type" */
export type Tin_Tin_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tin_Tin_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "tin.tin_type". All fields are combined with a logical 'AND'. */
export type Tin_Tin_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Tin_Tin_Type_Bool_Exp>>;
  _not?: InputMaybe<Tin_Tin_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Tin_Tin_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tin.tin_type" */
export enum Tin_Tin_Type_Constraint {
  /** unique or primary key constraint on columns "name" */
  TinTypePkey = 'tin_type_pkey'
}

export enum Tin_Tin_Type_Enum {
  /** Juridical entity */
  Juridical = 'JURIDICAL',
  /** Private person */
  Private = 'PRIVATE'
}

/** Boolean expression to compare columns of type "tin_tin_type_enum". All fields are combined with logical 'AND'. */
export type Tin_Tin_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Tin_Tin_Type_Enum>;
  _in?: InputMaybe<Array<Tin_Tin_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Tin_Tin_Type_Enum>;
  _nin?: InputMaybe<Array<Tin_Tin_Type_Enum>>;
};

/** input type for inserting data into table "tin.tin_type" */
export type Tin_Tin_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tin_Tin_Type_Max_Fields = {
  __typename?: 'tin_tin_type_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Tin_Tin_Type_Min_Fields = {
  __typename?: 'tin_tin_type_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "tin.tin_type" */
export type Tin_Tin_Type_Mutation_Response = {
  __typename?: 'tin_tin_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tin_Tin_Type>;
};

/** on_conflict condition type for table "tin.tin_type" */
export type Tin_Tin_Type_On_Conflict = {
  constraint: Tin_Tin_Type_Constraint;
  update_columns?: Array<Tin_Tin_Type_Update_Column>;
  where?: InputMaybe<Tin_Tin_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "tin.tin_type". */
export type Tin_Tin_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tin_tin_type */
export type Tin_Tin_Type_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "tin.tin_type" */
export enum Tin_Tin_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "tin.tin_type" */
export type Tin_Tin_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tin.tin_type" */
export enum Tin_Tin_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

export type Tin_Tin_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tin_Tin_Type_Set_Input>;
  where: Tin_Tin_Type_Bool_Exp;
};

/** update columns of table "tin.tin" */
export enum Tin_Tin_Update_Column {
  /** column name */
  Abbreviation = 'abbreviation',
  /** column name */
  AbsenceReason = 'absenceReason',
  /** column name */
  AssignmentProcedure = 'assignmentProcedure',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Format = 'format',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnalogue = 'isAnalogue',
  /** column name */
  Type = 'type'
}

export type Tin_Tin_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tin_Tin_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tin_Tin_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tin_Tin_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tin_Tin_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tin_Tin_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tin_Tin_Set_Input>;
  where: Tin_Tin_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

export type AddCheckLinkMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  tinId: Scalars['uuid']['input'];
}>;


export type AddCheckLinkMutation = { __typename?: 'mutation_root', addCheckLink?: { __typename?: 'tin_check_link', id: string } | null };

export type AddDocumentMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  tinId: Scalars['uuid']['input'];
}>;


export type AddDocumentMutation = { __typename?: 'mutation_root', addDocument?: { __typename?: 'tin_document', id: string } | null };

export type AddImageMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  image: Scalars['String']['input'];
  documentId: Scalars['uuid']['input'];
}>;


export type AddImageMutation = { __typename?: 'mutation_root', addImage?: { __typename?: 'tin_image', id: string } | null };

export type AddTinMutationVariables = Exact<{
  countryCode: Scalars['String']['input'];
  type: Tin_Tin_Type_Enum;
}>;


export type AddTinMutation = { __typename?: 'mutation_root', addTin?: { __typename?: 'tin_tin', id: string, type: Tin_Tin_Type_Enum, country: { __typename?: 'tin_country', code: string, tins: Array<{ __typename?: 'tin_tin', id: string, type: Tin_Tin_Type_Enum, format?: any | null, countryCode: string, abbreviation?: string | null, description?: string | null, assignmentProcedure?: string | null, absenceReason?: string | null, isAnalogue: boolean, checkLinks: Array<{ __typename?: 'tin_check_link', id: string, url?: string | null, title?: string | null, comment?: string | null, tinId: string }>, documents: Array<{ __typename?: 'tin_document', id: string, nameRu?: string | null, nameNative?: string | null, tinId: string, image?: { __typename?: 'tin_image', id: string } | null }> }> } } | null };

export type AdminTinsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminTinsQuery = { __typename?: 'query_root', tins: Array<{ __typename?: 'tin_tin', id: string, format?: any | null, country: { __typename?: 'tin_country', nameRu?: string | null, code: string } }> };

export type CheckLinkFragment = { __typename?: 'tin_check_link', id: string, url?: string | null, title?: string | null, comment?: string | null, tinId: string };

export type CountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CountriesQuery = { __typename?: 'query_root', countries: Array<{ __typename?: 'tin_country', nameRu?: string | null, isAccessible?: boolean | null, code: string }> };

export type CountriesAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type CountriesAdminQuery = { __typename?: 'query_root', countries: Array<{ __typename?: 'tin_country', nameRu?: string | null, code: string, juridicalTins: Array<{ __typename?: 'tin_tin', id: string, format?: any | null }>, privateTins: Array<{ __typename?: 'tin_tin', id: string, format?: any | null }>, analogueTins: Array<{ __typename?: 'tin_tin', id: string, format?: any | null }> }> };

export type TinAdminFragment = { __typename?: 'tin_tin', id: string, format?: any | null };

export type CountryQueryVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type CountryQuery = { __typename?: 'query_root', country?: { __typename?: 'tin_country', isAccessible?: boolean | null, code: string, log: Array<{ __typename?: 'audit_log', id?: any | null, createdAt?: any | null, changedFields?: any | null, tin?: { __typename?: 'tin_tin', id: string, abbreviation?: string | null } | null }>, tins: Array<{ __typename?: 'tin_tin', id: string, type: Tin_Tin_Type_Enum, format?: any | null, countryCode: string, abbreviation?: string | null, description?: string | null, assignmentProcedure?: string | null, absenceReason?: string | null, isAnalogue: boolean, checkLinks: Array<{ __typename?: 'tin_check_link', id: string, url?: string | null, title?: string | null, comment?: string | null, tinId: string }>, documents: Array<{ __typename?: 'tin_document', id: string, nameRu?: string | null, nameNative?: string | null, tinId: string, image?: { __typename?: 'tin_image', id: string } | null }> }> } | null };

export type DocumentFragment = { __typename?: 'tin_document', id: string, nameRu?: string | null, nameNative?: string | null, tinId: string, image?: { __typename?: 'tin_image', id: string } | null };

export type ImageQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type ImageQuery = { __typename?: 'query_root', image?: { __typename?: 'tin_image', id: string, image: string } | null };

export type LoginMutationVariables = Exact<{
  login: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'mutation_root', login: { __typename?: 'InvalidCredentials', message?: string | null } | { __typename?: 'Token', token: string, userId: string } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'mutation_root', logout?: boolean | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'query_root', me?: { __typename?: 'auth_user', id: string, login?: string | null, role: Auth_Role_Enum } | null, countries: Array<{ __typename?: 'tin_country', nameRu?: string | null, isAccessible?: boolean | null, code: string }> };

export type RemoveCheckLinkMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type RemoveCheckLinkMutation = { __typename?: 'mutation_root', removeCheckLink?: { __typename?: 'tin_check_link', id: string } | null };

export type RemoveDocumentMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type RemoveDocumentMutation = { __typename?: 'mutation_root', removeDocument?: { __typename?: 'tin_document', id: string } | null };

export type RemoveTinMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type RemoveTinMutation = { __typename?: 'mutation_root', removeTin?: { __typename?: 'tin_tin', id: string } | null };

export type TinFragment = { __typename?: 'tin_tin', id: string, type: Tin_Tin_Type_Enum, format?: any | null, countryCode: string, abbreviation?: string | null, description?: string | null, assignmentProcedure?: string | null, absenceReason?: string | null, isAnalogue: boolean, checkLinks: Array<{ __typename?: 'tin_check_link', id: string, url?: string | null, title?: string | null, comment?: string | null, tinId: string }>, documents: Array<{ __typename?: 'tin_document', id: string, nameRu?: string | null, nameNative?: string | null, tinId: string, image?: { __typename?: 'tin_image', id: string } | null }> };

export type UpdateChecklinkMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  _set: Tin_Check_Link_Set_Input;
}>;


export type UpdateChecklinkMutation = { __typename?: 'mutation_root', updateChecklink?: { __typename?: 'tin_check_link', id: string, url?: string | null, title?: string | null, comment?: string | null, tinId: string } | null };

export type UpdateDocumentMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  _set: Tin_Document_Set_Input;
}>;


export type UpdateDocumentMutation = { __typename?: 'mutation_root', updateDocument?: { __typename?: 'tin_document', id: string, nameRu?: string | null, nameNative?: string | null, tinId: string, image?: { __typename?: 'tin_image', id: string } | null } | null };

export type UpdateTinMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  _set: Tin_Tin_Set_Input;
}>;


export type UpdateTinMutation = { __typename?: 'mutation_root', updateTin?: { __typename?: 'tin_tin', id: string, type: Tin_Tin_Type_Enum, format?: any | null, countryCode: string, abbreviation?: string | null, description?: string | null, assignmentProcedure?: string | null, absenceReason?: string | null, isAnalogue: boolean, checkLinks: Array<{ __typename?: 'tin_check_link', id: string, url?: string | null, title?: string | null, comment?: string | null, tinId: string }>, documents: Array<{ __typename?: 'tin_document', id: string, nameRu?: string | null, nameNative?: string | null, tinId: string, image?: { __typename?: 'tin_image', id: string } | null }> } | null };

export const TinAdminFragmentDoc = gql`
    fragment tinAdmin on tin_tin {
  id
  format
}
    `;
export const CheckLinkFragmentDoc = gql`
    fragment checkLink on tin_check_link {
  id
  url
  title
  comment
  tinId
}
    `;
export const DocumentFragmentDoc = gql`
    fragment document on tin_document {
  id
  nameRu
  nameNative
  tinId
  image {
    id
  }
}
    `;
export const TinFragmentDoc = gql`
    fragment tin on tin_tin {
  id
  type
  format
  countryCode
  abbreviation
  description
  assignmentProcedure
  absenceReason
  isAnalogue
  checkLinks {
    ...checkLink
  }
  documents {
    ...document
  }
}
    ${CheckLinkFragmentDoc}
${DocumentFragmentDoc}`;
export const AddCheckLinkDocument = gql`
    mutation addCheckLink($id: uuid!, $tinId: uuid!) {
  addCheckLink(object: {id: $id, tinId: $tinId}) {
    id
  }
}
    `;
export type AddCheckLinkMutationFn = Apollo.MutationFunction<AddCheckLinkMutation, AddCheckLinkMutationVariables>;

/**
 * __useAddCheckLinkMutation__
 *
 * To run a mutation, you first call `useAddCheckLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCheckLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCheckLinkMutation, { data, loading, error }] = useAddCheckLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tinId: // value for 'tinId'
 *   },
 * });
 */
export function useAddCheckLinkMutation(baseOptions?: Apollo.MutationHookOptions<AddCheckLinkMutation, AddCheckLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCheckLinkMutation, AddCheckLinkMutationVariables>(AddCheckLinkDocument, options);
      }
export type AddCheckLinkMutationHookResult = ReturnType<typeof useAddCheckLinkMutation>;
export type AddCheckLinkMutationResult = Apollo.MutationResult<AddCheckLinkMutation>;
export type AddCheckLinkMutationOptions = Apollo.BaseMutationOptions<AddCheckLinkMutation, AddCheckLinkMutationVariables>;
export const AddDocumentDocument = gql`
    mutation addDocument($id: uuid!, $tinId: uuid!) {
  addDocument(object: {id: $id, tinId: $tinId}) {
    id
  }
}
    `;
export type AddDocumentMutationFn = Apollo.MutationFunction<AddDocumentMutation, AddDocumentMutationVariables>;

/**
 * __useAddDocumentMutation__
 *
 * To run a mutation, you first call `useAddDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDocumentMutation, { data, loading, error }] = useAddDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tinId: // value for 'tinId'
 *   },
 * });
 */
export function useAddDocumentMutation(baseOptions?: Apollo.MutationHookOptions<AddDocumentMutation, AddDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDocumentMutation, AddDocumentMutationVariables>(AddDocumentDocument, options);
      }
export type AddDocumentMutationHookResult = ReturnType<typeof useAddDocumentMutation>;
export type AddDocumentMutationResult = Apollo.MutationResult<AddDocumentMutation>;
export type AddDocumentMutationOptions = Apollo.BaseMutationOptions<AddDocumentMutation, AddDocumentMutationVariables>;
export const AddImageDocument = gql`
    mutation addImage($id: uuid!, $image: String!, $documentId: uuid!) {
  addImage(
    object: {id: $id, image: $image, documentId: $documentId}
    on_conflict: {constraint: image_document_id_key, update_columns: [id, image]}
  ) {
    id
  }
}
    `;
export type AddImageMutationFn = Apollo.MutationFunction<AddImageMutation, AddImageMutationVariables>;

/**
 * __useAddImageMutation__
 *
 * To run a mutation, you first call `useAddImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addImageMutation, { data, loading, error }] = useAddImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      image: // value for 'image'
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useAddImageMutation(baseOptions?: Apollo.MutationHookOptions<AddImageMutation, AddImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddImageMutation, AddImageMutationVariables>(AddImageDocument, options);
      }
export type AddImageMutationHookResult = ReturnType<typeof useAddImageMutation>;
export type AddImageMutationResult = Apollo.MutationResult<AddImageMutation>;
export type AddImageMutationOptions = Apollo.BaseMutationOptions<AddImageMutation, AddImageMutationVariables>;
export const AddTinDocument = gql`
    mutation addTin($countryCode: String!, $type: tin_tin_type_enum!) {
  addTin(object: {countryCode: $countryCode, type: $type}) {
    id
    type
    country {
      code: iso31661alpha3
      tins {
        ...tin
      }
    }
  }
}
    ${TinFragmentDoc}`;
export type AddTinMutationFn = Apollo.MutationFunction<AddTinMutation, AddTinMutationVariables>;

/**
 * __useAddTinMutation__
 *
 * To run a mutation, you first call `useAddTinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTinMutation, { data, loading, error }] = useAddTinMutation({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAddTinMutation(baseOptions?: Apollo.MutationHookOptions<AddTinMutation, AddTinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTinMutation, AddTinMutationVariables>(AddTinDocument, options);
      }
export type AddTinMutationHookResult = ReturnType<typeof useAddTinMutation>;
export type AddTinMutationResult = Apollo.MutationResult<AddTinMutation>;
export type AddTinMutationOptions = Apollo.BaseMutationOptions<AddTinMutation, AddTinMutationVariables>;
export const AdminTinsDocument = gql`
    query AdminTins {
  tins(where: {format: {_is_null: false}}) {
    id
    country {
      code: iso31661alpha3
      nameRu
    }
    format
  }
}
    `;

/**
 * __useAdminTinsQuery__
 *
 * To run a query within a React component, call `useAdminTinsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminTinsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminTinsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminTinsQuery(baseOptions?: Apollo.QueryHookOptions<AdminTinsQuery, AdminTinsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminTinsQuery, AdminTinsQueryVariables>(AdminTinsDocument, options);
      }
export function useAdminTinsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminTinsQuery, AdminTinsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminTinsQuery, AdminTinsQueryVariables>(AdminTinsDocument, options);
        }
export type AdminTinsQueryHookResult = ReturnType<typeof useAdminTinsQuery>;
export type AdminTinsLazyQueryHookResult = ReturnType<typeof useAdminTinsLazyQuery>;
export type AdminTinsQueryResult = Apollo.QueryResult<AdminTinsQuery, AdminTinsQueryVariables>;
export const CountriesDocument = gql`
    query countries {
  countries(order_by: [{isAccessible: desc}, {nameRu: asc}]) {
    code: iso31661alpha3
    nameRu
    isAccessible
  }
}
    `;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: Apollo.QueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
      }
export function useCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
        }
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesQueryResult = Apollo.QueryResult<CountriesQuery, CountriesQueryVariables>;
export const CountriesAdminDocument = gql`
    query countriesAdmin {
  countries(order_by: {tins_aggregate: {count: desc}}) {
    code: iso31661alpha3
    nameRu
    juridicalTins: tins(where: {type: {_eq: JURIDICAL}, isAnalogue: {_eq: false}}) {
      ...tinAdmin
    }
    privateTins: tins(where: {type: {_eq: PRIVATE}, isAnalogue: {_eq: false}}) {
      ...tinAdmin
    }
    analogueTins: tins(where: {isAnalogue: {_eq: true}}) {
      ...tinAdmin
    }
  }
}
    ${TinAdminFragmentDoc}`;

/**
 * __useCountriesAdminQuery__
 *
 * To run a query within a React component, call `useCountriesAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesAdminQuery(baseOptions?: Apollo.QueryHookOptions<CountriesAdminQuery, CountriesAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountriesAdminQuery, CountriesAdminQueryVariables>(CountriesAdminDocument, options);
      }
export function useCountriesAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountriesAdminQuery, CountriesAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountriesAdminQuery, CountriesAdminQueryVariables>(CountriesAdminDocument, options);
        }
export type CountriesAdminQueryHookResult = ReturnType<typeof useCountriesAdminQuery>;
export type CountriesAdminLazyQueryHookResult = ReturnType<typeof useCountriesAdminLazyQuery>;
export type CountriesAdminQueryResult = Apollo.QueryResult<CountriesAdminQuery, CountriesAdminQueryVariables>;
export const CountryDocument = gql`
    query country($code: String!) {
  country(iso31661alpha3: $code) {
    code: iso31661alpha3
    isAccessible
    log(order_by: {createdAt: desc}, limit: 10) {
      id
      createdAt
      changedFields
      tin {
        id
        abbreviation
      }
    }
    tins {
      ...tin
    }
  }
}
    ${TinFragmentDoc}`;

/**
 * __useCountryQuery__
 *
 * To run a query within a React component, call `useCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountryQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useCountryQuery(baseOptions: Apollo.QueryHookOptions<CountryQuery, CountryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountryQuery, CountryQueryVariables>(CountryDocument, options);
      }
export function useCountryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountryQuery, CountryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountryQuery, CountryQueryVariables>(CountryDocument, options);
        }
export type CountryQueryHookResult = ReturnType<typeof useCountryQuery>;
export type CountryLazyQueryHookResult = ReturnType<typeof useCountryLazyQuery>;
export type CountryQueryResult = Apollo.QueryResult<CountryQuery, CountryQueryVariables>;
export const ImageDocument = gql`
    query Image($id: uuid!) {
  image(id: $id) {
    id
    image
  }
}
    `;

/**
 * __useImageQuery__
 *
 * To run a query within a React component, call `useImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useImageQuery(baseOptions: Apollo.QueryHookOptions<ImageQuery, ImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImageQuery, ImageQueryVariables>(ImageDocument, options);
      }
export function useImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImageQuery, ImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImageQuery, ImageQueryVariables>(ImageDocument, options);
        }
export type ImageQueryHookResult = ReturnType<typeof useImageQuery>;
export type ImageLazyQueryHookResult = ReturnType<typeof useImageLazyQuery>;
export type ImageQueryResult = Apollo.QueryResult<ImageQuery, ImageQueryVariables>;
export const LoginDocument = gql`
    mutation login($login: String!, $password: String!) {
  login(login: $login, password: $password) {
    ... on InvalidCredentials {
      message
    }
    ... on Token {
      token
      userId
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      login: // value for 'login'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    login
    role
  }
  countries(order_by: [{isAccessible: desc}, {nameRu: asc}]) {
    code: iso31661alpha3
    nameRu
    isAccessible
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const RemoveCheckLinkDocument = gql`
    mutation removeCheckLink($id: uuid!) {
  removeCheckLink(id: $id) {
    id
  }
}
    `;
export type RemoveCheckLinkMutationFn = Apollo.MutationFunction<RemoveCheckLinkMutation, RemoveCheckLinkMutationVariables>;

/**
 * __useRemoveCheckLinkMutation__
 *
 * To run a mutation, you first call `useRemoveCheckLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCheckLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCheckLinkMutation, { data, loading, error }] = useRemoveCheckLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCheckLinkMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCheckLinkMutation, RemoveCheckLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCheckLinkMutation, RemoveCheckLinkMutationVariables>(RemoveCheckLinkDocument, options);
      }
export type RemoveCheckLinkMutationHookResult = ReturnType<typeof useRemoveCheckLinkMutation>;
export type RemoveCheckLinkMutationResult = Apollo.MutationResult<RemoveCheckLinkMutation>;
export type RemoveCheckLinkMutationOptions = Apollo.BaseMutationOptions<RemoveCheckLinkMutation, RemoveCheckLinkMutationVariables>;
export const RemoveDocumentDocument = gql`
    mutation removeDocument($id: uuid!) {
  removeDocument(id: $id) {
    id
  }
}
    `;
export type RemoveDocumentMutationFn = Apollo.MutationFunction<RemoveDocumentMutation, RemoveDocumentMutationVariables>;

/**
 * __useRemoveDocumentMutation__
 *
 * To run a mutation, you first call `useRemoveDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDocumentMutation, { data, loading, error }] = useRemoveDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveDocumentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDocumentMutation, RemoveDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDocumentMutation, RemoveDocumentMutationVariables>(RemoveDocumentDocument, options);
      }
export type RemoveDocumentMutationHookResult = ReturnType<typeof useRemoveDocumentMutation>;
export type RemoveDocumentMutationResult = Apollo.MutationResult<RemoveDocumentMutation>;
export type RemoveDocumentMutationOptions = Apollo.BaseMutationOptions<RemoveDocumentMutation, RemoveDocumentMutationVariables>;
export const RemoveTinDocument = gql`
    mutation removeTin($id: uuid!) {
  removeTin(id: $id) {
    id
  }
}
    `;
export type RemoveTinMutationFn = Apollo.MutationFunction<RemoveTinMutation, RemoveTinMutationVariables>;

/**
 * __useRemoveTinMutation__
 *
 * To run a mutation, you first call `useRemoveTinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTinMutation, { data, loading, error }] = useRemoveTinMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveTinMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTinMutation, RemoveTinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTinMutation, RemoveTinMutationVariables>(RemoveTinDocument, options);
      }
export type RemoveTinMutationHookResult = ReturnType<typeof useRemoveTinMutation>;
export type RemoveTinMutationResult = Apollo.MutationResult<RemoveTinMutation>;
export type RemoveTinMutationOptions = Apollo.BaseMutationOptions<RemoveTinMutation, RemoveTinMutationVariables>;
export const UpdateChecklinkDocument = gql`
    mutation updateChecklink($id: uuid!, $_set: tin_check_link_set_input!) {
  updateChecklink(pk_columns: {id: $id}, _set: $_set) {
    ...checkLink
  }
}
    ${CheckLinkFragmentDoc}`;
export type UpdateChecklinkMutationFn = Apollo.MutationFunction<UpdateChecklinkMutation, UpdateChecklinkMutationVariables>;

/**
 * __useUpdateChecklinkMutation__
 *
 * To run a mutation, you first call `useUpdateChecklinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChecklinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChecklinkMutation, { data, loading, error }] = useUpdateChecklinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateChecklinkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChecklinkMutation, UpdateChecklinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChecklinkMutation, UpdateChecklinkMutationVariables>(UpdateChecklinkDocument, options);
      }
export type UpdateChecklinkMutationHookResult = ReturnType<typeof useUpdateChecklinkMutation>;
export type UpdateChecklinkMutationResult = Apollo.MutationResult<UpdateChecklinkMutation>;
export type UpdateChecklinkMutationOptions = Apollo.BaseMutationOptions<UpdateChecklinkMutation, UpdateChecklinkMutationVariables>;
export const UpdateDocumentDocument = gql`
    mutation updateDocument($id: uuid!, $_set: tin_document_set_input!) {
  updateDocument(pk_columns: {id: $id}, _set: $_set) {
    ...document
  }
}
    ${DocumentFragmentDoc}`;
export type UpdateDocumentMutationFn = Apollo.MutationFunction<UpdateDocumentMutation, UpdateDocumentMutationVariables>;

/**
 * __useUpdateDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentMutation, { data, loading, error }] = useUpdateDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentMutation, UpdateDocumentMutationVariables>(UpdateDocumentDocument, options);
      }
export type UpdateDocumentMutationHookResult = ReturnType<typeof useUpdateDocumentMutation>;
export type UpdateDocumentMutationResult = Apollo.MutationResult<UpdateDocumentMutation>;
export type UpdateDocumentMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>;
export const UpdateTinDocument = gql`
    mutation updateTin($id: uuid!, $_set: tin_tin_set_input!) {
  updateTin(pk_columns: {id: $id}, _set: $_set) {
    ...tin
  }
}
    ${TinFragmentDoc}`;
export type UpdateTinMutationFn = Apollo.MutationFunction<UpdateTinMutation, UpdateTinMutationVariables>;

/**
 * __useUpdateTinMutation__
 *
 * To run a mutation, you first call `useUpdateTinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTinMutation, { data, loading, error }] = useUpdateTinMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateTinMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTinMutation, UpdateTinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTinMutation, UpdateTinMutationVariables>(UpdateTinDocument, options);
      }
export type UpdateTinMutationHookResult = ReturnType<typeof useUpdateTinMutation>;
export type UpdateTinMutationResult = Apollo.MutationResult<UpdateTinMutation>;
export type UpdateTinMutationOptions = Apollo.BaseMutationOptions<UpdateTinMutation, UpdateTinMutationVariables>;