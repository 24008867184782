import set from 'lodash/fp/set'
import { createElement as $, FC } from 'react'
import InlineInput from './InlineInput'
import { ListEntitiesContainer, ListEntity } from './ListEntitiesContainer'

const FormatEditor: FC<FormatEditorProps> = ({
  format,
  onChange
}) => {
  const nextFormatArray = format || []
  const handleChange: Callback = (index) => (key, value) =>
    onChange(set([index, key], value, nextFormatArray))

  const handleDelete = (index: number) => () => {
    const nextFormat: FormatBit[] = [...nextFormatArray]
    nextFormat.splice(index, 1)
    onChange(nextFormat)
  }

  return $(ListEntitiesContainer, {
    title: 'Формат',
    buttonLabel: 'Добавить часть формата',
    onAdd: () => onChange(nextFormatArray.concat([{ regex: '', description: '' }]))
    },
    nextFormatArray.map((value, index) =>
      $(EditFormatBitPure, {
        ...value,
        key: index,
        onChange: handleChange(index),
        onDelete: handleDelete(index)
      })))
}

interface FormatBitProps extends FormatBit {
  onDelete: () => void,
  onChange: FormatBitUpdater
}

const EditFormatBitPure = ({
  regex,
  description,
  onDelete,
  onChange
}: FormatBitProps) =>
  $(ListEntity, { onDelete },
    $(InlineInput, {
      value: regex,
      onChange: (value) => onChange('regex', value),
      placeholder: 'Регулярное выражение'
    }),
    $(InlineInput, {
      value: description,
      onChange: (value) => onChange('description', value),
      placeholder: 'Описание'
    }))

type FormatBit = {
  regex: string,
  description: string
}

type FormatEditorProps = {
  format: FormatBit[],
  onChange: (value: FormatBit[]) => void
}

type FormatBitUpdater = (key: keyof FormatBit, value: string) => void
type Callback = (index: number) => FormatBitUpdater

export default FormatEditor