import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import Fuse from 'fuse.js'
import find from 'lodash/fp/find'
import { Auth_Role_Enum, CountriesQuery, useCountriesQuery, useMeQuery } from 'queries'
import { createElement as $, FC, useMemo } from 'react'

type CountryPickerProps = {
  value: string,
  onChange: (value: string | null) => void
}

type Country = CountriesQuery['countries'][number]

const CountryPicker: FC<CountryPickerProps> = ({
  value,
  onChange
}) => {
  const { data, loading } = useCountriesQuery()
  const meQuery = useMeQuery({ fetchPolicy: 'cache-only' })
  const countryValue = find({ code: value }, data?.countries) || null
  const fuse = useMemo(() =>
    data?.countries &&
      new Fuse(data.countries, fuseOptions),
    [data?.countries])
  return $<AutocompleteProps<Country, false, false, false>>(Autocomplete, {
    filterOptions: (countries, { inputValue }) =>
      fuse && inputValue
        ? fuse.search(inputValue).map(({ item }) => item)
        : countries,
    disabled: loading,
    fullWidth: true,
    value: countryValue,
    autoHighlight: true,
    noOptionsText: 'Нет данных',
    onChange: (_, option) => onChange(option?.code || null),
    getOptionLabel: (option) => option.nameRu || '',
    options: data?.countries || [],
    ...meQuery?.data?.me?.role !== Auth_Role_Enum.Admin && {
      groupBy: ({ isAccessible }) => isAccessible ? 'Доступно' : ' Не доступно',
      getOptionDisabled: ({ isAccessible }) => !isAccessible,
    },
    renderInput: (params) => $(TextField, {
      ...params,
      placeholder: 'Поиск по странам'
    })
  })
}

const fuseOptions: Fuse.IFuseOptions<Country> = {
  keys: ['nameRu', 'code', 'nameEn'],
  threshold: 0.5
}

export default CountryPicker