import { Box, styled, Theme, useMediaQuery } from '@mui/material'
import { createElement as $, FC, PropsWithChildren } from 'react'

const HorizontallyScrollable: FC<PropsWithChildren> = ({
  children
}) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  return isDesktop
    ? $(Box, { display: 'flex', flexWrap: 'wrap' }, children)
    : $(Scrollable, null, $(Box, { display: 'flex' }, children))
}

const Scrollable = styled(Box)({
  margin: '0 -1rem',
  padding: '0 1rem',
  overflowX: 'scroll'
})

export default HorizontallyScrollable