import Delete from '@mui/icons-material/Delete'
import { Box, Button, IconButton, List, ListItem, ListItemSecondaryAction, Typography } from '@mui/material'
import { createElement as $, FC, Fragment, PropsWithChildren } from 'react'

type ListEntitiesContainerProps = PropsWithChildren & {
  title: string,
  buttonLabel: String
  onAdd: () => void
}

export const ListEntitiesContainer: FC<ListEntitiesContainerProps> = ({
  children,
  onAdd,
  title,
  buttonLabel
}) =>
  $(Fragment, null,
    $(Typography, { variant: 'caption', color: 'textSecondary' }, title),
    $(List, null, children),
    $(Button, {
      sx: { margin: '0 0 -6px -6px'},
      size: 'small',
      onClick: () => onAdd()
      },
      buttonLabel))

type ListEntityProps = PropsWithChildren & {
  onDelete: () => void
}

export const ListEntity: FC<ListEntityProps> = ({
  children,
  onDelete
}) =>
  $(ListItem, { divider: true, disableGutters: true },
    $(Box, { flexGrow: 1 }, children),
    $(ListItemSecondaryAction, null,
      $(IconButton, { onClick: onDelete, edge: 'end' },
        $(Delete))))

export default ListEntitiesContainer