import ExitToApp from '@mui/icons-material/ExitToApp'
import { IconButton } from '@mui/material'
import { useLogoutMutation } from 'queries'
import { createElement as $, FC } from 'react'

const Logout: FC = () => {
  const [mutate] = useLogoutMutation()
  const logout = () => mutate()
    .then(() => window.location.reload())
  return $(IconButton, {
    sx: { m: 1 },
    onClick: logout },
    $(ExitToApp))
}

export default Logout