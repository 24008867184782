import ArrowDropdownIcon from '@mui/icons-material/ArrowDropDown'
import { Button, ButtonGroup, ClickAwayListener, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { Box } from '@mui/system'
import find from 'lodash/fp/find'
import sortBy from 'lodash/fp/sortBy'
import { TinFragment, Tin_Tin, Tin_Tin_Type_Enum, useAddTinMutation, useCountryQuery } from "queries"
import { createElement as $, FC, useCallback, useState } from 'react'
import tinButtonIcons from '../../tinButtonIcons'
import TinVariant from './TinVariant'

const Info = ({
  countryCode
}: { countryCode: Tin_Tin['countryCode'] }) => {
  const { data } = useCountryQuery({ variables: { code: countryCode } })
  const [variantId, setVariantId] = useState('')

  const tins = sortBy(['type', 'abbreviation'], data?.country?.tins || [])

  return $(Grid, { container: true, spacing: 2, pt: 2, pb: 2 },
    $(Grid, { item: true, xs: 3 },
      tins.length > 0 &&
        $(Box, { pb: 2 },
          $(ToggleButtonGroup, {
            onChange: (event, value) => setVariantId(value),
            fullWidth: true,
            orientation: 'vertical',
            exclusive: true,
            value: variantId || tins[0].id
            },
            tins.map(TinButton))),
      $(AddTin, { countryCode, tins })),
    $(Grid, { item: true, xs: 9 }, 
      tins.length > 0 &&
        $(TinVariant, find({ id: variantId }, tins) || tins[0])))
}

type AddTinProps = {
  countryCode: Tin_Tin['countryCode'],
  tins: TinFragment[]
}

const TinButton = ({
  id,
  type,
  abbreviation
}: TinFragment) =>
  abbreviation
    ? $(ToggleButton, { key: id, value: id, sx: { justifyContent: 'space-between' } },
        $('div', null, abbreviation),
        $(tinButtonIcons[type], { color: 'disabled', fontSize: 'small' }))
    : $(ToggleButton, { key: id, value: id },
        type)

const AddTin: FC<AddTinProps> = ({
  tins,
  countryCode
}) => {

  const [expanded, setExpanded] = useState(false)

  return (tins.length === 0 || expanded)
    ? $(ClickAwayListener, {
        onClickAway: () => setExpanded(false),
        children: $(Box, null, // Enveloping in Box is important for ref forwarding
          $(Buttons, { tins, countryCode, setExpanded }))
        })
    : $(Button, {
        onClick: () => setExpanded(!expanded),
        variant: 'outlined',
        fullWidth: true
        },
        'Добавить',
        $(ArrowDropdownIcon))
}

interface ButtonsProps extends AddTinProps {
  setExpanded: (expanded: boolean) => void
}

const Buttons: FC<ButtonsProps> = ({
  tins,
  countryCode,
  setExpanded
}) => {
  const [mutate] = useAddTinMutation()

  const handleClick = useCallback((type: Tin_Tin_Type_Enum) => () => {
    setExpanded(false)
    const tin: TinFragment = {
      __typename: 'tin_tin',
      id: Math.random().toString(),
      type,
      countryCode,
      isAnalogue: false,
      format: null,
      absenceReason: null,
      assignmentProcedure: null,
      abbreviation: null,
      description: null,
      checkLinks: [],
      documents: []
    }
    mutate({
      variables: {
        countryCode,
        type
      },
      optimisticResponse: {
        __typename: 'mutation_root',
        addTin: {
          ...tin,
          country: {
            __typename: 'tin_country',
            code: countryCode,
            tins: [...tins, tin]
          }
        }
      }
    })
  }, [mutate, countryCode, tins, setExpanded])

  return $(ButtonGroup, {
    orientation: 'vertical',
    variant: 'outlined',
    fullWidth: true
    },
    $(Button, { onClick: handleClick(Tin_Tin_Type_Enum.Juridical) }, 'Юр. лицо'),
    $(Button, { onClick: handleClick(Tin_Tin_Type_Enum.Private) }, 'Физ. лицо'))
}

export default Info