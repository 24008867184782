import { Container, ContainerProps, Theme, useMediaQuery } from '@mui/material'
import MassChecking from 'MassChecking'
import { createElement as $, FC } from 'react'
import { Route, Routes, useMatch, useNavigate } from 'react-router-dom'
import CountryPicker from './CountryPicker'
import Tin from './Tin'

const Country: FC = () => {
  const match = useMatch(':country/*')
  const navigate = useNavigate()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  return $(Container, isDesktop ? desktop : mobile,
    $(CountryPicker, {
      value: match?.params?.country || '',
      onChange: (value) => navigate(value || '')
    }),
    $(Routes, null,
      $(Route, { path: '*', element: $(MassChecking) }),
      $(Route, { path: ':country/*', element: $(Tin) })))
}

const desktop: ContainerProps = {
  maxWidth: 'md',
  sx: { mt: 3 }
}

const mobile: ContainerProps = {
  disableGutters: true
}

export default Country