import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, TextField, Typography } from '@mui/material'
import { green, red } from '@mui/material/colors'
import sortBy from 'lodash/fp/sortBy'
import { CountryQuery, TinFragment, Tin_Tin_Type_Enum, useCountryQuery } from 'queries'
import { createElement as $, FC, Fragment, ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { Navigate, useMatch, useNavigate } from 'react-router-dom'
import tinButtonIcons from '../tinButtonIcons'
import CheckLinks from './CheckLinks'
import Documents from './Documents'
import { MatchedRegex, checkRegex, regexDescription } from './Format'

const Tin: FC = () => {
  const countryMatch = useMatch(':country/*')
  const code = countryMatch?.params.country!
  const candidateMatch = useMatch(':country/:candidate')
  const preparedCandidate = decodeURIComponent(candidateMatch?.params.candidate || '')
  const { data } = useCountryQuery({ variables: { code } })
  const navigate = useNavigate()
  const tins = sortBy(['type', 'abbreviation'], data?.country?.tins || [])

  // Inexistent country or path
  if (data) {
    if (!data.country)
      return $(Navigate, { to: '/' })
    else if (!data.country.isAccessible)
      return $(NotPermitted)
  }

  if (data?.country?.tins.length === 0)
    return $(NoData)

  return $(Box, { mt: 2, mb: 2 },
    $(TextField, {
      label: 'Введите TIN',
      fullWidth: true,
      value: preparedCandidate,
      onChange: (event) => navigate(`/${code}/${encodeURIComponent(event.target.value)}`, { replace: true })
    }),
    $(Box, { mt: 2, mb: 2 }, 
      tins.map(TinVariant(preparedCandidate))),
    data?.country?.log.length &&
      data?.country?.log.map(LatestChangeIteratee))
}

const NotPermitted: FC = () =>
  $(Box, { padding: 2 },
    'Доступ к данной стране ограничен на вашем тарифе')

const NoData: FC = () =>
  $(Box, { padding: 2 }, 'Нет данных о TIN')

const TinVariant = (
  candidate: string
) => ({
  id,
  abbreviation,
  type,
  isAnalogue,
  description,
  format,
  assignmentProcedure,
  absenceReason,
  documents,
  checkLinks
}: TinFragment) => {

  const match = format && candidate && checkRegex(format, candidate)

  const backgroundColor = !candidate
    ? undefined
    : match
      ? green[500]
      : red[500]

  return $(Fragment, { key: id },
    $(Accordion, null,
      $(AccordionSummary, null,
        $(Box, { display: 'flex', alignItems: 'center' }, 
          $(Avatar, { sx: { backgroundColor }},
            $(tinButtonIcons[type])),
          $(Box, { marginLeft: 2 }, 
            $(Typography, { variant: 'body1' },
              abbreviation),
            $(Typography, {
              variant: 'caption',
              color: 'textSecondary',
              },
              isAnalogue
                ? `Аналог TIN ${labels[type]} лица`
                : `TIN ${labels[type]} лица`)))),
      $(AccordionDetails, { sx: { pt: 0 }},
        match
          ? MatchedRegex(match, format)
          : format && format.length > 0 &&
            $(LabelDescription, {
              label: 'Формат',
              value: regexDescription(format)
            }),
        description &&
          $(LabelDescription, {
            label: 'Описание',
            value: description
          }),
        assignmentProcedure &&
          $(LabelDescription, {
            label: 'Порядок присвоения',
            value: assignmentProcedure
          }),
        absenceReason &&
          $(LabelDescription, {
            label: 'Причины отсутствия',
            value: absenceReason
          }),
        checkLinks.length > 0 &&
          $(LabelDescription, {
            label: 'Ссылки на проверку',
            value: $(CheckLinks, { checkLinks })
          }),
        documents.length > 0 &&
          $(LabelDescription, {
            label: 'Документы',
            value: $(Documents, { documents })
          }))))
}

type LabelDescriptionProps = {
  label?: string | null
  value?: string | ReactElement | null
}

const LabelDescription = ({
  label,
  value
}: LabelDescriptionProps) =>
  $(Box, { mt: 2 },
    label &&
      $(Typography, { variant: 'caption', color: 'textSecondary' }, label),
    typeof value === 'string'
      ? $(Typography, { sx: { whiteSpace: 'pre-wrap' }, variant: 'body1' }, value || 'Информация отсутствует')
      : value)

const labels = {
    [Tin_Tin_Type_Enum.Juridical]: 'юридического',
    [Tin_Tin_Type_Enum.Private]: 'физического',
}

const LatestChangeIteratee = (props: Log) =>
  $(LatestChange, { key: props.id, ...props })

const LatestChange: FC<NonNullable<CountryQuery['country']>['log'][number]> = ({
  createdAt,
  tin
}) => {
  const intl = useIntl()
  return $(Box, null,
    $(Typography, { variant: 'caption', color: 'text.secondary' },
      `${tin?.abbreviation} актуализирован ${intl.formatDate(new Date(createdAt))}`))
}

type Log = NonNullable<CountryQuery['country']>['log'][number]

export default Tin