import { Container, List, ListItemButton, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { AdminTinsQuery, CountriesAdminQuery, TinAdminFragment, useAdminTinsQuery, useCountriesAdminQuery } from 'queries'
import { createElement as $, FC } from 'react'
import { useNavigate } from 'react-router-dom'

const CountryList: FC = () => {
  const { data } = useCountriesAdminQuery()
  return $(Container, { maxWidth: 'md', disableGutters: true },
    $(NonFilled),
    $(RenderTable, { countries: data?.countries || [] }))
}

const NonFilled = () => {
  const { data } = useAdminTinsQuery()
  return $(Paper, null,
    $(List, null,
      data?.tins.filter(filterNonFilled).map(NotFilledIterator)))
}

type AdminTin = AdminTinsQuery['tins'][number]
const filterNonFilled = ({ format }: AdminTin) =>
  format.some(emptyRegex)

const NotFilledIterator = (props: AdminTin) =>
  $(NotFilledListItem, { key: props.id, ...props })

const NotFilledListItem = ({ id, country, format }: AdminTin) => {
  const navigate = useNavigate()
  return $(ListItemButton, {
    key: id,
    onClick: () => navigate(`/admin/${country.code}`) 
    },
    $(ListItemText, { primary: country.nameRu, secondary: `не заполнено: ${format.filter(emptyRegex).length} регекс` }))
}

const emptyRegex = ({ regex }: any) => regex === ''

const RenderTable = ({ countries }: { countries: CountryProps[]}) =>
  $(Paper, { sx: { mt: 2, mb: 2 }}, 
    $(Table, null,
      $(TableHead, null,
        $(TableRow, null,
          $(TableCell, null, 'Страна'),
          $(TableCell, null, 'Юр. лица'),
          $(TableCell, null, 'Физ. лица'),
          $(TableCell, null, 'Аналоги'))),
      $(TableBody, null,
          countries.map(CountryIteratee))))

type CountryProps = CountriesAdminQuery['countries'][number]

const CountryIteratee = (props: CountryProps) =>
  $(Country, { key: props.code, ...props })

const Country: FC<CountryProps> = ({
  nameRu,
  code,
  juridicalTins,
  privateTins,
  analogueTins
}) => {
  const navigate = useNavigate()
  return $(TableRow, {
    hover: true,
    key: code,
    onClick: () => navigate(`/admin/${code}`)
    },
    $(TableCell, null, nameRu),
    $(TableCell, null, `${juridicalTins.reduce(countFormatsReducer, 0)}/${juridicalTins.length}`),
    $(TableCell, null, `${privateTins.reduce(countFormatsReducer, 0)}/${privateTins.length}`),
    $(TableCell, null, `${analogueTins.reduce(countFormatsReducer, 0)}/${analogueTins.length}`))
}

const countFormatsReducer = (result: number, { format }: TinAdminFragment) =>
  result += format ? 1 : 0

export default CountryList