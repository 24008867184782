import { useApolloClient } from '@apollo/client'
import { Box, Button, CircularProgress, Collapse, Fade, Paper, TextField, Theme, Typography, useMediaQuery } from '@mui/material'
import { useLoginMutation } from 'queries'
import { ChangeEvent, createElement as $, useCallback, useEffect, useState } from 'react'

const salesEmail = 'sales@tincheck.ru'

const Login = () => {

  const [login, setLogin] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  // Using custom loading so reFetchObservableQueries can go through
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [mutate] = useLoginMutation({
    variables: { login, password },
    errorPolicy: 'none',
    ignoreResults: true
  })

  const client = useApolloClient()

  const attemptLogin = useCallback(() => {
    setLoading(true)
    mutate()
      .then(result => {
        if (result.errors?.length) {
          setLoading(false)
          setError(result.errors[0].message)
        }
        else if (result.data?.login.__typename === 'InvalidCredentials') {
          setLoading(false)
          setError(result.data?.login.message as string)
        }
        else client.reFetchObservableQueries()
          .catch((e) => {
            setLoading(false)
            setError(e.message)
          })
        return result
      })
  }, [setLoading, client, mutate])

  const handleChange = useCallback((handler: any) => (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setError('')
    handler(event.target.value)
  }, [setError])

  useEffect(() => {
    const handler = (e: KeyboardEvent) =>
      e.code === 'Enter' && !loading && attemptLogin()
    window.addEventListener('keypress', handler)
    return () => window.removeEventListener('keypress', handler)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const [showSalesEmail, setShowSalesEmail] = useState(false)

  return $(Box, isDesktop
    ? {
      maxWidth: 400,
      margin: 'auto',
      sx: { mt: 8 }
    } : null,
  $(Fade, { in: true, children: // FIXME
    $(Paper, { sx: { padding: '1rem' }},
      $(Box, { padding: 2 },
        $(Typography, { variant: 'h2', align: 'center', paragraph: true }, 'TinCheck'),
        $(Typography, { variant: 'body2', align: 'center' }, 
          'Автоматизированное решение для валидации ИНН (TIN) или причин его отсутствия')),
          $(Button, {
            onClick: () => {
              setShowSalesEmail(true)
              window.open(`mailto:${salesEmail}?subject=Запрос демонстрационного доступа к TinCheck`)
            },
            fullWidth: true,
          }, 'Запросить демо'),
          $(Typography, {
            // @ts-ignore
            component: 'div',
            sx: { mb: 2, textAlign: 'center' },
            variant: 'caption'
            }, 
            showSalesEmail && `Напишите на ${salesEmail}`),
      $(TextField, {
        disabled: loading,
        value: login,
        label: 'Логин',
        fullWidth: true,
        onChange: handleChange(setLogin)
      }),
      $(Box, { height: '1rem' }),
      $(TextField, {
        disabled: loading,
        value: password,
        size: 'medium',
        label: 'Пароль',
        fullWidth: true,
        type: 'password',
        onChange: handleChange(setPassword)
        }),
      $(Box, { height: '1rem' }),
      $(Button, {
        disabled: loading || password.length < 4,
        size: 'large',
        variant: 'outlined',
        fullWidth: true,
        onClick: attemptLogin
        },
        loading
          ? $(CircularProgress, { size: 26 })
          : 'Войти'),
      $(Collapse, { in: !!error },
        $(Box, { marginTop: '1rem' }, 
          $(Typography, {
            variant: 'caption',
            color: 'error',
            }, error))))}))
}

export default Login