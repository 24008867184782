import { ApolloProvider } from '@apollo/client'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import {
  Box, CircularProgress,
  createTheme,
  CssBaseline,
  responsiveFontSizes,
  ThemeProvider,
  useMediaQuery
} from '@mui/material'
import Login from 'Login'
import { createElement as $, FC, PropsWithChildren, useMemo } from 'react'
import { IntlProvider } from 'react-intl'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Admin from './Admin'
import client from './apollo'
import Country from './Country'
import InfoPage from './InfoPage'
import Logout from './Logout'
import { useMeQuery } from './queries'

const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const theme = useMemo(
    () =>
      responsiveFontSizes(
        createTheme({
          palette: {
            mode: prefersDarkMode ? 'dark' : 'light',
          }
        })
      ),
    [prefersDarkMode]
  )

  return $(BrowserRouter, null,
    $(ThemeProvider, { theme },
      $(CssBaseline),
      $(ApolloProvider, {
        client,
        children: $(IntlProvider, { locale: 'ru' }, $(AppRoutes))
        // FIXME https://github.com/apollographql/apollo-client/issues/2503
      })))
}

const AppRoutes: FC = () => {
  const { data, loading } = useMeQuery()
  return loading
    ? $(CircularProgress)
    : !data?.me
      ? $(Routes, null,
          $(Route, { path: '/info', element: $(InfoPage) }),
          $(Route, { path: '*', element: $(Login) }))
      : $(WithLogout, null,
          $(Routes, null,
            data.me?.role === 'ADMIN' &&// FXIME check if not hosted 
              $(Route, { path: 'admin/*', element: $(Admin) }),
            $(Route, { path: '/info', element: $(InfoPage) }),
            $(Route, { path: '*', element: $(Country) })))
}

const WithLogout: FC<PropsWithChildren> = ({ children }) => {
  return $(Box, {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
    },
    $(Box, { flexGrow: 1 }, children),
    $(Box, {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center'
      },
      $(Logout)))
}

export default App
