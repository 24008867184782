import { Box, Typography } from '@mui/material'
import { green } from '@mui/material/colors'
import { createElement as $, FC } from 'react'

const GROUP_NAME = 'Format'
export const checkRegex = (format: FormatBit[], candidate: string) => {
  const regex = new RegExp(`^${(format).map((formatBit, index) => `(?<${GROUP_NAME}${index}>${formatBit.regex})`).join('')}$`)
  const match = regex.exec(candidate)
  return match
}

export const regexDescription = (format: any) => (format as FormatBit[]).map(formatBit => formatBit.description).join('\n')

export const MatchedRegex = (
  matchResult: RegExpExecArray,
  format: FormatBit[]
) => {
  let result = []
  for (const key in matchResult.groups) {
    result.push($(RegexMatchEntry, {
      key,
      value: matchResult.groups[key],
      description: format[parseInt(key.replace(GROUP_NAME, ''), 10)].description
    }))
  }
  return result
}

const RegexMatchEntry: FC<{ value: string, description: string }> = ({
  value,
  description
}) =>
  $(Box, { display: 'flex' },
    $(Typography, { sx: { width: '16ex', flexShrink: 0 }, color: green[500] }, value),
    $(Typography, !value ? { color: 'textSecondary' } : null, description))


type FormatBit = {
  regex: string
  description: string
}

export default MatchedRegex