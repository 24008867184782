import { createElement as $, FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import Country from './Country'
import CountryList from './CountryList'

const Admin: FC = () =>
  $(Routes, null,
    $(Route, { path: ':country/*', element: $(Country) }),
    $(Route, { path: '', element: $(CountryList) }))

export default Admin