import { Box, Button, styled, Theme, Typography } from '@mui/material'
import { MUIStyledCommonProps } from '@mui/system'
import { saveAs } from 'file-saver'
import { ChangeEventHandler, createElement as $, FC, useState } from 'react'

const id = `mass-upload`
const label = `Нажмите чтобы добавить файл формата .xlsx или .xls. Файл должен содержать один лист и две колонки без заголовка`

const MassChecking: FC = () => {

  const [loading, setLoading] = useState(false)

  const handleChange: ChangeEventHandler<HTMLInputElement> = async (event) => {
    if (event.target.files) {
      const file = event.target.files[0]
      if (file) {
        setLoading(true)
        const response = await fetch('/batch', {
          method: 'POST',
          body: event.target.files[0]
        })
        if (response.ok) {
          const blob = await response.blob()
          saveAs(blob, `${file.name.split('.')[0]}.processed.xlsx`)
        }
        setLoading(false)
      }
    }
  }

  return $(Box, null, 
    $(Box, { padding: 2 },
      $(Typography, { variant: 'caption', color: 'textSecondary' }, 'или воспользуйтесь массовой проверкой')),
    $('label', { htmlFor: id },
      $(Input, {
        id,
        accept: '.xlsx,.xls',
        type: 'file',
        onChange: handleChange
      }),
      $(Button, {
        fullWidth: true,
        disabled: loading,
        variant: 'outlined',
        // @ts-ignore
        component: 'span',
        sx: {
          textTransform: 'none',
          textAlign: 'left',
          justifyContent: 'space-between'
          }
        },
        $(Box, { padding: '16px 0' }, 
          // $(Box, null, $(InsertDriveFile)),
          $(Typography, { align: 'center', variant: 'caption', color: 'textSecondary' }, label),
          $(Table, null,
            $('tbody', null, 
              $('tr', null,
                $('td', null, '12345678901'),
                $('td', null, 'BEL'),
                $('td', null, null)),
              $('tr', null,
                $('td', null, ''),
                $('td', null, ''),
                $('td', null, null))))))))
}

const Table = styled('table')(({ theme }: MUIStyledCommonProps<Theme>) => ({
  marginTop: 8,
  borderCollapse: 'collapse',
  '& tr:last-child': {
    '& td': {
      borderBottom: 0
    }
  },
  '& td': {
    border: '1px solid black',
    borderColor: theme!.palette.divider,
    padding: '4px 16px',
    '&:last-child': {
      width: '8ex',
      borderRight: 0
    }
  }
}))

const Input = styled('input')({
  display: 'none',
})

export default MassChecking