import { styled } from '@mui/material'
import { DocumentFragment, DocumentFragmentDoc, Tin_Image, Tin_Tin, useAddDocumentMutation, useImageQuery, useRemoveDocumentMutation, useUpdateDocumentMutation } from 'queries'
import { createElement as $, FC, useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'
import InlineInput from './InlineInput'
import ListEntitiesContainer, { ListEntity } from './ListEntitiesContainer'
import UploadButton from './UploadButton'

const Documents: FC<DocumentsProps> = ({
  id,
  documents
}) => {
  const [mutate] = useAddDocumentMutation()

  const addDocument = useCallback(() => {
    const newId = uuidv4()
    mutate({
      variables: { tinId: id, id: newId },
      optimisticResponse: {
        __typename: 'mutation_root',
        addDocument: {
          __typename: 'tin_document',
          id: newId
        }
      },
      update: (cache) =>
        cache.modify({
          id: cache.identify({ __typename: 'tin_tin', id }),
          fields: {
            documents: (documents) => {
              const newDocumentRef = cache.writeFragment({
                data: {
                  __typename: 'tin_document',
                  id: newId,
                  nameRu: null,
                  nameNative: null,
                  tinId: id,
                  image: null
                },
                fragment: DocumentFragmentDoc
              })
              return [...documents, newDocumentRef]
            }
          }
        })
    })
  }, [mutate, id])

  return $(ListEntitiesContainer, {
    title: 'Документы',
    buttonLabel: 'Добавить документ',
    onAdd: addDocument
    },
    documents.map(DocumentIterator))
}

type DocumentsProps = {
  id: Tin_Tin['id'],
  documents: DocumentFragment[]
}

const DocumentIterator = (props: DocumentFragment) => $(Document, { key: props.id, ...props })

const Document: FC<DocumentFragment> = (props) => {
  const {
    id,
    nameRu,
    nameNative,
    tinId,
    image
  } = props

  const [updateChecklink] = useUpdateDocumentMutation({ ignoreResults: true })

  const handleChange = useCallback((key: keyof DocumentFragment) => (data: any) => {
    updateChecklink({
      variables: { id, _set: { [key]: data }},
      optimisticResponse: {
        __typename: 'mutation_root',
        updateDocument: {
          ...props,
          [key]: data,
        }
      }
    })
  }, [id, updateChecklink, props])

  const [removeDocument] = useRemoveDocumentMutation({
    variables: { id },
    update: (cache) =>
      cache.modify({
        id: cache.identify({ __typename: 'tin_tin', id: tinId }),
        fields: {
          documents: (documents, { readField }) =>
            documents.filter((documentId: any) => id !== readField('id', documentId))
        }
      })
  })
  return $(ListEntity, { onDelete: () => removeDocument() },
    $(UploadButton, { documentId: id }, 
      image
        ? $(ImageBlock, { id: image.id })
        : 'Добавить картинку'),
    $(InlineInput, {
      placeholder: 'Название на русском',
      value: nameRu || '',
      onChange: handleChange('nameRu')
    }),
    $(InlineInput, {
      placeholder: 'Название на языке оригинала',
      value: nameNative || '',
      onChange: handleChange('nameNative')
    }))
}

const ImageBlock = ({ id }: Pick<Tin_Image, 'id'>) => {
  console.log(id)
  const { data } = useImageQuery({ variables: { id }})
  return $(ImageContainer, { src: data?.image?.image })
}

const ImageContainer = styled('img')({
  width: '100%'
})

export default Documents