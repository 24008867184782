import { InputBase, styled } from '@mui/material'
import { createElement as $ } from 'react'
import useDebounceValue from 'useDebounceValue'

const InlineInput = ({
  value,
  onChange,
  placeholder
}: {
  value: string,
  onChange: (value: string) => void,
  placeholder: string
}) => {
  const [currentValue, setValue] = useDebounceValue(value, 500, onChange)

  return $(EditFormatBitInput, { 
    value: currentValue,
    fullWidth: true,
    multiline: true,
    onChange: (event) => setValue(event.target.value),
    placeholder
  })
}

const EditFormatBitInput = styled(InputBase)({
  fontFamily: 'monospace',
})

export default InlineInput