import { Container } from '@mui/material'
import CountryPicker from 'CountryPicker'
import { createElement as $, FC } from 'react'
import { useMatch, useNavigate } from 'react-router'
import EditTin from './EditTin'

const Country: FC = () => {
  const match = useMatch('/admin/:country')
  const navigate = useNavigate()
  return $(Container, { maxWidth: 'md', sx: { mt: 2 }},
    $(CountryPicker, {
      value: match?.params.country || '',
      onChange: (value) => value
        ? navigate(`/admin/${value}`)
        : navigate('/admin')
    }),
    match?.params.country &&
      $(EditTin, { countryCode: match.params.country }))
}

export default Country