import { Box, Checkbox, FormControlLabel, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { Tin_Tin_Type_Enum } from 'queries'
import { createElement as $, FC } from 'react'

type Props = {
  type: Tin_Tin_Type_Enum
  isAnalogue: boolean
  onChange: (key: 'type' | 'isAnalogue') => (value: any) => void
} 

const TypeSelector: FC<Props> = ({
  type,
  isAnalogue,
  onChange
}) =>
  $(Box, { display: 'flex' },
    $(Box, { flexGrow: 1 }, 
      $(ToggleButtonGroup, {
        value: type,
        exclusive: true,
        // fullWidth: true,
        onChange: (event: any, value: Tin_Tin_Type_Enum) => value && onChange('type')(value)
        },
        $(ToggleButton, {
          value: Tin_Tin_Type_Enum.Juridical
        }, 'Юридическое лицо'),
        $(ToggleButton, {
          value: Tin_Tin_Type_Enum.Private
        }, 'Физическое лицо'))),
    $(FormControlLabel, {
      value: isAnalogue,
      onChange: (_, value: boolean) => onChange('isAnalogue')(value),
      componentsProps: {
        typography: {
          color: 'textSecondary'
        }
      },
      labelPlacement: 'start',
      control: $(Checkbox, { checked: isAnalogue }),
      label: 'Аналог'
    }))

export default TypeSelector