import TopicIcon from '@mui/icons-material/Topic'
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material'
import { blue } from '@mui/material/colors'
import { createElement as $, FC } from 'react'
import { DocumentFragment, Tin_Document, Tin_Image, useImageQuery } from '../queries'
import HorizontallyScrollable from './HorizontallyScrollable'

const Documents: FC<{ documents: DocumentFragment[] }> = ({ documents }) =>
  $(HorizontallyScrollable, null, documents.map(DocumentIteratee))

const DocumentIteratee = (document: DocumentFragment) =>
  $(Document, { key: document.id, ...document })

const Document: FC<DocumentFragment> = ({
  nameNative,
  nameRu,
  image
}) => {
  return $(Box, { flexShrink: 0 }, 
    $(Card, { sx: { maxWidth: '16rem', marginRight: '1rem', marginTop: '1rem' }},
      $(CardActionArea, null,
        image
          ? $(RenderImage, { id: image.id, nameNative: nameNative! })
          : $(Box, {
              height: 16 * 8,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              sx: { backgroundColor: blue[900] }
              },
              $(TopicIcon)),
        $(CardContent, null,
          $(Typography, { variant: 'body2' }, nameRu),
          $(Typography, { variant: 'caption', color: 'textSecondary' }, nameNative)))))
}

const RenderImage: FC<ImageProps> = ({ id, nameNative }) => {
  const { data } = useImageQuery({ variables: { id }})
  return $(CardMedia, {
    // FIXME
    // @ts-ignore
    component: 'img',
    height: 16 * 8,
    onClick: () => {
      if (data?.image?.image) {
        const img = new Image()
        img.src = data.image.image
        const windowObject = window.open('')
        img.onload = () =>
          windowObject?.document.write(img.outerHTML)
      }
    },
    src: data?.image?.image,
    alt: nameNative,
  })
}

type ImageProps = {
  id: Tin_Image['id'],
  nameNative: Tin_Document['nameNative']
}

export default Documents