import { useEffect, useState } from 'react'

function useDebounceValue<T>(
  initialValue: T,
  delay: number,
  handler: (nextValue: T) => void
): [T, (nextValue: T) => void] {
  const [currentValue, setValue] = useState(initialValue)

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (currentValue !== initialValue)
        handler(currentValue)
    }, delay)
    return () => clearTimeout(timeout)
    // eslint-disable-next-line
  }, [handler, delay, currentValue])

  return [currentValue, setValue]
}

export default useDebounceValue