import { CheckLinkFragment, CheckLinkFragmentDoc, Tin_Tin, useAddCheckLinkMutation, useRemoveCheckLinkMutation, useUpdateChecklinkMutation } from 'queries'
import { createElement as $, FC, memo, useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'
import InlineInput from './InlineInput'
import { ListEntitiesContainer, ListEntity } from './ListEntitiesContainer'

const Links: FC<LinksProps> = ({
  id,
  checkLinks
}) => {
  const [mutate] = useAddCheckLinkMutation()
  
  const addLink = useCallback(() => {
    const newId = uuidv4()
    mutate({
      variables: { tinId: id, id: newId },
      optimisticResponse: {
        __typename: 'mutation_root',
        addCheckLink: {
          __typename: 'tin_check_link',
          id: newId
        }
      },
      update: (cache) =>
        cache.modify({
          id: cache.identify({ __typename: 'tin_tin', id }),
          fields: {
            checkLinks: (checkLinks) => {
              const newCheckLinkRef = cache.writeFragment({
                data: {
                  __typename: 'tin_check_link',
                  id: newId,
                  url: null,
                  title: null,
                  comment: null,
                  tinId: id
                },
                fragment: CheckLinkFragmentDoc
              })
              return [...checkLinks, newCheckLinkRef]
            }
          }
        })
    })
  }, [mutate, id])

  return $(ListEntitiesContainer, {
    title: 'Ссылки на проверку',
    buttonLabel: 'Добавить ссылку',
    onAdd: addLink
    },
    checkLinks.map(LinkIteratee))
}

const LinkIteratee = (props: CheckLinkFragment) => $(Link, { key: props.id, ...props })

const Link: FC<CheckLinkFragment> = memo((props) => {
  const {
    id,
    url,
    title,
    comment,
    tinId
  } = props

  const [updateChecklink] = useUpdateChecklinkMutation({ ignoreResults: true })

  const handleChange = useCallback((key: keyof CheckLinkFragment) => (data: any) => {
    updateChecklink({
      variables: { id, _set: { [key]: data }},
      optimisticResponse: {
        __typename: 'mutation_root',
        updateChecklink: {
          ...props,
          [key]: data,
        }
      }
    })
  }, [id, updateChecklink, props])

  const [removeCheckLink] = useRemoveCheckLinkMutation({
    variables: { id },
    update: (cache) =>
      cache.modify({
        id: cache.identify({ __typename: 'tin_tin', id: tinId }),
        fields: {
          checkLinks: (checkLinks, { readField }) =>
            checkLinks.filter((checkLinkId: any) => id !== readField('id', checkLinkId))
        }
      })
  })

  return $(ListEntity, { onDelete: removeCheckLink },
    $(InlineInput, {
      placeholder: 'URL',
      value: url || '',
      onChange: handleChange('url')
    }),
    $(InlineInput, {
      placeholder: 'Название',
      value: title || '',
      onChange: handleChange('title')
    }),
    $(InlineInput, {
      placeholder: 'Комментарий',
      value: comment || '',
      onChange: handleChange('comment')
    }))
})

type LinksProps = {
  id: Tin_Tin['id'],
  checkLinks: CheckLinkFragment[]
}

export default Links