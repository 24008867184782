import { Card, CardActionArea, CardContent, Typography } from '@mui/material'
import { CheckLinkFragment } from 'queries'
import { createElement as $, FC } from 'react'
import HorizontallyScrollable from './HorizontallyScrollable'

const CheckLinks: FC<{ checkLinks: CheckLinkFragment[] }> = ({
  checkLinks
}) => 
  $(HorizontallyScrollable, null, 
    checkLinks.map(CheckLinkIteratee))

const CheckLinkIteratee = (props: CheckLinkFragment) =>
  $(CheckLink, { key: props.id, ...props })

const CheckLink: FC<CheckLinkFragment> = ({
  url,
  title,
  comment
}) =>
  $(Card, { sx: { mr: '1rem' }},
    $(CardActionArea, { onClick: () => url && window.open(url) }, 
      $(CardContent, null,
        $(Typography, null, title),
        $(Typography, { variant: 'caption', color: 'textSecondary' }, comment))))

export default CheckLinks